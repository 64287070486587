import React, { Component } from 'react';

import './Promoted.css';
import { SmallProduct } from '../smallproduct/SmallProduct';

class Promoted extends Component{
	constructor(props)
	{
		super(props);
		this.state={ };
	}
	
	render(){
		return(
						<div className={this.props.homeItem ? 'promoted_card_and_title_container' : ''}>
							<div className="homepage_card_container">
							{
								this.props.productsList.map((oneItem, i) => 
										<SmallProduct key={i} oneItem={oneItem} homeItem={this.props.homeItem} />
								)
							}
							</div>
						</div>
		);
	}
}

export default Promoted;