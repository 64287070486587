import { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

import './Messages.css';
import Util from '../../util/util';

import { MessagesList } from '../../components/messages/MessagesList';
import { Chat } from '../../components/chat/Chat';

class Messages extends Component {
	
	constructor()
		{
			super();
			this.state = {
							currentTab: 'inbox',
							inboxMessages: [],
							outboxMessages: [],
							sellingMessages: [],
							messagesAll: [],
							lastMessage: 0,
							currentMessageId: 0,
							currentMessageUser: 0,
							sellingUnprocess: 0,
							currentUser: false,
							currentChat: false,
							currentFilter: 'all',
							chatMaxId: 0,
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
			};
		}
	
	componentDidMount()
	{
		document.title = this.props.pageTitle;
		this.loadMessages(this.props, true);
		this.getCurrentChat();
		
		var mainThis = this;
		Util.addSSEventCallback('user_listening', 'messages_listen', function (data)
			{
				if ( data && data['update'] && data['update']['reloadMessages'] )
					{
						Util.storageClear(['messages_inbox', 'messages_outbox', 'messages_sell', 'messages_all', 'messages_latest_id', 'unreadselling']);
					}
				
				if ( data && data['update'] && ( data['update']['newMessage'] || data['update']['markRead'] ) )
					{
						mainThis.loadMessages(mainThis.props, true, true);
					}
			});
	}
	
	loadMessages(current_props, preload, doforceReload)
		{
			var mainThis = this;
			if ( this.state.usersData && this.state.usersData.id && this.state.usersData.token )
				{
					var ret = Util.storagePreload(['messages_inbox', 'messages_outbox', 'messages_sell', 'messages_all', 'messages_latest_id', 'unreadselling'], 'json', [], false);
					
					mainThis.setState({
										inboxMessages: ret['messages_inbox'],
										outboxMessages: ret['messages_outbox'],
										sellingMessages: ret['messages_sell'],
										messagesAll: ret['messages_all'],
										lastMessage: ret['messages_latest_id'],
										sellingUnprocess: ret['unreadselling']
									});
					
					if ( preload )
						{
							axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
							axios.post(window.ajaxLink+'message/getInboxOutbox', {maxCurrent: ret['messages_latest_id'], forceReload: doforceReload})
									.then(res => {
										
										if ( res['data']['responseData']['upToDate'] == 0 )
											{
												mainThis.setState({
																		inboxMessages: res['data']['responseData']['messagesInboxFirst'],
																		outboxMessages: res['data']['responseData']['messagesOutboxFirst'],
																		sellingMessages: res['data']['responseData']['messagesSellFirst'],
																		messagesAll: res['data']['responseData']['messagesAll'],
																		outboxMessagesAll: res['data']['responseData']['messagesOutbox'],
																		sellingUnprocess: res['data']['responseData']['unreadselling'],
																		lastMessage: res['data']['responseData']['maxId']
																	});
												
												localStorage.setItem('messages_inbox', JSON.stringify(res['data']['responseData']['messagesInboxFirst']));
												localStorage.setItem('messages_outbox', JSON.stringify(res['data']['responseData']['messagesOutboxFirst']));
												localStorage.setItem('messages_sell', JSON.stringify(res['data']['responseData']['messagesSellFirst']));
												localStorage.setItem('messages_all', JSON.stringify(res['data']['responseData']['messagesAll']));
												localStorage.setItem('unreadselling', JSON.stringify(res['data']['responseData']['unreadselling']));
												localStorage.setItem('messages_latest_id', JSON.stringify(res['data']['responseData']['maxId']));
											}
									}).catch(err => { console.log(err); }
								);
						}
				}
		}
	
	getCurrentChat = () =>
		{
			var nowPath = Util.getPath(2);
			var currentMessageId = 0;
			if ( nowPath )
				{
					nowPath = parseInt(nowPath);
					if ( nowPath && !isNaN(nowPath) && isFinite(nowPath) )
						{
							currentMessageId = nowPath;
						}
				}
			
			var nowPath2 = Util.getPath(3);
			var currentMessageUser = 0;
			if ( nowPath2 )
				{
					nowPath2 = parseInt(nowPath2);
					if ( nowPath2 && !isNaN(nowPath2) && isFinite(nowPath2) )
						{
							currentMessageUser = nowPath2;
						}
				}
			
			this.setState({currentMessageId: currentMessageId, currentMessageUser: currentMessageUser});
		}
	
	
	UNSAFE_componentWillReceiveProps(propsNew)
		{
			this.getCurrentChat();
		}
	
	render()
	{
		return (
				<div className="messages_page">
					<div className="page_1of4 message_types">
						<Link to="/poruke"><div className={'messages_tab '+(this.state.currentTab == 'inbox' ? 'active' : '')} onClick={()=>{ this.setState({currentTab: 'inbox', currentFilter: 'all'}); }}>Inbox</div></Link>
						<Link to="/poruke"><div className={'messages_tab '+(this.state.currentTab == 'outbox' ? 'active' : '')} onClick={()=>{ this.setState({currentTab: 'outbox', currentFilter: 'all'}); }}>Poslate Poruke</div></Link>
						
						<Link to="/poruke"><div className={'messages_tab msg_prodaja '+(this.state.currentTab == 'selling' ? 'active' : '')} onClick={()=>{ this.setState({currentTab: 'selling', currentFilter: 'all'}); }}>Prodaja {this.state.sellingUnprocess > 0 && <div className="messages_selling_unread">{this.state.sellingUnprocess}</div> }</div></Link>
					</div>
					{ ( !this.state.currentMessageId || this.state.currentMessageUser == 0 ) &&
					<div className="page_3of4 messages_list">
						<div className="messages_topdiv">
							{ this.state.currentTab == 'inbox' && <div className="messages_toptitle">Inbox</div> }
							{ this.state.currentTab == 'outbox' && <div className="messages_toptitle">Poslate Poruke</div> }
							{ this.state.currentTab == 'selling' && <div className="messages_toptitle">Prodaja</div> }
							
							{ ( this.state.currentTab == 'inbox' || this.state.currentTab == 'outbox' ) ? 
								<div className="message_tabs">
									<div className={(this.state.currentFilter == 'all' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'all'}); }}>SVE</div>
									<div className={(this.state.currentFilter == 'sell' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'sell'}); }}>PRODAJEM</div>
									<div className={(this.state.currentFilter == 'buy' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'buy'}); }}>KUPUJEM</div>
								</div>
								:
								<div className="message_tabs">
									<div className={(this.state.currentFilter == 'all' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'all'}); }}>SVE</div>
									<div className={(this.state.currentFilter == 'waiting' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'waiting'}); }}>ČEKAJU</div>
								</div>
							}
						</div>
						
						{ this.state.currentTab == 'inbox' && <MessagesList messages={this.state.inboxMessages} filter={this.state.currentFilter} /> }
						{ this.state.currentTab == 'outbox' && <MessagesList messages={this.state.outboxMessages} filter={this.state.currentFilter} /> }
						{ this.state.currentTab == 'selling' && <MessagesList messages={this.state.sellingMessages} filter={this.state.currentFilter} /> }
						
					</div> }
					
					{ this.state.currentMessageId > 0 && this.state.currentMessageUser != 0 &&
					<div className="page_3of4 messages_list">
						<Chat productChatId={this.state.currentMessageId} switchTabInbox={(callback) => { this.setState({currentTab: 'inbox'}, () => { callback(); }); }} currentTab={this.state.currentTab} otherUserId={this.state.currentMessageUser} />
					</div> }
				</div>
		);
	}
}

export default Messages;