import React, { Component } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import './OrgProducts.css';

import Util from '../../util/util';


class OrgProducts extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			user_id: '',
			usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
			userProducts: [],
			filteredProducts: 0,
			sentProducts: [],
			currentFilter: 'all',
			selectAll: false,
			promotedError: '',
			isVisibleError: true,
			loading_products: false
		}
		
		this.reloadTimeout = false;
	}

	componentDidMount = () =>
		{
			document.title = this.props.pageTitle;
			this.loadAllProducts();
		}
	
	loadAllProducts = () =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'products/userproducts', {})
				.then(res => {
					mainThis.setState({userProducts: res['data']['responseData']['user_products'], selectAll: false, loading_products: false});
					mainThis.checkFilters(mainThis.state.currentFilter);
				}).catch(err => {}
			);
		}
	
	checkAll = () =>
		{
			this.setState({selectAll: !this.state.selectAll});
			var filter = this.state.currentFilter;

			if ( filter == 'all' )
				{

					var allItems = this.state.userProducts;
					allItems.map((checkItems, i)=> allItems[i].checked = !this.state.selectAll );
					this.setState({userProducts: allItems});
				}
			if ( filter == 'expired' )
				{
					var expired = this.state.userProducts.filter(singleItem => singleItem.expired);
					expired.map((checkItems, i)=> expired[i].checked = !this.state.selectAll )
					
				}  
			if ( filter == 'no-quantity' )
				{
					var noquantity = this.state.userProducts.filter(singleItem => singleItem.quantity == 0);
					noquantity.map((checkItems, i)=> noquantity[i].checked = !this.state.selectAll );
				} 
				
		}
		
	 uncheckAll = () =>
		{
			this.setState({selectAll: false});
			var allItems = this.state.userProducts;
			allItems.map((checkItems, i)=> allItems[i].checked = false );
			this.setState({userProducts: allItems});
		}  
		
	getCheckBox = (i) =>
		{
			var checkBox = this.state.userProducts;
			checkBox[i]['checked'] = !checkBox[i]['checked'];
			this.setState({userProducts: checkBox});
		}
		
	promoteAd = (itemP) =>
		{
			this.setState({
				promotedError: '',
				isVisibleError: false,
			});
			
			var isValid = true;
			if ( itemP['quantity'] < 1 )
				{
					isValid = false;
					this.setState({isVisibleError: true, promotedError: "Ovaj proizvod ne može biti promovisan jer ga nema na stanju" });
				}
			
			if ( itemP['expired'] )
				{
					isValid = false;
					this.setState({isVisibleError: true, promotedError: "Ovaj proizvod ne može biti promovisan jer je istekao" });
				}
			
			if ( isValid == true && itemP.is_promoted == 0 )
				{
					this.setState({loading_products: true});
					var mainThis = this;
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'products/promoteAd',{product_id: itemP['product_id']})
					.then(res => {
						mainThis.loadAllProducts();
					}).catch(err => {console.log(err);}); 
				}
			
		}
		
	addQuantity = (i) =>
		{
			var quantity = this.state.userProducts;
			quantity[i]['quantity']++;
			this.setState({userProducts: quantity});
			
			var mainThis = this;
			
			if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
			axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
			axios.post(window.ajaxLink+'products/sendproducts', {changeQuantity: 1, productId: quantity[i].product_id, quantity: quantity[i].quantity})
				.then(res => {
					
					var allItems = mainThis.state.userProducts;
					mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
					mainThis.setState({userProducts: allItems, selectAll: false});
					
					if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
					mainThis.reloadTimeout = setTimeout(() => { mainThis.loadAllProducts(); }, 2000);
					
				}).catch(err => {})
		}
	
	updateQuantity = (event, i) =>
		{
			var quantity = this.state.userProducts;
			quantity[i]['quantity']= event.target.value;
			this.setState({userProducts: quantity});
			
			if ( quantity[i]['quantity'] !== '' && parseInt(quantity[i]['quantity']) !== 0 )
				{
					var mainThis = this;
					
					if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'products/sendproducts', {changeQuantity: 1, productId: quantity[i].product_id, quantity: quantity[i].quantity})
						.then(res => {
							
							this.divToInput(i);
							var allItems = mainThis.state.userProducts;
							mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
							mainThis.setState({userProducts: allItems, selectAll: false});
							
							if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
							mainThis.reloadTimeout = setTimeout(() => { mainThis.loadAllProducts(); }, 2000);
							
						}).catch(err => {})
				}
		}
	
	removeQuantity = (i) =>
		{
			var quantity = this.state.userProducts;
			if ( quantity[i]['quantity'] > 0 )
				{
					quantity[i]['quantity']--;
					this.setState({userProducts: quantity});
					
					var mainThis = this;
					
					if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'products/sendproducts', {changeQuantity: 1, productId: quantity[i].product_id, quantity: quantity[i].quantity})
						.then(res => {
							
							var allItems = mainThis.state.userProducts;
							mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
							mainThis.setState({userProducts: allItems, selectAll: false});
							
							if ( mainThis.reloadTimeout ) { clearTimeout(mainThis.reloadTimeout); }
							mainThis.reloadTimeout = setTimeout(() => { mainThis.loadAllProducts(); }, 2000);
							
						}).catch(err => {})
				}
		}
	
	renewAdd = () =>
		{
			var sendObjs = [];
			this.state.userProducts.map((checkedItem , i)=> checkedItem.checked && sendObjs.push(checkedItem.product_id) )
			
			if ( sendObjs.length > 0 )
				{
					this.setState({loading_products: true});
					var mainThis = this;
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'products/sendproducts', {renewAdd: 1, sentProducts: sendObjs})
						.then(res => {
							
							var allItems = mainThis.state.userProducts;
							mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
							mainThis.setState({userProducts: allItems, selectAll: false});
							mainThis.loadAllProducts();
							
						}).catch(err => {});
				}
			else
				{
					Util.showPopupDialog(
									'Obnova proizvoda',
									'Niste izabrali nijedan proizvod!',
									{title: 'Zatvori', css: 'middle_popup_blue_button', callback: () => { }},
									{title: '', css: '', callback: () => { }},
									() => { },
									'warning_popup');
				}

		}

	removeAdd = (prodId) =>
		{
			Util.showPopupDialog(
									'Potvrdite Brisanje',
									'Da li ste sigurni da želite da obrišete ovaj proizvod?',
									{title: 'Obriši', css: 'middle_popup_blue_button', callback: () => { this.removeAddConfirmed(prodId); }},
									{title: 'Prekini', css: 'middle_popup_yellow_button', callback: () => { }},
									() => { },
									'warning_popup');
		}
	
	removeAddConfirmed = (prodId) =>
		{
			this.setState({loading_products: true});
			var mainThis = this;
			axios.post(window.ajaxLink+'products/deleteproduct', {product_id: prodId})
				.then(res => {
					var allItems = mainThis.state.userProducts;
					mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
					mainThis.setState({userProducts: allItems, selectAll: false});
					mainThis.loadAllProducts();
					
				}).catch(err => {});
		}
	

	removeAllSelected = () =>
		{
			var sendObjs = [];
			this.state.userProducts.map((checkedItem , i)=> checkedItem.checked && sendObjs.push(checkedItem.product_id) )
			
			if ( sendObjs.length > 0 )
				{
					Util.showPopupDialog(
											'Potvrdite Brisanje',
											'Da li ste sigurni da želite da obrišete SVE IZABRANE proizvode?',
											{title: 'Obriši', css: 'middle_popup_blue_button', callback: () => { this.removeAddConfirmedAll(sendObjs); }},
											{title: 'Prekini', css: 'middle_popup_yellow_button', callback: () => { }},
											() => { },
											'warning_popup');
				}
			else
				{
					Util.showPopupDialog(
									'Brisanje proizvoda',
									'Niste izabrali nijedan proizvod!',
									{title: 'Zatvori', css: 'middle_popup_blue_button', callback: () => { }},
									{title: '', css: '', callback: () => { }},
									() => { },
									'warning_popup');
				}
		}
	
	
	removeAddConfirmedAll = (sendObjs) =>
		{
			this.setState({loading_products: true});
			var mainThis = this;
			axios.post(window.ajaxLink+'products/deleteproduct', {multiple_products: sendObjs})
				.then(res => {
					var allItems = mainThis.state.userProducts;
					mainThis.state.userProducts.map((checkItems, i)=> allItems[i].checked = false )
					mainThis.setState({userProducts: allItems, selectAll: false});
					mainThis.loadAllProducts();
					
				}).catch(err => {});
		}
	
	checkFilters = (filter) =>
		{
			if ( filter == 'all' )
				{
					this.setState({filteredProducts: this.state.userProducts.length});
				}
			if ( filter == 'expired' )
				{
					var expired = this.state.userProducts.filter(singleItem => singleItem.expired);
					var expiredProducts = expired.length;
					this.setState({filteredProducts: expiredProducts});
				}
			if ( filter == 'no-quantity' )
				{
					var noquantity = this.state.userProducts.filter(singleItem => singleItem.quantity == 0);
					var noquantityProducts = noquantity.length;
					this.setState({filteredProducts: noquantityProducts});
				}
		}
	
	render(){
		return (
				<div className="org_products_container">
					<div className="org_products_page_title">Organizator</div>
						<div className="org_products_button_container">
							<div className="org_products_button" onClick={this.renewAdd}>Obnovi Izabrane</div>
							<div className="org_products_button org_products_button_adj" onClick={this.removeAllSelected}>Obriši Izabrane</div>
						</div>
						<div className="org_tabs" onClick={this.uncheckAll}>
							<div className={(this.state.currentFilter == 'all' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'all'}); this.checkFilters('all'); }}>SVI</div>
							<div className={(this.state.currentFilter == 'expired' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'expired'}); this.checkFilters('expired'); }}>ISTEKLI</div>
							<div className={(this.state.currentFilter == 'no-quantity' ? 'active' : '')} onClick={() => { this.setState({currentFilter: 'no-quantity'}); this.checkFilters('no-quantity'); }}>NEMA NA STANJU</div>
						</div>
						{ this.state.filteredProducts !== 0 ?
							<div className="org_products_select_all_container">
								<div className={"org_products_checkbox"+( this.state.selectAll ? " org_products_checkbox_selected" : '')} onClick={this.checkAll}></div>
									<div className="org_products_select_all_text" onClick={this.checkAll}>Označi sve</div>
							</div>  :
							<div className="org_products_select_all_container">
								<div style={{"textAlign": "center", "padding":"40px 0"}} className="org_single_item_title">Nema rezultata</div>
							</div>
						}
						{ this.state.userProducts.map((singleItem, i )=>
								this.state.currentFilter == 'all' || ( this.state.currentFilter == 'expired' && singleItem.expired == true ) || ( this.state.currentFilter == 'no-quantity' && singleItem.quantity == 0 ) ?
								<div key={i} className={'org_products_single_item '+(singleItem.quantity == 0 ? ' org_warning' : '')+(singleItem.checked ? ' org_checked' : '')}>
									<div className={'org_single_item_title'+(singleItem.is_promoted == 1 ? ' org_toptitle_promoted':'')}>
										<div className={("org_products_checkbox org_single_item_checkbox_container_mobile "+(singleItem.checked ? " org_products_checkbox_selected" : ''))}></div>{singleItem.name}
									</div>
									<div>
										<div className="org_single_item_box org_single_item_checkbox_container">
											<div key={i} onClick={() => { this.getCheckBox(i) }}>
												<div className={("org_products_checkbox"+(singleItem.checked ? " org_products_checkbox_selected" : ''))}></div>
											</div>
										</div>
										<div className="org_single_item_box">
											<div className="org_single_item_picture" style={{backgroundImage: singleItem.image ? 'url('+ singleItem.image +')' : ''}}></div>
										</div>
										<div className="org_single_item_box">
											<div className="org_single_item_header org_single_item_header_adjust">Cijena</div>
											<div className="org_single_item_value org_single_item_value_price">{Util.formatPrice(singleItem.price)}</div>
										</div>
										<div className="org_single_item_box">
											<div className="org_single_item_header org_single_item_header_adjust">Obnovljeno</div>
											<div className={'org_single_item_value'+(singleItem.expired ? ' org_expired_warning' : '')}>{Util.dateTimeFormat(singleItem.time)}<div className="org_single_item_expire">{Util.timeUntilGet(singleItem.time_expire) == 'expired' ? "Isteklo" : "Ističe " + Util.timeUntilGet(singleItem.time_expire) }</div></div>
										</div>
										<div className="org_single_item_box">
											<div className="org_single_item_header org_single_item_header_adjust">Količina</div>
											<div className="org_single_item_value org_single_quantity">
												<div className="org_single_item_quantity_btn" onClick={() => { this.removeQuantity(i); this.checkFilters(this.state.currentFilter); }}>-</div>
													<div className="org_single_item_input_container">
														<input type="text" className="org_single_item_input" value={singleItem.quantity} onChange={(event) => { this.updateQuantity(event, i); this.checkFilters(this.state.currentFilter); }} />
													</div>
												<div className="org_single_item_quantity_btn" onClick={() => { this.addQuantity(i); this.checkFilters(this.state.currentFilter); }}>+</div>
											</div>
										</div>
										<div className="org_single_item_box">
											<div className="org_single_item_header org_single_item_header_adjust">Prodatih</div>
											<div className={'org_single_item_value '+(singleItem.soldCount ? ' org_soldmore' : '')}>{!singleItem.soldCount ? '-' : singleItem.soldCount}</div>
										</div>
										<div className="org_single_item_box org_single_item_last">
										{singleItem.quantity > 0 && !singleItem.expired && singleItem.image != '' && <div className={'org_single_item_promote_btn'+(singleItem.is_promoted == 1 ? ' org_promotedproduct':'')} onClick={() => { this.promoteAd(singleItem) }}>{singleItem.is_promoted == 0 ? 'Promoviši' : 'Promovisan'}</div> }
											<Link to={'/zamjeniproizvod/'+singleItem.product_id}><div className="org_single_item_delete_button">Izmijeni</div></Link>
											<div className="org_single_item_delete_buttony" onClick={() => { this.removeAdd(singleItem.product_id) }}>Obriši</div>
										</div>
									</div>
								</div>
								: ''
						)}
						
						<div className={'loader_holder'+(this.state.loading_products ? ' active' : '')}>
							<div className='loader_big'></div>
						</div>
				</div>
		)
	}
}

export default OrgProducts;