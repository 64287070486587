import { Component } from 'react';
import { Link } from "react-router-dom";
import './MessagesList.css';

import Util from '../../util/util';

export class MessagesList extends Component {
	
	render()
	{
		if ( !this.props.messages ) { this.props.messages = []; }
		
		var allMessages = [];
		for ( var oneMsg of this.props.messages )
			{
				if ( !this.props.filter || this.props.filter == 'all' || ( this.props.filter == 'sell' && oneMsg.selling ) || ( this.props.filter == 'buy' && !oneMsg.selling ) || ( this.props.filter == 'waiting' && oneMsg.message_read == 0 ) )
					{
						allMessages.push(oneMsg);
					}
			}
		
		allMessages.sort((a, b) => new Date(b.message_time).getTime() - new Date(a.message_time).getTime());   
		
		return (
				<div className="messages_list_all">
					{ allMessages.map((oneItem, j) =>
								<Link to={('/poruke/'+oneItem.product_id+'/'+oneItem.user_id)} key={j} >
									<div className={'messages_list_one'+(oneItem.message_read == 0 ? ' unread': '')}>
										<div className="user_message_name"><div className="user_message_image" style={{'backgroundImage': (oneItem.image ? 'url('+window.imagesLink+oneItem.image+')' : '')}}></div>{oneItem.username}&nbsp;</div>
										<div className="user_message_product">{oneItem.product_name}</div>
										<div className="user_message_text">{oneItem.sending == 1 && <div className="message_mysent"></div> }{oneItem.message}</div>
										<div className="user_message_time">{Util.timeAgoGet(oneItem.message_time)}</div>
									</div>
								</Link>
							)}
					
					{ allMessages.length == 0 && <div className="empty_screen">Nema poruka</div> }
				</div>
		);
	}
}