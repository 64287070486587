import { Component } from 'react';
import './Results.css';
import { SmallProduct } from '../smallproduct/SmallProduct';

import Util from '../../util/util';
import { Link } from 'react-router-dom';
import Promoted from '../../components/promotedproducts/Promoted';
import { SellerRating } from '../../components/sellerrating/SellerRating';

export class Results extends Component {
	
	constructor()
		{
			super();
			this.state = {
							showBackToTop: false,
							showCategory: false,
							sortMenuOpen: false,
							debug: '',
							
							showLimit: 12
						};
			
		}
	
	showHideCategory=(alwaysHide)=>
		{
			var doHide = !this.state.showCategory;
			if ( alwaysHide == true ) { doHide = false; }
			
			this.setState({showCategory: doHide}, function ()
				{
					if ( this.state.showCategory )
						{
							Util.hideOnOutsideClick(['section_find_categorieslist', 'section_find_categoriescurrent'], () =>
								{
									this.showHideCategory(true);
								});
						}
				});
		}
	
	showHideSortBy=(alwaysHide)=>
		{
			var doHide = !this.state.sortMenuOpen;
			if ( alwaysHide == true ) { doHide = false; }
			
			this.setState({sortMenuOpen: doHide}, function ()
				{
					if ( this.state.sortMenuOpen )
						{
							Util.hideOnOutsideClick(['section_find_categorieslistsort', 'section_find_categoriescurrentsort'], () =>
								{
									this.showHideSortBy(true);
								});
						}
				});
		}
	
	componentDidMount()
		{
			window.addEventListener('scroll', this.handleScroll);
		}
	
	handleScroll = (e) =>
		{
			if (document.getElementById('section_items_list') &&  document.querySelector('.section_items_list .section_oneitem') )
				{
					var scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight;
					var oneItemHeight = document.querySelector('.section_items_list .section_oneitem').offsetHeight;
					
					if ( window.scrollY > 100 )
						{
							this.setState({showBackToTop: true});
						}
					else
						{
							this.setState({showBackToTop: false});
						}
					
					this.setState({debug: window.scrollY+'--'+scrollMaxY});
					if ( scrollMaxY < window.scrollY + (oneItemHeight*4) )
						{
							var maxLoading = Math.ceil(((scrollMaxY+window.innerHeight) - document.getElementById('section_items_list').offsetTop) / oneItemHeight);
							maxLoading = (maxLoading + 3)*4;
							
							if ( maxLoading > this.state.showLimit )
								{
									this.setState({showLimit: maxLoading});
								}
						}
					
					if ( scrollMaxY - (oneItemHeight*4) < window.scrollY && this.props.extendSearch )
						{
							this.props.extendSearch();
						}
				}
		}
	
	componentWillUnmount()
		{
			window.removeEventListener('scroll', this.handleScroll);
		}

	render()
	{
		var productsList = [];
		var loadedNow = 0;
		for ( var oneItm of this.props.productsList )
			{
				//if ( this.props.currentCategory && this.props.currentCategory !== 'Sve Kategorije' && this.props.currentCategory !== oneItm.category ) { }
				//else if ( this.props.currentSearch && this.props.currentSearch !== '' && oneItm.name.toUpperCase().indexOf(this.props.currentSearch.toUpperCase()) < 0 ) { }
				if ( loadedNow > this.state.showLimit || ( this.props.maxShow && loadedNow > this.props.maxShow ) ) { break; }
				else if ( this.props.startShow && loadedNow <= this.props.startShow ) { loadedNow++; }
				else
					{
						productsList.push(oneItm);
						loadedNow++;
					}
			}
		return (
				<div className="sections_list results_page">
					<div className="section_item">
						{
							( this.props.showCategories || this.props.showSearch ) && <div className="section_left50">
								{ this.props.showCategories && <div className="section_find_categories">
									<div id="section_find_categoriescurrent" className="section_find_categoriescurrent" onClick={this.showHideCategory}>{this.props.currentCategory}</div>
									<div id="section_find_categorieslist" className={'section_find_categorieslist'+(this.state.showCategory ? ' active' : '')}>
										<div className="section_find_categorieslist_item" onClick={() => { this.props.setCategory('Sve Kategorije'); this.setState({showCategory: false}); }}><div>Sve Kategorije</div><div className="section_find_categorieslist_count">{this.props.productsListLength}</div></div>
										{ this.props.filterCategories.map((oneItem, j) =>
											<div className="section_find_categorieslist_item" key={j} onClick={() => { this.props.setCategory(oneItem.name); this.setState({showCategory: false}); } }><div>{oneItem.name}</div><div className="section_find_categorieslist_count">{oneItem.count}</div></div>
										)}
									</div>
								</div> }
							</div>
						}
						
						{ ( this.props.showCategories || this.props.showSearch ) &&  <div className="section_right50">
							{ this.props.showSearch && <input type="text" name="search_result" id="search_result" value={this.props.currentSearch} onChange={(event) => this.props.doSearch(event.target.value)} placeholder="Pretraga proizvoda..." /> }
						</div> }
						
						
						{ this.props.storesList && this.props.storesList.length > 0 && <div>
							{ this.props.storesList.map((oneItem, j) =>
											<div className="info_card_container mtop10" key={j}>
												<div className="profile_info_card">
													<div>
														<div className="profile_image" style={{'backgroundImage': (oneItem.image ? 'url('+window.imagesLink+oneItem.image+')' : '')}}></div>
														<div className="profile_name">{oneItem.username}</div>
													</div>
													<div>
														<Link to={("/radnja/"+oneItem.username)}><div className="profile_link">Profil</div></Link>
													</div>
												</div>
												<div className="product_rating_statistic">
													<SellerRating currentTab="seller" userId={oneItem.id} />
												</div>
											</div>
										)}
						
						</div> }
						
						
						
						{ ( !this.props.showCategories && !this.props.showSearch ) && <div className="section_left50">
									{ this.props.lastSearch && <div className="search_terms">Pretraživanje <span>{Util.firstLettersUppercase(decodeURI(this.props.lastSearch))}</span></div> }
								</div>
						}
						
						
						{
							( !this.props.showCategories && !this.props.showSearch && !this.props.homeItem ) && productsList.length > 0 && <div className="section_right50">
								<div className="section_find_categories">
									<div id="section_find_categoriescurrentsort" className="section_find_categoriescurrent" onClick={this.showHideSortBy}>{this.props.sortValue}</div>
									<div id="section_find_categorieslistsort" className={'section_find_categorieslist'+(this.state.sortMenuOpen ? ' active' : '')}>
										<div className="section_find_categorieslist_item" onClick={()=>{ this.props.sortBy('Najsličniji'); this.setState({sortMenuOpen: false}); }}>Najsličniji</div>
										<div className="section_find_categorieslist_item" onClick={()=>{ this.props.sortBy('Najnoviji'); this.setState({sortMenuOpen: false}); }}>Najnoviji</div>
										<div className="section_find_categorieslist_item" onClick={()=>{ this.props.sortBy('Najstariji'); this.setState({sortMenuOpen: false}); }}>Najstariji</div>
										<div className="section_find_categorieslist_item" onClick={()=>{ this.props.sortBy('Najviša cijena'); this.setState({sortMenuOpen: false}); }}>Najviša cijena</div>
										<div className="section_find_categorieslist_item" onClick={()=>{ this.props.sortBy('Najniža cijena'); this.setState({sortMenuOpen: false}); }}>Najniža cijena</div>
									</div>
								</div>
							</div>
						}
						
						<div id="section_items_list" className={'section_items_list'+(this.props.homeItem ? ' hidetopborder':'')}>
						
							{ this.props.promotedList && <Promoted productsList={this.props.promotedList} /> }
						
							{ productsList.length > 0 && productsList.map((oneItem, j) =>
								<SmallProduct key={j} oneItem={oneItem} homeItem={this.props.homeItem} />
							)}
							{ productsList.length == 0 && this.props.isMyProfile == false && <div className="no_results_text">Nema rezultata.</div> }
							
							{ productsList.length == 0 && this.props.isMyProfile == true &&
								<div className="no_results_text">
									<Link to="/novaprodaja"><div class="newsalebuttonres">Dodajte novi proizvod</div></Link>
								</div> }
						</div>
						
						{ this.props.homeItem == true && this.props.productsList.length == ((this.props.extendLimit-1)*40) && this.props.extendLimit < 4 &&
							<div className="results_button" onClick={this.props.loadProducts} >Učitaj još</div>
						}
						{ !this.props.showSearch && this.state.showBackToTop == true &&
							<div className="results_button results_backtotop" onClick={()=> {window.scrollTo(0, 0)}} ></div>
						}
					</div>
				</div>
		);
	}
}