import React, { Component } from 'react';
import axios from "axios";
import './Home.css';
import Util from '../../util/util';
import Promoted from '../../components/promotedproducts/Promoted';
import { Link } from 'react-router-dom';
import { Results } from '../../components/results/Results';
import { Footerhome } from '../../components/footerhome/Footerhome';

class Home extends Component {
	
	constructor()
		{ 
			super();
			var lastList = JSON.parse(localStorage.getItem('products_home'));
			var promotedp = JSON.parse(localStorage.getItem('promoted_products_home'));
			
			this.state = {
							itemsList: lastList && lastList.length > 0 ? lastList : [],
							promoted_products: promotedp && promotedp.length > 0 ? promotedp : [],
							bannerData: {
											'homepage_banner_title': '',
											'homepage_banner_sub_title': '',
											'homepage_banner_button': '',
											'homepage_banner_image': '',
											'homepage_banner_background': '',
											'homepage_banner_title_color': '',
											'homepage_banner_sub_title_color': '',
											'homepage_banner_link': '/',
											'homepage_banner_button_color': '',
											'homepage_banner_show_logged_status': '',
											'homepage_banner_show': '',
											'homepage_banner_title2': '',
											'homepage_banner_sub_title2': '',
											'homepage_banner_button2': '',
											'homepage_banner_image2': '',
											'homepage_banner_background2': '',
											'homepage_banner_title_color2': '',
											'homepage_banner_sub_title_color2': '',
											'homepage_banner_link2': '/',
											'homepage_banner_button_color2': '',
											'homepage_banner_show_logged_status2': '',
											'homepage_banner_show2': ''
										},
							bannerShowValue: false,
							sectionList: [],
							homeSubcategoryList: [],
							homeProductsPromoted: [],
							subCatToShow: [],
							subPromotedToShow: [],
							extendLimit: 1,
							heightContainerClass: 'show_subcategories_inactive',
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
							categoriesHeight: 0,
							nowcattop: ''
						};
		}
  
	componentDidMount()
		{
			document.title = this.props.pageTitle;
			
			var ret = Util.storagePreload(['global_settings', 'homepage_sections', 'homepage_catts', 'homepage_cpromoted'], 'json', [], false);
			
			this.setState({
								bannerData: ret['global_settings'],
								sectionList: ret['homepage_sections'],
								homeSubcategoryList: ret['homepage_catts'],
								homeProductsPromoted: ret['homepage_cpromoted']
							});
			
			var mainThis = this;
			axios.get(window.ajaxLink+'products/sections')
					.then(res => {

						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['sectionResults'] )
						{
							this.setState({sectionList: res['data']['responseData']['sectionResults']});
							localStorage.setItem('homepage_sections', JSON.stringify(res['data']['responseData']['sectionResults']));
						}
						
						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['bannerResults'] )
							{
								this.setState({bannerData: res['data']['responseData']['bannerResults']});
								localStorage.setItem('global_settings', JSON.stringify(res['data']['responseData']['bannerResults']));
							}
						
						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['allCat'] )
							{
								mainThis.setState({homeSubcategoryList: res['data']['responseData']['allCat'], homeProductsPromoted: res['data']['responseData']['allPromoted']});
								localStorage.setItem('homepage_catts', JSON.stringify(res['data']['responseData']['allCat']));
								localStorage.setItem('homepage_cpromoted', JSON.stringify(res['data']['responseData']['allPromoted']));
							}

					}).catch(err => {}
				);
			
			this.loadMore();
			
		}
	
	loadMore = () =>
		{
			var extendLimit = this.state.extendLimit;
			extendLimit++;
			this.setState({extendLimit: extendLimit});
			this.loadProducts();
		}
	
	loadProducts = () =>
		{
			axios.post(window.ajaxLink+'products', {extend_limit: this.state.extendLimit})
					.then(res => {
						
						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['products'] )
							{
								this.setState({itemsList: res['data']['responseData']['products'], promoted_products: res['data']['responseData']['promoted_products']});
								localStorage.setItem('products_home', JSON.stringify(res['data']['responseData']['products']));
								localStorage.setItem('promoted_products_home', JSON.stringify(res['data']['responseData']['promoted_products']));
							}
					}).catch(err => {}
				);
		}
		
	showSubCategories = (par, e) => 
		{
			var cats = this.state.homeSubcategoryList;
			var promotes = this.state.homeProductsPromoted;
			
			var length = 0;
			var promoted_length = 0;
			
			for ( var i of cats )
				{	
					if( i[par])
						{
							this.setState({subCatToShow: i[par]});
							length = i[par].length;
						}
				}
				
			for ( var j of promotes )
				{
					if ( j[par] )
						{
							this.setState({ subPromotedToShow : j[par] });
							promoted_length = j[par].length;
						}
				}
				
			var catContainer = document.querySelector('.one_card_hover_container');
			var fullW = catContainer.clientWidth;
			
			if ( fullW > 760 )
				{
					var hightByLength = Math.ceil(length/Math.floor(fullW/183))*100;
					
					if ( promoted_length > 0 )
						{
							hightByLength = hightByLength + document.querySelector('.section_oneitem.section_oneitem_home').clientHeight + 20;
						}
					
					this.setState({nowcattop: par,categoriesHeight: hightByLength == 0 ? 0 : hightByLength+10});
				}
			else
				{
					this.setState({categoriesHeight: 0});
				}
		}
		
	hideSubCategories = () => 
		{
			this.setState({nowcattop: '', categoriesHeight: 0});
		}

	render()	
	{
		return (
				<div className="home_page">
					{ this.state.bannerData.homepage_banner_show == true && ( this.state.bannerData.homepage_banner_show_logged_status == '' || ( this.state.bannerData.homepage_banner_show_logged_status == 1 && this.state.usersData.id ) || ( this.state.bannerData.homepage_banner_show_logged_status == 0 && !this.state.usersData.id ) )  &&
						<div className="homepage_banner_container">
							<div className="homepage_banner_left" style={{backgroundColor: this.state.bannerData.homepage_banner_background}}>
								<div className="homepage_banner_title" style={{color: this.state.bannerData.homepage_banner_title_color}}>{this.state.bannerData.homepage_banner_title}</div>
								<div className="homepage_banner_sub_title" style={{color: this.state.bannerData.homepage_banner_sub_title_color}}>{this.state.bannerData.homepage_banner_sub_title}</div>
								<a href={this.state.bannerData.homepage_banner_link}><div className="homepage_banner_button" style={{backgroundColor: this.state.bannerData.homepage_banner_button_color, color: this.state.bannerData.homepage_banner_background}}>
									{this.state.bannerData.homepage_banner_button}
										<svg width="24px" height="24px" viewBox="0 0 24 24" id="magicoon-Filled" xmlns="http://www.w3.org/2000/svg"><g id="arrow-right-Filled"><path id="arrow-right-Filled-2" data-name="arrow-right-Filled" style={{fill: this.state.bannerData.homepage_banner_background}} d="M21.707,12.707l-7,7a1,1,0,0,1-1.414-1.414L18.586,13H3a1,1,0,0,1,0-2H18.586L13.293,5.707a1,1,0,0,1,1.414-1.414l7,7A1,1,0,0,1,21.707,12.707Z"/></g></svg>
									</div>
								</a>
							</div>
							<div className="homepage_banner_right">
							<a href={this.state.bannerData.homepage_banner_link}><div className="homepage_banner_image" style={{backgroundImage: 'url('+this.state.bannerData.homepage_banner_image+')'}}></div></a>
							</div>
						</div>
					}
					{ this.state.bannerData.homepage_banner_show2 == true && ( this.state.bannerData.homepage_banner_show_logged_status2 == '' || ( this.state.bannerData.homepage_banner_show_logged_status2 == 1 && this.state.usersData.id ) || ( this.state.bannerData.homepage_banner_show_logged_status2 == 0 && !this.state.usersData.id ) )  &&
						<div className="homepage_banner_container">
							<div className="homepage_banner_left" style={{backgroundColor: this.state.bannerData.homepage_banner_background2}}>
								<div className="homepage_banner_title" style={{color: this.state.bannerData.homepage_banner_title_color2}}>{this.state.bannerData.homepage_banner_title2}</div>
								<div className="homepage_banner_sub_title" style={{color: this.state.bannerData.homepage_banner_sub_title_color2}}>{this.state.bannerData.homepage_banner_sub_title2}</div>
								<a href={this.state.bannerData.homepage_banner_link2}><div className="homepage_banner_button" style={{backgroundColor: this.state.bannerData.homepage_banner_button_color2, color: this.state.bannerData.homepage_banner_background2}}>
									{this.state.bannerData.homepage_banner_button2}
										<svg width="24px" height="24px" viewBox="0 0 24 24" id="magicoon-Filled" xmlns="http://www.w3.org/2000/svg"><g id="arrow-right-Filled"><path id="arrow-right-Filled-2" data-name="arrow-right-Filled" style={{fill: this.state.bannerData.homepage_banner_background2}} d="M21.707,12.707l-7,7a1,1,0,0,1-1.414-1.414L18.586,13H3a1,1,0,0,1,0-2H18.586L13.293,5.707a1,1,0,0,1,1.414-1.414l7,7A1,1,0,0,1,21.707,12.707Z"/></g></svg>
									</div>
								</a>
							</div>
							<div className="homepage_banner_right">
							<a href={this.state.bannerData.homepage_banner_link2}><div className="homepage_banner_image" style={{backgroundImage: 'url('+this.state.bannerData.homepage_banner_image2+')'}}></div></a>
							</div>
						</div>
					}
					<div className="all_sections_container nopadd" onMouseLeave={this.hideSubCategories}>
						<Link to="/k/automobili"><div className={'one_card_category '+(this.state.nowcattop=='Vozila'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Vozila', e)}>
							<div className="one_card_image ocimg_car"></div>
							<div className="one_card_title">Vozila</div>
						</div></Link>
						<Link to="/k/kompjuteri"><div className={'one_card_category '+(this.state.nowcattop=='Kompjuteri'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Kompjuteri', e)}>
							<div className="one_card_image ocimg_laptop"></div>
							<div className="one_card_title">Kompjuteri</div>
						</div></Link>
						<Link to="/k/mobilni-uređaji"><div className={'one_card_category '+(this.state.nowcattop=='Mobilni uređaji'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Mobilni uređaji', e)}>
							<div className="one_card_image ocimg_phone"></div>
							<div className="one_card_title">Mobilni Uredjaji</div>
						</div></Link>
						<Link to="/k/tehnika"><div className={'one_card_category '+(this.state.nowcattop=='Tehnika'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Tehnika', e)}>
							<div className="one_card_image ocimg_tech"></div>
							<div className="one_card_title">Tehnika</div>
						</div></Link>
						<Link to="/k/moj-dom"><div className={'one_card_category '+(this.state.nowcattop=='Moj dom'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Moj dom', e)}>
							<div className="one_card_image ocimg_home"></div>
							<div className="one_card_title">Moj Dom</div>
						</div></Link>
						<Link to="/k/ljepota-i-zdravlje"><div className={'one_card_category '+(this.state.nowcattop=='Ljepota i zdravlje'?'active':'')} onMouseEnter={(e) => this.showSubCategories('Ljepota i zdravlje', e)}>
							<div className="one_card_image ocimg_health"></div>
							<div className="one_card_title">Ljepota i zdravlje</div>
						</div></Link>
						<Link to="/svekategorije"><div className="one_card_category" onMouseEnter={(e) => this.showSubCategories(0, e)}>
							<div className="one_card_image ocimg_all"></div>
							<div className="one_card_title">Sve kategorije</div>
						</div></Link>
						<div className="one_card_hover_container" style={{'height': (this.state.categoriesHeight+'px')}}>
							<div className="home_subcategory_container">
								{	
										this.state.subCatToShow.map((oneItem,j)=>
											<Link key={oneItem.id} to={oneItem.link}>
												<div className="home_subcategory_item">
													<div className="home_subcategory_item0"></div>
													<div className="subcat_text">{oneItem.name}</div>
												</div>
											</Link>
								)}	
							</div>
							{ this.state.subPromotedToShow.length > 0 && <Promoted productsList={this.state.subPromotedToShow} homeItem={true} />}
						</div>
					</div>
					
					{ this.state.sectionList.map((sectionItem,i)=>
						<div key={i} className="homepage_card_and_title_container">
							<Link to={sectionItem.section_link}><div className="homepage_card_title_container">
								<div className="homepage_card_title">{sectionItem.section_title}</div>
									<div className="homepage_card_title_right">{sectionItem.section_seeall}<svg width="24px" height="24px" viewBox="0 0 24 24" id="magicoon-Filled" xmlns="http://www.w3.org/2000/svg"><g id="arrow-right-Filled"><path id="arrow-right-Filled-2" data-name="arrow-right-Filled" fill="#555" d="M21.707,12.707l-7,7a1,1,0,0,1-1.414-1.414L18.586,13H3a1,1,0,0,1,0-2H18.586L13.293,5.707a1,1,0,0,1,1.414-1.414l7,7A1,1,0,0,1,21.707,12.707Z"/></g></svg></div>
								</div>
							</Link>
							<div className="homepage_card_container">
							{ sectionItem.sub_sections.map((subSectionItem,j)=>
										<div key={j} className="homepage_card">
											<Link to={subSectionItem.item_link}><div className="homepage_card_image" style={{backgroundImage: 'url('+subSectionItem.item_image+')'}}></div></Link>
											<Link to={subSectionItem.item_link}><div className="homepage_card_description">{subSectionItem.item_title}</div></Link>
										</div>
							)}
							</div>
						</div>
					)}
					
					<Promoted productsList={this.state.promoted_products} homeItem={true} />
					<Results productsList={this.state.itemsList} homeItem={true} extendLimit={this.state.extendLimit} loadProducts={this.loadMore} />
					
					<Footerhome></Footerhome>
					
				</div>
		);
	}
}

export default Home;