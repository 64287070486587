import { Component } from 'react';
import { Link } from "react-router-dom";
import './Header.css';
import axios from "axios";

import Util from '../../util/util';


export class Header extends Component {
	
	constructor()
	{
		super();
		var params = new URLSearchParams(window ? window.location.search : {});
		this.state = {
						currentLocation: params.get('l') ? params.get('l') : 0,
						currentSearch: params.get('q') ? params.get('q') : '',
						loginEmail: '',
						loginPass: '',
						usernameError: '',
						passwordError: '',
						showLogin: false,
						showUserMenu: false,
						loadingLogin: false,
						usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
						unread_messages: 0,
						facebookLogin: '',
						updateEvent: false
					};
	}
	
	searchProducts=()=>
	{
		this.props.navigate('/pretraga?q='+this.state.currentSearch+(this.state.currentLocation ? '&l='+this.state.currentLocation : ''));
	}
	
	showHideLogin=(alwaysHide)=>
	{
		var doHide = !this.state.showLogin;
		if ( alwaysHide == true ) { doHide = false; }
		
		if ( doHide ) { window.addBlur('.Page'); } else { window.removeBlur('.Page'); }
		
		this.setState({showLogin: doHide}, function ()
			{
				if ( this.state.showLogin )
					{
						Util.hideOnOutsideClick(['header_login_div', 'header_login', 'header_postbutton'], () =>
							{
								this.showHideLogin(true);
							});
					}
			});
	}
	
	doCloseLogin = () =>
		{
			window.removeBlur('.Page');
			this.setState({
				showLogin: false,
				showUserMenu: false,
			});
		}
	
	showHideMenu=(alwaysHide)=>
	{
		var doHide = !this.state.showUserMenu;
		if ( alwaysHide == true ) { doHide = false; }
		
		this.setState({showUserMenu: doHide}, function ()
			{
				if ( this.state.showUserMenu )
					{
						Util.hideOnOutsideClick(['header_loggeduser'], () =>
							{
								this.showHideMenu(true);
							});
					}
			});
	}
	
	doLogin=(doFacebook)=>
	{
		var mainThis = this;
		this.setState({usernameError: '', passwordError: ''});
		 if ( !Util.checkParams(this.state.loginEmail, 'emailphonename') && !doFacebook )
			{
				 //Wrong username / email / phone
				this.setState({usernameError: 'Korisničko ime, email ili broj telefona nisu pravilno upisani'});
			} 
		else if ( !Util.checkParams(this.state.loginPass, 'password') && !doFacebook )
			{
				// Wrong password
				this.setState({passwordError: 'Password / Lozinka nije pravilno upisan'});
			}
		else
			{
				this.setState({loadingLogin: true});
				axios.post(window.ajaxLink+'user/login', {username: this.state.loginEmail, 'password': this.state.loginPass, 'facebookLogin': this.state.facebookLogin})
						.then(res => {
							
							if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['id'] )
								{
									Util.eList = [];
									Util.callback = false;
									
									localStorage.clear();
									
									mainThis.setState({showLogin: false, loadingLogin: false, usersData: res['data']['responseData']});
									window.removeBlur('.Page');
									localStorage.setItem('user_data', JSON.stringify(res['data']['responseData']));
									
									mainThis.getUnreadCount();
									window.location.reload();
								}
							else
								{
									mainThis.setState({loadingLogin: false, passwordError: 'Korisnički detalji nisu ispravni.'});
								}
						}).catch(err => {
											Util.handleAjaxError(err, mainThis);
											mainThis.setState({loadingLogin: false});
										});
			}
	}
	
	doLogOut=()=>
		{
			this.setState({showLogin: false, showUserMenu: false, usersData: {}});
			window.removeBlur('.Page');
			localStorage.clear();
			if ( window.FB && window.FB.getUserID() ) { window.FB.logout(); }
			window.location.href = '/';
		}
	
	componentDidMount()
		{
			this.getUnreadCount();
		}
	
	getUnreadCount()
	{
		if ( this.state.usersData && this.state.usersData.id && this.state.usersData.token )
			{
				var mainThis = this;
				axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
				axios.get(window.ajaxLink+'message/count')
						.then(res => {
						
							if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['messagesCount'] )
								{
									mainThis.setState({unread_messages: res['data']['responseData']['messagesCount']});
								}
							else
								{
									mainThis.setState({unread_messages: 0});
								}
							mainThis.createListeningUpdate();
							
						}).catch(err => {
							
							mainThis.setState({unread_messages: 0});
							if ( err && err.response && err.response.data && err.response.data.errorData && err.response.data.errorData.name == 'TokenExpiredError' )
								{
									mainThis.setState({showLogin: false, showUserMenu: false, usersData: {}});
									window.removeBlur('.Page');
									localStorage.removeItem('user_data');
									window.location.reload();
								}
						}
					);

			}
	}
	
	
	createListeningUpdate()
		{
			if ( this.state.updateEvent )
				{
					this.state.updateEvent.close();
				}
			
			if ( this.state.usersData && this.state.usersData.id && this.state.usersData.token )
				{
					var mainThis = this;
					var newEvent = Util.listenSSEvent('user_listening', window.ajaxLink+'sse/subscribe?name=userId&value='+this.state.usersData.id+'&token='+this.state.usersData.token);
					
					Util.addSSEventCallback('user_listening', 'header_listen', function (data)
						{
							if ( data && data['update'] && data['update']['messageCount'] !== undefined )
								{
									if ( mainThis.state.unread_messages !== data['update']['messageCount'] )
										{
											mainThis.setState({unread_messages: data['update']['messageCount']});
										}
								}
						});
					
					this.setState({updateEvent: newEvent});
				}
		}
	
	loginFacebook = () =>
		{
			if ( window.FB )
				{
					var mainThis = this;
					window.FB.login(function(response)
						{
							if ( response && response.authResponse && response.authResponse.accessToken )
								{
									mainThis.setState({facebookLogin: response.authResponse.accessToken}, () => { mainThis.doLogin(true); });
								}
							
						}, {scope: 'public_profile,email'});
				}
		}
	
	render()
	{
		return (
				<div className="header">
					<div className="header_middle">
						<Link to="/"><div className="header_logo"></div></Link>
						<div className="header_search header_search_desktop">
							<div className="header_location">
								<select id="search_location" onChange={(event) => { this.setState({currentLocation: event.target.value}); }} value={this.state.currentLocation}>
									<option value="0">Sve Lokacije</option>
									<option value="" disabled="disabled" style={{'color': 'inherit', 'background': '#ccc'}}>Republika Srpska</option>
									<option value="1">Banjalučka</option>
									<option value="2">Dobojsko-Bijeljinska</option>
									<option value="3">Sarajevsko-Zvornička</option>
									<option value="4">Trebinjsko-Fočanska</option>
									<option value="" disabled="disabled" style={{'color': 'inherit', 'background': '#ccc'}}>Federacija BiH</option>
									<option value="5">Sarajevo</option>
									<option value="6">Unsko-sanski</option>
									<option value="7">Posavski</option>
									<option value="8">Tuzlanski</option>
									<option value="9">Zeničko-dobojski</option>
									<option value="10">Bosansko-podrinjski</option>
									<option value="11">Srednjobosanski</option>
									<option value="12">Hercegovačko-neretvanski</option>
									<option value="13">Zapadno-hercegovački</option>
									<option value="14">Livanjski</option>
									<option value="15">Distrikt Brčko</option>
								</select>
							</div>
							<div className="header_search_input">
								<input type="text" name="search_top" id="search_top" value={this.state.currentSearch} onChange={(event) => this.setState({currentSearch: event.target.value})} onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); this.searchProducts(); }}}  placeholder="Probajte: Laptop HP" />
								<div onClick={this.searchProducts}></div>
							</div>
						</div>
						{ this.state.usersData.id == undefined && <div id="header_login" className="header_login" onClick={this.showHideLogin}>Prijava</div> }
						<div className="header_login_pix">
							<div id="header_login_div" className={'header_login_div'+(this.state.showLogin ? ' active' : '')}>
								<div className="header_login_left">
									<div className="header_login_chat">
										<div>Razmjena poruka sa kupcima i prodavcima</div>
										Šaljite i primajte poruke, komentarišite proizvode, šaljite ponude, itd.
									</div>
									<div className="header_login_delivery">
										<div>Sigurna i brza dostava</div>
										Besplatna dostava za neke od ponuđenih proizvoda.
									</div>
									<div className="header_login_shop">
										<div>Besplatno otvaranje online radnje</div>
										Postavljanje proizvoda za prodaju je besplatno za sve radnje.
									</div>
								</div>
								<div className="header_login_right">
									<div className="mobile_close" onClick={() => { this.setState({showLogin: false}); window.removeBlur('.Page'); }}>&times;</div>
									<form onSubmit={this.doLogin}>
										<div className="header_login_title">Korisničko ime, email ili broj telefona</div>
										<input type="text" name="header_login_email" id="header_login_email" value={this.state.loginEmail} onChange={(event) => this.setState({loginEmail: event.target.value})} onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); this.doLogin(); }}}  placeholder="064123..." />
										{ this.state.usernameError && <div className="header_login_error">{this.state.usernameError}</div> }
										<div className="header_login_title">Password / Lozinka</div>
										<input type="password" name="header_login_pass" id="header_login_pass" value={this.state.loginPass} onChange={(event) => this.setState({loginPass: event.target.value})} onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); this.doLogin(); }}} />
										{ this.state.passwordError && <div className="header_login_error">{this.state.passwordError}</div> }
										{ /* <Link to="/zaboravljen-login"><div className="header_login_forgot">Zaboravili ste password?</div></Link> */ }
										<div className="header_login_button" onClick={this.doLogin}>Prijava</div>
										<Link to='/lozinka'><div className="forgotenPassword" onClick={this.doCloseLogin}>Zaboravili ste lozinku?</div></Link>
									</form>
									<div className="header_login_no_account">
										<div className="header_login_no_account_text">Ako se niste registrovali do sad:</div>
										<Link to="/registracija" onClick={() => { this.setState({showLogin: false}); window.removeBlur('.Page'); }} className="header_register_button">Otvorite Novi Nalog</Link>
										<div className="header_login_button_facebook" id="headerfacebooklogin" onClick={this.loginFacebook}>Facebook Pristup</div>
									</div>
									<div className={'loader_small'+(this.state.loadingLogin ? ' active' : '')}></div>
								</div>
								<div className="header_login_mobile">
									<div className="header_login_chat">
										<div>Razmjena poruka sa kupcima i prodavcima</div>
										Šaljite i primajte poruke, komentarišite proizvode, šaljite ponude, itd.
									</div>
									<div className="header_login_delivery">
										<div>Sigurna i brza dostava</div>
										Besplatna dostava za neke od ponuđenih proizvoda.
									</div>
									<div className="header_login_shop">
										<div>Besplatno otvaranje online radnje</div>
										Postavljanje proizvoda za prodaju je besplatno za sve radnje.
									</div>
								</div>
							</div>
						</div>
						{ this.state.usersData.id == undefined && <Link to="/placanje"><div className="header_postbutton" id="header_postbutton">Besplatan Oglas</div></Link> }
						
						
						{ this.state.usersData.id !== undefined && <div className="header_loggedin_section">
							<div id="header_loggeduser" className="header_loggeduser" onClick={this.showHideMenu}><div className="header_userimage" style={{'backgroundImage': (this.state.usersData.image ? 'url('+this.state.usersData.image+')' : '')}}></div><div className="username_dropmenu">{this.state.usersData.name}&nbsp;</div><div></div></div>
							<Link to="/poruke"><div className="header_messages" key={('msg_count_'+this.state.unread_messages)}>{(this.state.unread_messages > 0 ? <div>{this.state.unread_messages}</div> : '')}&nbsp;</div></Link>
							<div  id="header_dropdownmenu_div" className={'header_logged_dropdown'+(this.state.showUserMenu ? ' active' : '')}>
								
								<Link to="/novaprodaja"><div className="header_postbutton">Nova prodaja</div></Link>
								
								<Link to={'/radnja/'+(this.state.usersData.name).toLowerCase()}><div className="header_group_spacing">Moj Profil</div></Link>
								<Link to="/podesavanja"><div>Podešavanja</div></Link>
								<Link to="/obavjestenja"><div>Obavještenja</div></Link>
								<Link to="/statistika"><div>Statistika</div></Link>
								<Link to="/organizovanjeproizvoda"><div>Organizovanje proizvoda</div></Link>
								<Link to={'/radnja/'+(this.state.usersData.name).toLowerCase()+'#prosli'}><div>Prodati proizvodi</div></Link>
								<Link to="/kupljeniproizvodi"><div>Kupljeni proizvodi</div></Link>
								
								<div className="header_group_spacing" onClick={this.doLogOut}>Odjava</div>
							</div>
						</div> }
						
						
						
						<div className="header_search header_search_mobile">
							<div className="header_location">
								<select id="search_location" onChange={(event) => { this.setState({currentLocation: event.target.value}, () => { this.searchProducts(); }); }} value={this.state.currentLocation}>
									<option value="0">Sve Lokacije</option>
									<option value="" disabled="disabled" style={{'color': 'inherit', 'background': '#ccc'}}>Republika Srpska</option>
									<option value="1">Banjalučka</option>
									<option value="2">Dobojsko-Bijeljinska</option>
									<option value="3">Sarajevsko-Zvornička</option>
									<option value="4">Trebinjsko-Fočanska</option>
									<option value="" disabled="disabled" style={{'color': 'inherit', 'background': '#ccc'}}>Federacija BiH</option>
									<option value="5">Sarajevo</option>
									<option value="6">Unsko-sanski</option>
									<option value="7">Posavski</option>
									<option value="8">Tuzlanski</option>
									<option value="9">Zeničko-dobojski</option>
									<option value="10">Bosansko-podrinjski</option>
									<option value="11">Srednjobosanski</option>
									<option value="12">Hercegovačko-neretvanski</option>
									<option value="13">Zapadno-hercegovački</option>
									<option value="14">Livanjski</option>
									<option value="15">Distrikt Brčko</option>
								</select>
							</div>
							<div className="header_search_input">
								<input type="text" name="search_top" id="search_top"  value={this.state.currentSearch} onChange={(event) => this.setState({currentSearch: event.target.value})} onKeyDown={(e) => { if (e.keyCode === 13) { e.preventDefault(); this.searchProducts(); }}} placeholder="Probajte: Laptop HP" />
								<div onClick={this.searchProducts}></div>
							</div>
						</div>
					</div>
				</div>
		);
	}
}