import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './Notifications.css';

class Notifications extends Component {
	constructor()
	{
		super();
		this.state = {
			usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
			emailSelected: false,
			smsSelected: false
		};
	}

	componentDidMount()
		{
			document.title = this.props.pageTitle;
			this.setState({emailSelected: this.state.usersData.email_notifications, smsSelected: this.state.usersData.sms_notifications});
		}
	
	confirmChoice = () =>
		{
			axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
			axios.post(window.ajaxLink+'user/updateNotifications', {
				user_id: this.state.usersData.id, 
				email_selected: this.state.emailSelected, 
				sms_selected: this.state.smsSelected
				})
				.then(res => {
					
					if ( res['data'] && res['data']['responseData'] )
						{
							if (res['data']['responseData'].success == 1 )
								{
									var userData = JSON.parse(localStorage.user_data);
									if ( this.state.emailSelected == true )
										{
											userData.email_notifications = 1;
										}
									else
										{
											userData.email_notifications = 0;
										}
									
									if ( this.state.smsSelected == true )
										{
											userData.sms_notifications = 1;
										}
									else
										{
											userData.sms_notifications = 0;
										}
									localStorage.setItem('user_data', JSON.stringify(userData));
									window.location = '/mojprofil';
								}
						}
					
							}).catch(err => {  }
						);
		}
	
	render(){
		console.log(JSON.parse(localStorage.getItem('user_data')));
		return (
			<div className="notifications_page">
					<div className="notifications_page_title">Obavještenja</div>
					<div className="notifications_page_text"><p>Na ovoj stranici imate mogućnost da kontrolišete sva obavještenja koja dobijate van OPK.ba.</p><p> Na osnovu podrazumijevanih podešavanja ove opcije su isključene kako bi se spriječio neželjeni spam jer se sve poruke šalju automatski. </p><p>Ukoliko na ovoj stranici vidite da su ove opcije isključene, a sigurni ste da ipak dobijate poruke od OPK.ba molimo <Link to={'/kontakt'}> kontaktirajte nas</Link>.</p></div>
					<div className="notifications_switch_container">
						<div className="notifications_text">Email obavještenja</div>
						<div className={"notifications_switch"+(this.state.emailSelected == true ? " switch_checked" : "")} onClick={()=> this.setState({emailSelected: !this.state.emailSelected})} >
							<div className={"switch_slide"+(this.state.emailSelected == true ? " switch_slide_checked" : "")}></div>
						</div>
						<div className="notifications_page_text text_below_option">* odabirom ove opcije dobijaćete email svaki put kada Vam korisnik sajta pošalje privatnu poruku ili naruči Vaš proizvod</div>
					</div>
					<div className="notifications_switch_container">
						<div className="notifications_text">SMS obavještenja</div>
						<div className={"notifications_switch"+(this.state.smsSelected == true ? " switch_checked" : "")} onClick={()=> this.setState({smsSelected: !this.state.smsSelected})} >
							<div className={"switch_slide"+(this.state.smsSelected == true ? " switch_slide_checked" : "")}></div>
						</div>
						<div className="notifications_page_text text_below_option">* odabirom ove opcije dobijaćete SMS poruku svaki put kada Vam korisnik sajta pošalje privatnu poruku ili naruči Vaš proizvod</div>
					</div>
					<div className="notifications_button" onClick={this.confirmChoice} >Sačuvaj izmjene</div>
				</div>
			
		)
	}
}


export default Notifications;