import React, {Component} from 'react';
import axios from 'axios';

import './NewProductCategory.css';

import { SelectAnimated } from '../../components/elements/SelectAnimated';


class NewProductCategory extends Component{
	constructor()
	{
		super();
		this.defaultState = {
			mainCategory: '',
			mainCategoryError: '',
			category_list: [],
			
			subCategory: '',
			subCategoryError: '',
			subCategoryList: [],
			
			subSubCategory: '',
			subSubCategoryError: '',
			subSubCategoryList: [],
			
			subSubSubCategory: '',
			subSubSubCategoryList: [],
			
			isLoaded: false
		}
		
		this.state = this.defaultState;
	}
	
	UNSAFE_componentWillReceiveProps = (nextProps) =>
		{
			if ( this.state.isLoaded == false && nextProps.mainCategory  )
				{
					this.loadAllCategoriesParent(nextProps, this.state.isLoaded);
				}
			
			if ( !nextProps.mainCategory )
				{
					this.setState(this.defaultState);
				}
		}
	
	loadAllCategoriesParent = (propsNext, isLoaded) =>
		{
			if ( isLoaded == false )
				{
					var mainCategory = this.state.mainCategory;
					var subCategory = this.state.subCategory;
					var subSubCategory = this.state.subSubCategory;
					var subSubSubCategory = this.state.subSubSubCategory;
					
					if ( propsNext.mainCategory ) { mainCategory = propsNext.mainCategory; }
					if ( propsNext.subCategory ) { subCategory = propsNext.subCategory; }
					if ( propsNext.subSubCategory ) { subSubCategory = propsNext.subSubCategory; }
					if ( propsNext.subSubSubCategory ) { subSubSubCategory = propsNext.subSubSubCategory; }
					
					this.setState({isLoaded: true, mainCategory: mainCategory, subCategory: subCategory, subSubCategory: subSubCategory, subSubSubCategory: subSubSubCategory}, () => 
						{
							if ( mainCategory )
								{
									this.getNextCategory(mainCategory, 'subCategoryList', true);
								}
							
							if ( subCategory )
								{
									this.getNextCategory(subCategory, 'subSubCategoryList', true);
								}
							
							if ( subSubCategory )
								{
									this.getNextCategory(subSubCategory, 'subSubSubCategoryList', true);
								}
						});
				}
		}
	
	getNextCategory = (cat_id, cat_model, auto_load, callback) =>
		{
			if ( !auto_load )
				{
					console.log(cat_model);
					
					if ( cat_model == 'subCategoryList' )
						{ 
							this.updateChild({subCategory: '', subCategoryList: [], subSubCategory: '', 'subSubCategoryList': [], subSubSubCategory: '', 'subSubSubCategoryList': []});
						}
					else if ( cat_model == 'subSubCategoryList' )
						{
							this.updateChild({subSubCategory: '', 'subSubCategoryList': [], subSubSubCategory: '', 'subSubSubCategoryList': []});
						}
					else if ( cat_model == 'subSubSubCategoryList' )
						{ 
							this.updateChild({subSubSubCategory: '', 'subSubSubCategoryList': []});
						}
				}
			
			var mainThis = this;
			axios.post(window.ajaxLink+'products/getCategory', {cat_id: cat_id})
				.then(res => {
					var categoriesList = res['data']['responseData']['categories'];
					categoriesList.unshift({id: '', name: '', disabled: true, default: true});
					mainThis.setState({[cat_model]: categoriesList});
					
					if ( auto_load ) { mainThis.loadAllCategoriesParent(this.props); mainThis.props.checkFields(); }
					if ( callback ) { callback(); }
					
				}).catch(err => {});
		}
	
	updateChild = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
					this.props.updateChild(val);
				}
			else
				{
					this.setState(val);
					this.props.updateChild(val);
				}
			
		}
	
	render()
		{

			return(
				<div className="new_product_category">
					<div className="category_title">
					<span>Odaberite kategoriju</span>
					<div className="animated_input_description">
						<div className="description_icon"></div>
						<div className="description_icon_hover">Morate odabrati 1 glavnu i 1 potkategoriju</div>
					</div>
					</div>	
									
					<div className="category_container">
						<div className="select_categories_container">
							<SelectAnimated id="mainCategory" title="Odaberite kategoriju"  fieldError={this.props.mainCategoryError} fieldName="mainCategory" fieldValue={this.state.mainCategory} callback={() => { this.getNextCategory(this.state.mainCategory, 'subCategoryList', false, this.props.refreshList); }} updateChild={this.updateChild} options_list={this.props.categoryList}/>													
						</div>
						{
							this.props.mainCategory && <div className="select_categories_container">
								<SelectAnimated id="subCategory" title="Odaberite Potkategoriju" fieldName="subCategory" fieldError={this.props.subCategoryError} fieldValue={this.state.subCategory} callback={() => { this.getNextCategory(this.state.subCategory, 'subSubCategoryList', false, this.props.checkFields); }} updateChild={this.updateChild} options_list={this.state.subCategoryList}/>
							</div>
						}
						
						{
							this.props.subCategory && this.state.subSubCategoryList.length > 1 && <div className="select_categories_container">
								<SelectAnimated id="subSubCategory" title="Odaberite Potkategoriju" fieldName="subSubCategory" fieldValue={this.state.subSubCategory} fieldError={this.props.subSubCategoryError} callback={() => { this.getNextCategory(this.state.subSubCategory, 'subSubSubCategoryList', false, this.props.checkFields); }} updateChild={this.updateChild} options_list={this.state.subSubCategoryList}/>
							</div>
							
						}
						
						{
							this.state.subSubCategory && this.state.subSubSubCategoryList.length > 1 && <div className="select_categories_container">
								<SelectAnimated id="subSubSubCategory" title="Odaberite Potkategoriju" fieldName="subSubSubCategory" fieldValue={this.state.subSubSubCategory} fieldError={this.props.subSubSubCategoryError} updateChild={this.updateChild} callback={()=> { this.props.checkFields();}} options_list={this.state.subSubSubCategoryList}/>
							</div>
						}
					</div>
				</div>
			)
		}
}

export default NewProductCategory;