import React, { Component } from 'react';
import axios from "axios";
import Util from '../../util/util';

import './Register.css';

import { InputAnimated } from '../../components/elements/InputAnimated';

class Register extends Component {
	
	constructor()
		{
			super();
			this.state = {
							
							register_email: '',
							register_username: '',					
							register_password: '',
							
							email_error: '',
							username_error: '',	
							password_error: '',
							
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
							unread_messages: 0,
							
							confirm_code: '',
							terms_agreed: false,
							email_confirmed: false,
							pending_confirmation: false,
							
							show_error: false,
							loading_register: false,
							terms_agreed_error: '',
							confirm_message: '',
			};
		}
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
		}
	
	doImageUpload = () =>
		{
			if ( this.state.changeImage || this.state.changeImageTop )
				{
					const formData = new FormData();
					if ( this.state.changeImage ) { formData.append('files', this.state.changeImage); }
					if ( this.state.changeImageTop ) { formData.append('files', this.state.changeImageTop); }
					
					var mainThis = this;
					axios.post(window.ajaxLink+'products/uploadImages', formData, {headers: {'Content-Type': 'multipart/form-data'}})
						.then(res => {
							
							var img1 = mainThis.state.changeImage;
							var img2 = mainThis.state.changeImageTop;
							
							if ( img1 && !img2 )
								{
									mainThis.doRegister(res['data']['responseData']['imagesList'][0], false);
								}
							else if ( !img1 && img2 )
								{
									mainThis.doRegister(false, res['data']['responseData']['imagesList'][0]);
								}
							else if ( img1 && img2 )
								{
									mainThis.doRegister(res['data']['responseData']['imagesList'][0], res['data']['responseData']['imagesList'][1]);
								}
							
						}).catch(err => {}); 
				}
			else
				{
					this.doRegister();
				}
		}
	
	doRegister = (new_image, new_image_top) =>
		{
			this.setState({
								email_error: '',
								username_error: '',
								password_error: '',
								
								terms_agreed_error: '',
								show_error: false,
			});
			
			var allValid = true;
			 //email validation
			if( !Util.checkParams(this.state.register_email, 'emailphone'))
				{
					this.setState({ email_error:'Email ili broj telefona nije pravilno upisan'});
					allValid = false;
				}
			
			//password validation
			if ( !Util.checkParams(this.state.register_password, 'password'))
				{
					this.setState({password_error: 'Šifra / Lozinka mora imati minimum 5 karaktera.'});
					allValid = false;
				}
				
			//username validation
			if	( !Util.checkParams(this.state.register_username, 'username'))
				{
					this.setState({username_error:'Username / Korisničko ime mora sadržati više od 5 karaktera.'});
					allValid = false;
				
				}

			if ( allValid == true )
				{
					if ( this.state.pending_confirmation == false )
						{
							this.resendCode();
							allValid = false;
						}
				}
			
			 if ( allValid == true && this.state.confirm_code.length < 5 )
				{
					this.setState({confirm_message: 'Morate unijeti kod koji ste dobili na Vaš email.'});
					allValid = false;
				} 
			
			if ( !this.state.terms_agreed)
				{
					this.setState({terms_agreed_error: 'Morate prihvatiti uslove korištenja.', show_error: true,});
					allValid = false;
					
				}
			// ajax register
			if ( allValid == true && this.state.terms_agreed == true )
				{
					var mainThis = this;
					this.setState({loading_register: true}); //loader page for register
					axios.post(window.ajaxLink+'user/register', {
								email:  this.state.register_email, 
								username : this.state.register_username,
								password : this.state.register_password,
								registerCode : this.state.confirm_code,
								newImage: new_image ? new_image : false,
								newImageTop: new_image_top ? new_image_top : false
							}).then(response => {
								
								 if ( response['data'] && response['data']['responseData'] && response['data']['responseData']['id'])
									{
										Util.eList = [];
										Util.callback = false;
										mainThis.setState({ loading_register: false, usersData: response['data']['responseData']});
										localStorage.setItem('user_data', JSON.stringify(response['data']['responseData']));
										window.location = '/mojprofil';
									}
								else
									{
										//mainThis.setState({email_error: 'Korisnički nalog sa ovim podacima vec postoji'});
									}  
							
							}).catch(err => {
											Util.handleAjaxError(err, mainThis);
										});
				}
		}
	
	resendCode = () =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'user/verifyEmail', {email: this.state.register_email, username : this.state.register_username})
			.then(res => {
				console.log(res);
				mainThis.setState({pending_confirmation: true, confirm_message: res['data']['responseData']['msg']});

			}).catch(err => {
					Util.handleAjaxError(err, mainThis);
				});
		}
	
	verifyConfirmCode = () =>
		{
			var codeVerify = this.state.confirm_code.trim().replace(/[^.\d]/g, '');
			codeVerify = codeVerify.substr(0, 5);
			this.setState({confirm_code: codeVerify});
		}

	agreeTerms = () =>
		{
			var doAgree = this.state.terms_agreed;
			doAgree = !doAgree;
			this.setState({ terms_agreed: doAgree, terms_agreed_error: false, show_error: false });
		}
		

		
	selectImages = async (e) =>
		{
			if ( e.target.files ) 
				{
					let img = e.target.files[0];
					this.setState({
							settingsImage: URL.createObjectURL(img),
							changeImage: img
						});
				}
		}

	selectImages2 = async (e) =>
		{
			if ( e.target.files ) 
				{
					let img = e.target.files[0];
					this.setState({
							settingsImageTop: URL.createObjectURL(img),
							changeImageTop: img
						});
				}
		}
	
	updateChild  = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
			
		}
	
	cleanupStoreName = () =>
		{
			var sname = this.state.register_username.toLowerCase();
			sname = sname.replace(/[^0-9a-z]/g, '');
			this.setState({register_username: sname});
		}
	
	loginFacebookSimulate = () =>
	{
		var elm = document.getElementById('headerfacebooklogin');
		if ( elm ) { elm.click(); }
	}
	
	render()
	{
		return (
				<div className="register_page">
					<form onSubmit={this.doImageUpload}>
						<div className="register_topImage" style={{'backgroundImage': (this.state.settingsImageTop ? 'url('+this.state.settingsImageTop+')' : '')}}>
							<input type="file" className="file_upload" accept="image/*" onChange={this.selectImages2} />
							<div className="register_topIcon">
								<div className="register_topIcon_text">Dodaj pozadinu</div>
							</div>
						</div>
						<div className="register_userimage" id="img11" style={{'backgroundImage': (this.state.settingsImage ? 'url('+this.state.settingsImage+')' : '')}}>
							<input type="file" className="file_upload" accept="image/*" onChange={this.selectImages} />
							<div className="register_profileIcon"></div>
						</div>
						<div className="register_page_title">Otvaranje Novog Naloga</div>
						<div className="register_left">
							<div className="register_form">
								<div className="register_form_toptitle">Registracija novog korisnika</div>
								
								<InputAnimated type="text" id="register_form_emailphone" title="Email ili Broj telefona (format: 06XXXXXXX)" placeholder="npr: 06612341.." fieldValue={this.state.register_email} fieldName="register_email" fieldError={this.state.email_error} updateChild={this.updateChild} />
								<InputAnimated type="text" id="register_form_username" title="Korisničko ime" placeholder="npr: mojaradnja" fieldValue={this.state.register_username} fieldName="register_username" fieldError={this.state.username_error} updateChild={(val, call) => { this.updateChild(val, this.cleanupStoreName); }} />
								<InputAnimated type="password" id="register_form_pass" title="Šifra / Lozinka" placeholder="Slova i znakovi" fieldValue={this.state.register_password} fieldName="register_password" fieldError={this.state.password_error} updateChild={this.updateChild} />

								<div className="agree_terms">
									<div className={('terms_checkbox'+ (this.state.terms_agreed ? ' terms_agreed' : ''))} onClick={this.agreeTerms}></div>
									<span>Slažem se i prihvatam sve uslove iz <a href="/uslovikoristenja" target="_blank">uslova korištenja.</a></span>
									
									{ this.state.show_error == true &&
										<div className="agreed_error">{this.state.terms_agreed_error}</div> }
								</div>
								
								{ this.state.pending_confirmation && this.state.terms_agreed &&
									<div className="register_confirm_email">
										Prije nego što nastavite dalje, morate unijeti KOD koji Vam je poslat na email:<br />
										<InputAnimated type="phone" id="register_confirmcode" title="Kod" placeholder="" fieldValue={this.state.confirm_code} fieldName="confirm_code" fieldError={this.state.confirm_message} updateChild={(val, call) => { this.updateChild(val, this.verifyConfirmCode); }} />
										<div className="register_button" onClick={this.resendCode}>Niste dobili kod?</div>
									</div> }
								
								
								<div className="register_button" onClick={this.doImageUpload}>Registracija</div>
								
							</div>
						</div>
						<div className="register_right">
							<div className="register_form">
								<div className="register_form_toptitle">Imate Facebook nalog?<br /><br />Jednostavno sa jednim klikom možete pristupiti kao registrovan korisnik:</div>
								<div className="register_button_facebook" onClick={this.loginFacebookSimulate}>Facebook Korisnik</div>
							</div>
						</div>
						<div className={'loader_holder'+(this.state.loading_register ? ' active' : '')}>
							<div className='loader_big'></div>
						</div>
					</form>
				</div>

		);
	}
}

export default Register;