import { Component } from 'react';
import './Style.css';


export class FromToAnimated extends Component {
	
	constructor()
		{
			super();
			this.state = {hasFocus: false, hasFocus1: false, val1: '', val2: '', changedVal: false};
		}
	
	
	componentDidMount()
	{
		this.setState({val1: this.props.fieldValue, val2: this.props.fieldValue});
	}
	
	doFormFocus = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: true});
			}
	}
	
	doFormBlur = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: false});
			}
	}
	doFormFocus1 = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus1: true});
			}
	}
	
	doFormBlur1 = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus1: false});
			}
	}
	
	doAllUpdate = () =>
	{
		this.props.updateChild({[this.props.fieldName]: [this.state.val1, this.state.val2]}, true, false);
	}
	
	render()
	{
		return (
				<div className={('input_element_animated form_field'+(this.props.fieldValue && this.props.fieldValue.toString().length > 0 ? ' hasActiveText' : ''))}>
					<div className="from_to_field from_to_left">
						<span> Od: </span>
						<input className={(this.state.hasFocus ? ' active' : '')} type={this.props.type} placeholder="" name={this.props.id} id={this.props.id} value={this.state.val1} onChange={(event) => this.setState({val1: event.target.value.replace(/[^0-9]/g, '')})} onFocus={this.doFormFocus} onBlur={this.doFormBlur} />
					</div>
					<div className="from_to_field">
						<span> Do: </span>
						<input className={(this.state.hasFocus1 ? ' active' : '')}  type={this.props.type} placeholder="" name={this.props.id} id={this.props.id} value={this.state.val2} onChange={(event) => this.setState({val2: event.target.value.replace(/[^0-9]/g, '')})} onFocus={this.doFormFocus1} onBlur={this.doFormBlur1} />
					</div>
					<div className={"from_to_submit"+( ( this.props.fieldValue || this.props.fieldValue1 || this.state.val1 || this.state.val2 ) && ( this.props.fieldValue != this.state.val1 || this.props.fieldValue1 != this.state.val2 ) ? ' hasActiveText' : '')} onClick={this.doAllUpdate}>Traži</div>
					{ this.props.fieldError && <div className="form_field_error">{this.props.fieldError}</div> }
				</div>
		);
	}
}