import { Component } from 'react';
import './AddRating.css';
import axios from 'axios';

import { TextareatAnimated } from '../../components/elements/TextareatAnimated';
import { Link } from 'react-router-dom';
import Util from '../../util/util';


export class AddRating extends Component {

	constructor()
		{
			super();
			this.state = {
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
							ratingType: 0,
							userRating: 0,
							userRatingError: '',
							descRating: 0,
							descRatingError: '',
							timeRating: 0,
							timeRatingError: '',
							deliveryPriceRating: 0,
							deliveryPriceRatingError: '',
							messResponseRating: 0,
							messResponseRatingError: '',
							dealRating: 0,
							dealRatingError: '',
							staticMessage: '',
							messageError: '',
							oneTab: 0,
							showRatingPopup : false,
							noUserPopup: false
				};
		}
	
	checkSeller = () =>
		{
			this.setState({
							userRatingError: '',
							messageError: ''
			});
			
			if ( this.props.currentTab == 'seller' )
				{
					this.setState({ratingType: 1});
				}
			else if ( this.props.currentTab == 'buyer' )
				{
					this.setState({ratingType: 2});
				}
			
			
			var allTrue = true;
			
			if ( this.state.userRating == 0 )
				{
					this.setState({userRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			var staticMessage = this.state.staticMessage.trim();
			
			if ( !Util.checkParams(staticMessage, 'txtAreaRegEx'))
				{
					this.setState({messageError: 'Poruka nije ispravno upisana'});
					allTrue = false;
				}
			
			if ( allTrue == true )
				{
					this.setState({
								oneTab: 1,
					});
				}
				
		}
	
	doSendRatings  = () =>
		{
			this.setState({
							descRatingError: '',
							timeRatingError: '',
							deliveryPriceRatingError: '',
							messResponseRatingError: '',
							dealRatingError: ''
			});
			
			var mainThis = this;
			var allTrue = true;
			
			
			if ( this.state.descRating == 0 )
				{
					this.setState({descRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			if ( this.state.timeRating == 0 )
				{
					this.setState({timeRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			if ( this.state.deliveryPriceRating == 0 )
				{
					this.setState({deliveryPriceRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			if ( this.state.messResponseRating == 0 )
				{
					this.setState({messResponseRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			if ( this.state.dealRating == 0 )
				{
					this.setState({dealRatingError: 'Ovo polje ne možete ostaviti prazno'});
					allTrue = false;
				}
			
			if ( this.state.ratingType !== 0 && allTrue == true )
				{
					var staticMessage = this.state.staticMessage.trim();
					axios.post(window.ajaxLink+'user/updateRatings', {
						
						rating_type: this.state.ratingType,
						from_user_id: this.state.usersData.id,
						to_user_id: this.props.userId,
						rating_overall: this.state.userRating,
						rating_desc: this.state.descRating,
						rating_shipfast: this.state.timeRating,
						rating_shipprice: this.state.deliveryPriceRating,
						rating_msgfast: this.state.messResponseRating,
						rating_agree: this.state.dealRating,
						rating_text: staticMessage,
						rating_order_id: this.props.orderId
					})
								.then(res => {
									mainThis.setState({buySuccess: true, showRatingPopup: false, oneTab: 0});
									
									window.location.reload();
									
								}).catch(err => {
									
									console.log(err);
									mainThis.setState({buySuccess: false});
								}
							);
				}					
		}
	
	updateChild  = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
			
		}
	
	closePopup = () =>
		{
			this.setState({showRatingPopup: false, noUserPopup: false, oneTab: 0});
			if ( this.props.updateParent ) { this.props.updateParent({openAlready: false}); }
		}
	
	render()
	{
		return (
			<div>
			{ this.state.usersData.id != this.props.userId && !this.props.openAlready &&
				<div className="rating_popup_button" onClick={()=> { this.state.usersData.id !== undefined ? this.setState({showRatingPopup: true}) : this.setState({noUserPopup: true}) }}>Dodajte ocjenu</div>
			}
					{ ( this.state.showRatingPopup == true || this.props.openAlready ) &&
						<div className="middle_popup">
							<div className="middle_popup_inside">
								<div className="middle_popup_close" onClick={this.closePopup}>&times;</div>
								<div className="middle_popup_title">Recenzija</div>
								{ this.state.oneTab == 0 &&
									<div className="rating_popup_wrapper">
										<div className="rating_popup_question">DODAJTE OCJENU I VAŠE MIŠLJENJE</div>
										<div className="rating_popup_star_container">
											<div className={'rating_popup_star'+(this.state.userRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({userRating: 1}); }}></div>
											<div className={'rating_popup_star'+(this.state.userRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({userRating: 2}); }}></div>
											<div className={'rating_popup_star'+(this.state.userRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({userRating: 3}); }}></div>
											<div className={'rating_popup_star'+(this.state.userRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({userRating: 4}); }}></div>
											<div className={'rating_popup_star'+(this.state.userRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({userRating: 5}); }}></div>
										</div>
										<div className={'' + (this.state.userRatingError !== '' ? 'form_field_error' : '')}>{this.state.userRatingError}</div>
										
										<TextareatAnimated type="text" id="static_form_message" title="Poruka" fieldValue={this.state.staticMessage} fieldName="staticMessage" fieldError={this.state.messageError} updateChild={this.updateChild} />
										<div className="rating_popup_button" onClick={ this.checkSeller }>Dalje</div>
									</div>
								}
								{ this.state.oneTab == 1 &&
									<div className="rating_popup_wrapper">
										<div className="rating_popup_question">{ this.props.currentTab == 'seller' ? 'JOŠ NEŠTO ŠTO BI NAŠI KUPCI TREBALI DA ZNAJU?' : 'JOŠ NEŠTO ŠTO BI NAŠI PRODAVCI TREBALI DA ZNAJU?' }</div>
										<div className="rating_popup_detailed_question_container">
											<div className="rating_popup_detailed_question">{this.props.currentTab == 'seller' ? 'Da li je vaš proizvod isti kao u opisu?': 'Da li je vaš kupac uredno platio dostavljeni proizvod?'}</div>
											<div className="rating_popup_star_container">
												<div className={'rating_popup_star'+(this.state.descRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({descRating: 1}); }}></div>
												<div className={'rating_popup_star'+(this.state.descRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({descRating: 2}); }}></div>
												<div className={'rating_popup_star'+(this.state.descRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({descRating: 3}); }}></div>
												<div className={'rating_popup_star'+(this.state.descRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({descRating: 4}); }}></div>
												<div className={'rating_popup_star'+(this.state.descRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({descRating: 5}); }}></div>
											</div>
											<div className={'' + (this.state.descRatingError !== '' ? 'form_field_error' : '')}>{this.state.descRatingError}</div>
										</div>
										<div className="rating_popup_detailed_question_container">
											<div className="rating_popup_detailed_question">{ this.props.currentTab == 'seller' ? 'Da li je vaš proizvod dostavljen na vrijeme?' : 'Da li vam je kupac poslao ispravnu adresu?' }</div>
											<div className="rating_popup_star_container">
												<div className={'rating_popup_star'+(this.state.timeRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({timeRating: 1}); }}></div>
												<div className={'rating_popup_star'+(this.state.timeRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({timeRating: 2}); }}></div>
												<div className={'rating_popup_star'+(this.state.timeRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({timeRating: 3}); }}></div>
												<div className={'rating_popup_star'+(this.state.timeRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({timeRating: 4}); }}></div>
												<div className={'rating_popup_star'+(this.state.timeRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({timeRating: 5}); }}></div>
											</div>
											<div className={'' + (this.state.timeRatingError !== '' ? 'form_field_error' : '')}>{this.state.timeRatingError}</div>
										</div>
										<div className="rating_popup_detailed_question_container">
											<div className="rating_popup_detailed_question">{ this.props.currentTab == 'seller' ? 'Da li ste zadovoljni cijenom dostave vašeg proizvoda?' : 'Da li vam je kupac poslao ispravan broj telefona?'} </div>
											<div className="rating_popup_star_container">
												<div className={'rating_popup_star'+(this.state.deliveryPriceRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({deliveryPriceRating: 1}); }}></div>
												<div className={'rating_popup_star'+(this.state.deliveryPriceRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({deliveryPriceRating: 2}); }}></div>
												<div className={'rating_popup_star'+(this.state.deliveryPriceRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({deliveryPriceRating: 3}); }}></div>
												<div className={'rating_popup_star'+(this.state.deliveryPriceRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({deliveryPriceRating: 4}); }}></div>
												<div className={'rating_popup_star'+(this.state.deliveryPriceRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({deliveryPriceRating: 5}); }}></div>
											</div>
											<div className={'' + (this.state.deliveryPriceRatingError !== '' ? 'form_field_error' : '')}>{this.state.deliveryPriceRatingError}</div>
										</div>
										<div className="rating_popup_detailed_question_container">
											<div className="rating_popup_detailed_question">{ this.props.currentTab == 'seller' ? 'Da li prodavač odgovara vašim porukama na vrijeme?' : 'Da li ste se brzo dogovorili sa kupcem?'}</div>
											<div className="rating_popup_star_container">
												<div className={'rating_popup_star'+(this.state.messResponseRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({messResponseRating: 1}); }}></div>
												<div className={'rating_popup_star'+(this.state.messResponseRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({messResponseRating: 2}); }}></div>
												<div className={'rating_popup_star'+(this.state.messResponseRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({messResponseRating: 3}); }}></div>
												<div className={'rating_popup_star'+(this.state.messResponseRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({messResponseRating: 4}); }}></div>
												<div className={'rating_popup_star'+(this.state.messResponseRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({messResponseRating: 5}); }}></div>
											</div>
											<div className={'' + (this.state.messResponseRatingError !== '' ? 'form_field_error' : '')}>{this.state.messResponseRatingError}</div>
										</div>
										<div className="rating_popup_detailed_question_container">
											<div className="rating_popup_detailed_question">{ this.props.currentTab == 'seller' ? 'Da li ste se brzo dogovorili sa prodavcem?' : 'Da li kupac odgovara vašim porukama na vrijeme?'}</div>
											<div className="rating_popup_star_container">
												<div className={'rating_popup_star'+(this.state.dealRating > 0 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({dealRating: 1}); }}></div>
												<div className={'rating_popup_star'+(this.state.dealRating > 1 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({dealRating: 2}); }}></div>
												<div className={'rating_popup_star'+(this.state.dealRating > 2 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({dealRating: 3}); }}></div>
												<div className={'rating_popup_star'+(this.state.dealRating > 3 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({dealRating: 4}); }}></div>
												<div className={'rating_popup_star'+(this.state.dealRating > 4 ?' rating_popup_star_yellow':'')} onClick={()=> { this.setState({dealRating: 5}); }}></div>
											</div>
											<div className={'' + (this.state.dealRatingError !== '' ? 'form_field_error' : '')}>{this.state.dealRatingError}</div>
										</div>
										<div className="rating_popup_button" onClick={ this.doSendRatings }>Postavite</div>
									</div>
								}
							</div>
						</div>
					}
					{ this.state.noUserPopup &&
					<div className="middle_popup">
							<div className="middle_popup_inside">
								<div className="middle_popup_close" onClick={this.closePopup}>&times;</div>
								<div className="middle_popup_title">Ocjena korisnika {this.props.profileName}</div>
										<div className="middle_popup_body">
											<div className="middle_popup_body">
												<div className="middle_popup_body_padding">
													<div className="profile_popup_warning">Ocjene mogu da ostavljaju samo registrovani korisnici</div>
													<Link to="/registracija"><div className="profile_popup_confirm" style={{width: 115 +'px', marginTop: 20 + 'px'}} onClick={this.closePopup}>Registruj se</div></Link>
												</div>
											</div>
										</div>
							</div>
						</div>
					}
			</div>
		);
	}
}