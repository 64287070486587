import React, { Component } from 'react';
import './Categories.css';
import axios from "axios";
import { Link } from 'react-router-dom';
import Util from '../../util/util';

class Categories extends Component {

	constructor(ttt)
		{
			super();
			this.state = {allCategories: []};
		}

	componentDidMount()
		{
			document.title = 'Sve kategorije'+this.props.pageTitle;
			
			var mainThis = this;
			axios.post(window.ajaxLink+'products/categories', {})
				.then(res => {
					
					if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['categories'] )
						{
							mainThis.setState({allCategories: res['data']['responseData']['categories']});
						}
					
					
					
				}).catch(err => {});
		}
	
	render(){
		return(
			<div className="categories_page">
				<div className="categories_title">Sve kategorije</div>
				{ this.state.allCategories.map((oneItem, j) =>
					<div className="category_oneitem" key={j}>
						<div className="category_topname"><Link to={oneItem.link}>{Util.firstLettersUppercase(oneItem.name)}</Link></div>
						<div className="category_subname">
							{ oneItem.sub_categories.map((oneSubItem, jj) => <div key={jj}><Link to={oneSubItem.link}>{Util.firstLettersUppercase(oneSubItem.name)}</Link></div> )}
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default Categories;
