import React, { Component } from 'react';
import './About.css';

class AboutUs extends Component {
	render(){
		return (
			<div className="about_container">
				<div className="about_section_header">O našoj stranici</div>
				<div className="about_section_header">Uskoro...</div>
			</div>
		)
	}
}

export default AboutUs;