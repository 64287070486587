import moment from 'moment';

class Util
{
	constructor ()
		{
			this.eList = [];
			this.callback = false;
			
			this.alreadyRegistered = false;
			
			this.eventListening = {};
			this.eventListeningCallbacks = {};
			
			this.monthNames = ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'];
			this.weekDays = ['', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota', 'Nedjelja'];
		}
	
	getPath(position)
		{
			var nowPath = window.location.pathname.split('/');
			if ( nowPath[position] ) { return nowPath[position]; }
			return false;
		}
	
	hideOnOutsideClick(eList, callback)
		{
			this.eList = eList;
			this.callback = callback;
			
			if ( this.alreadyRegistered == false )
				{
					window.addEventListener('click', (e)=>{ this.checkIsOutside(e); });
					this.alreadyRegistered = true;
				}
		}
	
	checkIsOutside(e)
		{
			var allOutside = true;
			for ( var eone of this.eList )
				{
					if ( document.getElementById(eone) && document.getElementById(eone).contains(e.target) )
						{
							allOutside = false;
						}
				}
			
			if ( allOutside )
				{
					if ( this.callback ) { this.callback(); }
					this.callback = false;
					this.eList = [];
				}
		}
		
	getOrderId(window)
		{
			var productId = window.location.href.split('-');
			productId = productId[productId.length-1];
			return productId;
		}
		
	checkParams(value, vtype)
		{
			var isValid = false;
			
			if ( vtype == 'emailphonename' )
				{
					if ( /^[a-zA-ZšŠđĐžŽčČćĆ]{3,}$/i.test(value) )
						{
							// Username
							isValid = true;
						}
					
					if ( /^[0-9]{9,10}$/.test(value) )
						{
							// Phone number
							isValid = true;
						}
					
					if ( /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value) )
						{
							// email
							isValid = true;
						}
					
					if ( /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value) )
						{
							// email
							isValid = true;
						}
				}
			
			if ( vtype == 'emailphone')
				{
					if ( /^[0-9]{9,10}$/.test(value) )
							{
								// Phone number
								isValid = true;
							}
							
					if ( /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value) )
						{
							// email
							isValid = true;
						}
				}
			
			if ( vtype == 'password' && /^.{5,}$/s.test(value) )
				{
					isValid = true;
				}	
			
			if ( vtype == 'username' && /^[A-Za-zšŠđĐžŽčČćĆ0-9]{5,}$/.test(value) )
				{
					isValid = true;
				}
			//used for string check	
			if ( vtype == 'stringReg'  && /^[a-zšđžčć ,.'-]{5,}$/i.test(value))
				{
					// username, firstname, last name, businessname
					isValid = true;
				}
			
			if ( vtype == 'name'  && /^[a-zA-ZšŠđĐžŽčČćĆ]{3,}$/i.test(value))
				{
					// username, firstname, last name
					isValid = true;
				}
			
			if ( vtype == 'address' && /^[a-zA-Z0-9šŠđĐžŽčČćĆ\s,.'-]{3,}$/.test(value) )
				{
					//address, businessAddress
					isValid = true;
				}
			
			if ( vtype == 'city' && /^[a-zA-Z0-9šŠđĐžŽčČćĆ\s,.'-]{4,}$/.test(value) )
				{
					//city
					isValid = true;
				}
			
			if ( vtype == 'postal' && /^\d{5}$/.test(value))
				{
					//Postal code
					isValid = true;
				}
				
			if ( vtype == 'website' && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(value))
				{
					//Postal code
					isValid = true;
				}

		
			if ( vtype == 'email' && /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value))
				{
					// email
					isValid = true;
				}
				
			if ( vtype == 'productName' && /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9šŠđĐžŽčČćĆ])[a-zA-Z0-9 '-/#"]{2,}$/.test(value))
				{
					//product name check
					isValid = true
				}
			
			if ( vtype == 'quantity' && /^[0-9]{1,9}/.test(value))
				{
					//quantity check
					isValid = true;
				}
				
			if ( vtype == 'txtAreaRegEx' && /^(?!\s)(?!.*\s$)[a-zA-Z0-9šŠđĐžŽčČćĆ?$@#()'"!,+\-=_:.&€£*%\s ]{2,}$/.test(value))
				{
					isValid = true;
				}
				
			if ( vtype == 'phone' && /^[0-9]{9,10}$/.test(value))
				{
					// Phone number
					isValid = true;
				}
				
			if ( vtype == 'JIB' && /^[0-9]{13,13}$/.test(value))
				{
					// Phone number
					isValid = true;
				}
				
			if ( vtype == 'lengthRegEx' && /^.{4,35}$/.test(value))
				{
					isValid = true;
				}
				
			if ( vtype == 'urlother' )
				{
					if ( value.substr(0, 27) == 'https://www.olx.ba/artikal/' )
						{
							isValid = true;
						}
				}
			
			if ( !value || value == undefined ) { isValid = false; }
			
			return isValid;
		}
	
	timeAgoGet(ctime)
	{
		if ( typeof ctime == 'string' ) { ctime = new Date(ctime); }
		
		var duration = moment.duration(moment().diff(moment(ctime)));
		var createdAt = '';
		
		if ( duration.as('day') == 0 ) { createdAt = 'juče'; }
		else if ( duration.as('day') > 1 && duration.as('day') < 20 ) { createdAt = `prije ${Math.floor(duration.as('day'))} dana` }
		else if ( duration.as('day') > 20 ) { createdAt = this.dateFormat(ctime); }
		else if ( duration.as('hour') >= 1 )
			{
				if ( Math.floor(duration.as('hour')) == 1 ) { createdAt = 'prošli sat'; }
				if ( Math.floor(duration.as('hour')) == 2 ) { createdAt = 'prije 2 sata'; }
				if ( Math.floor(duration.as('hour')) == 3 ) { createdAt = 'prije 3 sata'; }
				if ( Math.floor(duration.as('hour')) == 4 ) { createdAt = 'prije 4 sata'; }
				if ( Math.floor(duration.as('hour')) >= 5 ) { createdAt = 'prije '+Math.floor(duration.as('hour'))+' sati'; }
				if ( Math.floor(duration.as('hour')) == 21 ) { createdAt = 'prije '+Math.floor(duration.as('hour'))+' sat'; }
				if ( Math.floor(duration.as('hour')) >= 22 ) { createdAt = 'prije '+Math.floor(duration.as('hour'))+' sata'; }
			}
		else if ( duration.as('minute') >= 1 )
			{
				createdAt = 'prije '+Math.floor(duration.as('minute'))+' min';
			}
		else if ( duration.as('minute') < 1 )
			{
				createdAt = 'prije 1 min';
			}
		
		return createdAt;
	}
	
	timeUntilGet(ctime)
	{
		if ( typeof ctime == 'string' ) { ctime = new Date(ctime); }
		
		var duration = moment.duration(moment(ctime).diff(moment()));
		var expiresIn = '';
		
		if ( duration.as('day') == 0 ) { expiresIn = 'sutra'; }
		else if ( duration.as('day') > 1 && duration.as('day') < 20 ) { expiresIn = `za ${Math.floor(duration.as('day'))} dana` }
		else if ( duration.as('day') > 20 ) { expiresIn = this.dateFormat(ctime); }
		else if ( duration.as('hour') >= 1 )
			{
				if ( Math.floor(duration.as('hour')) == 1 ) { expiresIn = 'za sat'; }
				if ( Math.floor(duration.as('hour')) == 2 ) { expiresIn = 'za 2 sata'; }
				if ( Math.floor(duration.as('hour')) == 3 ) { expiresIn = 'za 3 sata'; }
				if ( Math.floor(duration.as('hour')) == 4 ) { expiresIn = 'za 4 sata'; }
				if ( Math.floor(duration.as('hour')) >= 5 ) { expiresIn = 'za '+Math.floor(duration.as('hour'))+' sati'; }
				if ( Math.floor(duration.as('hour')) == 21 ) { expiresIn = 'za '+Math.floor(duration.as('hour'))+' sat'; }
				if ( Math.floor(duration.as('hour')) >= 22 ) { expiresIn = 'za '+Math.floor(duration.as('hour'))+' sata'; }
			}
		else if ( duration.as('minute') >= 1 )
			{
				expiresIn = 'za '+Math.floor(duration.as('minute'))+' min';
			}
		else if ( duration.as('minute') < 1 )
			{
				expiresIn = 'expired';
			}
		
		return expiresIn;
	}
	
	getTotal(quantity, price)
		{
			var total = 0;
			total = quantity * price;
			return total;
		}
	
	minutesDiff(ctime, ctime2)
	{
		if ( typeof ctime == 'string' ) { ctime = new Date(ctime); }
		if ( typeof ctime2 == 'string' ) { ctime2 = new Date(ctime2); }
		
		var duration = moment.duration(moment(ctime2).diff(moment(ctime)));
		
		if ( Math.abs(duration.as('minute')) >= 1 )
			{
				return Math.abs(duration.as('minute'));
			}
		else
			{
				return 0;
			}
	}
	
	dateFormat(dtime)
		{
			if ( !dtime ) { return ''; }
			
			var date = new Date(dtime);
			var year = date.getFullYear();
			var month = this.monthNames[date.getMonth()];
			var day = date.getDate();
			
			return day + '. '+ month + ' ' + year;
		}
	
	dateTimeFormat(dtime)
		{
			if ( !dtime ) { return ''; }
			
			var date = new Date(dtime);
			var month = this.monthNames[date.getMonth()];
			var day = date.getDate();
			
			var hours = date.getHours() > 9 ? date.getHours() : '0'+date.getHours();
			var minutes = date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes();
			
			return day + '. '+ month + ' u ' +hours+':'+minutes;
		}
	
	timeFormat(dtime)
		{
			if ( !dtime ) { return ''; }
			
			var date = new Date('1/1/2021 '+dtime);
			var hours = date.getHours() > 9 ? date.getHours() : '0'+date.getHours();
			var minutes = date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes();
			
			return hours+':'+minutes;
		}
	
	weekDayFormat(week_day)
		{
			if ( !week_day ) { return ''; }
			
			return this.weekDays[week_day];
		}
	
	openClosedCheck(all_hours)
		{
			if ( !all_hours ) { return false; }
			
			var startTime = new Date('1/1/2021 00:00:00');
			var endTime = new Date('1/1/2021 23:59:59');
			for ( var d of all_hours )
				{
					if ( d['week_day'] == (new Date()).getDay() || ( d['week_day'] == 7 && (new Date()).getDay() == 0 )  )
						{
							if ( d['start_time'] == '25:00:00' ) { return true; }
							
							startTime = new Date('1/1/2021 '+ d['start_time']);
							endTime = new Date('1/1/2021 '+d['end_time']);
							if ( d['end_time'] == '24:00:00' ) { endTime.setDate(endTime.getDate()+1); }
						}
				}
			
			
			var nowTime = new Date('1/1/2021 '+(new Date().getHours())+':'+(new Date().getMinutes())+':00');
			var openClosed = false;
			if ( nowTime.getTime() > startTime.getTime() && nowTime.getTime() < endTime.getTime() )
				{
					openClosed = true;
				}
			return openClosed;
		}
	
	
	
	
	
	handleAjaxError(error, mainThis, showPopup = true)
		{
			if ( error && error.response && error.response.data && error.response.data.errorData )
				{
					if ( error.response.data.errorData.model && error.response.data.errorData.error )
						{
							mainThis.setState({[error.response.data.errorData.model]: error.response.data.errorData.error});
						}
					else if ( showPopup && error.response.data.errorData.error )
						{
							console.log('show popup----', error.response.data.errorData.error);
						}
				}
		}
	
	listenSSEvent(unique_name, url)
		{
			return false;
			/*
			if ( typeof EventSource == 'undefined' ) { return false; }
			
			var mainThis = this;
			if ( this.eventListening[url] ) { this.eventListening[url].close(); }
			
			this.eventListening[url] = new EventSource(url);
			this.eventListening[url]['unique_name'] = unique_name;
			this.eventListening[url]['unique_url'] = url;
			this.eventListening[url].onmessage = (event) => {
				if ( event.data !== 'first load' && event.data !== 'keep-alive' )
					{
						if ( event.target.unique_name && mainThis.eventListeningCallbacks && mainThis.eventListeningCallbacks[event.target.unique_name] )
									{
										var callList = mainThis.eventListeningCallbacks[event.target.unique_name];
										var cb;
										try
											{
												if ( event.data && event.data.indexOf(':') > 0 )
													{
														for ( cb in callList )
															{
																callList[cb](JSON.parse(event.data));
															}
														return false;
													}
											}
										catch(err) { console.log('err-', err); }
										
										try
											{
												for ( cb in callList )
													{
														callList[cb](event.data);
													}
											}
										catch(err) { console.log('err-', err); }
									}
					}
			};
			
			this.eventListening[url].onerror = function(event)
				{
					if ( event.target.readyState == 2 )
						{
							setTimeout(function () { mainThis.listenSSEvent(event.target.unique_name, event.target.unique_url) }, 2000);
						}
				};
			
			return this.eventListening[url];*/
		}
	
	addSSEventCallback(unique_name, unique_id, callback)
		{
			if ( !this.eventListeningCallbacks[unique_name] ) { this.eventListeningCallbacks[unique_name] = {}; }
			this.eventListeningCallbacks[unique_name][unique_id] = callback;
		}
	
	
	
	
	storagePreload(list_items, item_type, default_return, callback)
		{
			var rez = {};
			for ( var itm of list_items )
				{
					var savedList = localStorage.getItem(itm);
					if ( savedList && item_type == 'json' ) { savedList = JSON.parse(savedList); }
					if ( savedList ) { rez[itm] = savedList; } else { rez[itm] = default_return; }
				}
			
			if ( callback ) { callback(rez); }
			return rez;
		}
	
	storageClear(list_items)
		{
			for ( var itm of list_items )
				{
					localStorage.removeItem(itm);
				}
		}
	
	showPopupDialog(title, text, button1, button2, closeX, custom_main_css)
		{
			var top_div = document.createElement("div");
			top_div.className = 'middle_popup';
			
			var inside_div = document.createElement("div");
			inside_div.className = 'middle_popup_inside middle_popup_inside_small '+(custom_main_css ? custom_main_css : '');
			
			var title_div = document.createElement("div");
			title_div.className = 'middle_popup_title';
			title_div.innerHTML = title;
			
			var div_body_inside = document.createElement("div");
			div_body_inside.className = 'middle_popup_body_padding';
			div_body_inside.innerHTML = text;
			
			if ( closeX && typeof closeX == 'function' )
				{
					var div_body_close = document.createElement("div");
					div_body_close.className = 'middle_popup_close';
					div_body_close.innerHTML = '&times';
					div_body_close.onclick = () => { top_div.remove(); window.removeBlur(''); closeX(); }
					
					inside_div.appendChild(div_body_close);
				}
			
			if ( button1 )
				{
					var div_body_button1 = document.createElement("div");
					div_body_button1.className = button1['css'];
					div_body_button1.innerHTML = button1['title'];
					if ( typeof button1['callback'] == 'function' )
						{
							div_body_button1.onclick = () => { top_div.remove(); window.removeBlur(''); button1['callback'](); }
						}
				}
			
			if ( button2 )
				{
					var div_body_button2 = document.createElement("div");
					div_body_button2.className = button2['css'];
					div_body_button2.innerHTML = button2['title'];
					if ( typeof button2['callback'] == 'function' )
						{
							div_body_button2.onclick = () => { top_div.remove(); window.removeBlur(''); button2['callback'](); }
						}
					
				}
			
			if ( button1 || button2 )
				{
					var div_body_inside_buttons = document.createElement("div");
					div_body_inside_buttons.className = 'middle_popup_buttons';
					
					if ( button1 ) { div_body_inside_buttons.appendChild(div_body_button1); }
					if ( button2 ) { div_body_inside_buttons.appendChild(div_body_button2); }
					
					div_body_inside.appendChild(div_body_inside_buttons);
				}
			
			inside_div.appendChild(title_div);
			inside_div.appendChild(div_body_inside);
			
			top_div.appendChild(inside_div);
			
			window.addBlur('.App');
			document.body.appendChild(top_div);
		}
	
	firstLettersUppercase(word)
		{
			if ( !word ) { return word; }
			
			return word.split(' ').map((word) => { if ( word.length > 1 ) { return word[0].toUpperCase() + word.substring(1); } else { return word; } }).join(" ");
		}
		
	formatPrice(price)
		{
			if ( !price ) { return ''; }
			var formatedPrice = '';
			if ( price == -1 ) { formatedPrice = 'Po dogovoru' }
			else
				{
					//formatedPrice = Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+ ' KM'
					formatedPrice = parseFloat(price).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")+ ' KM'
					
					if ( formatedPrice.substr(-6) == ',00 KM' ) { formatedPrice = Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+ ' KM'; }
				}
			return formatedPrice;
		}
}

export default new Util();