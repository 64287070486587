import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import './Product.css';

import Util from '../../util/util';

import { InputAnimated } from '../../components/elements/InputAnimated';
import { TextareatAnimated } from '../../components/elements/TextareatAnimated';
import ProductImages from '../../components/images/productImages';
import { Chat } from '../../components/chat/Chat';
import { SellerRating } from '../../components/sellerrating/SellerRating';


class Product extends Component {

	constructor(ttt)
		{
			super();
			this.state = {
							productInfo: {
											'id': '',
											'name': '',
											'username': '',
											'meta_array': [],
											'categories_array':[],
											'images_array':[],
											'user_id': ''

										},

							'productPath':'',
							'loadingProduct': true,
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
							order_quantity: 1,
							order_quantity_error: '',
							order_first_name: '',
							order_first_name_error: '',
							order_last_name: '',
							order_last_name_error: '',
							order_address: '',
							order_address_error: '',
							order_city: '',
							order_city_error: '',
							order_postal: '',
							order_postal_error: '',
							order_phone: '',
							order_phone_error: '',
							showSendMessage: false,
							order_more_info: '',
							more_infoError: '',
							seller_info: '',
							showSentMessage: false,
							showBuyNow: false,
							showLocation: false,
							blockInfo: false,

							buySuccess: false



						};
		}

	componentDidMount()
		{
			var nowPath = Util.getPath(2);	
		
			var mainThis = this;
			document.title = 'Proizvod '+ nowPath + ' '+this.props.pageTitle;
			
			if ( nowPath )
				{
					var savedInfo = localStorage.getItem('product_'+nowPath);
					if ( savedInfo ) { savedInfo = JSON.parse(savedInfo); }
					if ( savedInfo ) { this.setState({productInfo: savedInfo, loadingProduct: false}); }

					axios.post(window.ajaxLink+'products/item', {productLink: decodeURI(nowPath)})
							.then(res => {
								document.title = res['data']['responseData']['product']['name']+mainThis.props.pageTitle;

								mainThis.setState({productInfo: res['data']['responseData']['product'], seller_info: res['data']['responseData']['seller_info'], loadingProduct: false}, () =>
									{
										if ( window.FB && window.FB.XFBML && window.FB.XFBML.parse ) { window.FB.XFBML.parse(); }
									});

								localStorage.setItem('product_'+nowPath, JSON.stringify(res['data']['responseData']['product']));
							}).catch(err => {
								mainThis.setState({blockInfo: true, productInfo: {'name': 'Nepoznat proizvod', 'username': '', 'meta_array': [], 'categories_array':[], 'images_array':[], 'user_id': ''}, loadingProduct: false});
								console.log(err);
							}
						);

				}
			this.setState({
				order_first_name: this.state.usersData.first_name,
				order_last_name: this.state.usersData.last_name,
				order_address: this.state.usersData.address,
				order_city: this.state.usersData.city,
				order_postal: this.state.usersData.postal_code,
				order_phone: this.state.usersData.phone
			})

		}
	
	handleChange(event)
		{
			this.setState({order_quantity: event.target.value});
		}

	doOrder = () =>
		{
			this.setState({
								order_quantity_error: '',
								order_first_name_error: '',
								order_last_name_error: '',
								order_address_error: '',
								order_city_error: '',
								order_postal_error: '',
								order_phone_error: '',
								more_infoError: '',
			});
			
			
			var allValid = true;
			
			//quantity value check
			if ( parseInt(this.state.order_quantity) > parseInt(this.state.productInfo.quantity) )
				{
					this.setState({order_quantity_error: 'Trenutno nemamo toliko proizvoda na stanju'});
					allValid = false;
				}
			
			if ( this.state.productInfo.delivery == 'shipping' )
				{
					//first name validation
					if ( !Util.checkParams(this.state.order_first_name, 'name'))
						{
							this.setState({order_first_name_error: 'Ime nije pravilno upisano'});
							allValid = false;
						}
					
					//last name validation
					if ( !Util.checkParams(this.state.order_last_name, 'name'))
						{
							this.setState({order_last_name_error: 'Prezime nije pravilno upisano'});
							allValid = false;
						}
				
					// address validation
					if	( !Util.checkParams(this.state.order_address, 'address'))
						{
							this.setState({order_address_error:'Adresa mora sadržati više od 2 karaktera'});
							allValid = false;
						}
					
					// city validation
					if ( !Util.checkParams(this.state.order_city, 'city'))
						{
							this.setState({order_city_error: 'Ime grada nije pravilno upisano'});
							allValid = false;
						}
					
					// postal code validation
					if ( !Util.checkParams(this.state.order_postal, 'postal'))
						{
							this.setState({order_postal_error: 'Poštanski broj mora sadržati 5 cifara'});
							allValid = false;
						}
					
					//phone number validation
					if( !Util.checkParams(this.state.order_phone, 'phone'))
						{
							this.setState({order_phone_error:'Broj telefona nije pravilno upisan'});
							allValid = false;
						}
				}
			
			
			if ( allValid == true )
				{
					var mainThis = this;
					var newQuantity = parseInt(this.state.productInfo.quantity) - parseInt(this.state.order_quantity);
					newQuantity = JSON.stringify(newQuantity);
					
					axios.post(window.ajaxLink+'products/updatequantity', {
						
						updateQuantity: 1,
						new_quantity: newQuantity,
						product_id: mainThis.state.productInfo.id,
						seller_id: mainThis.state.productInfo.user_id
						
					}).then(res => {
						
							}).catch(err => {
								console.log(err);
							}
						);
					
					var id = Util.getOrderId(window);
					axios.post(window.ajaxLink+'products/order', {
					seller_id: this.state.productInfo.user_id,
					product_id: id,
					quantity: this.state.order_quantity,
					first_name: this.state.order_first_name,
					last_name: this.state.order_last_name,
					city: this.state.order_city,
					postal: this.state.order_postal,
					address: this.state.order_address,
					phone: this.state.order_phone,
					more_info: this.state.order_more_info
					
					})
								.then(res => {
									if ( res['data']['responseMessage']=='success' )
									{
										mainThis.setState({buySuccess: true});
										
									}
									
								}).catch(err => {
									
									console.log(err);
									mainThis.setState({buySuccess: false});
								}
							);	
				}
		}
	
	doSimulateLogin = () =>
		{
			if ( document.getElementById('header_login') ) { setTimeout(function () { document.getElementById('header_login').click(); }, 100); }
		}
	
	messageIsSent = () =>
		{
			window.addBlur('.product_section, .header');
			this.setState({showSendMessage: false});
			Util.showPopupDialog('Poruka poslata', 'Vaša poruka je uspješno poslata korisniku <b>'+this.state.productInfo.username+'</b>.', false, false, () => { this.setState({showSendMessage: false}); });
		}
	
	updateChild  = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
			
		}
		
	doCloseConfirmation = () =>
		{
			window.removeBlur('');
			window.location = '/kupljeniproizvodi';			
			this.setState({showBuyNow: false, buySuccess: false}); 
		}

	render(){
		return(
			<div className="product_page">
				{ this.state.blockInfo == true ?
					<div className="product_section">Ovaj oglas nije pronađen.</div>
					:
					<div className="product_section">
						<div className= "product_info_container">
							<div className="product_image_container">
								<ProductImages image={this.state.productInfo.image} topTitle={this.state.productInfo.name} slides={this.state.productInfo.images_array}/>
							</div>
							
							
							<div className="product_info_upper_container product_info_upper_containermobile">
								<div className="article_body">{ this.state.productInfo.categories_array.map(category => 
										<Link to={category.link} key={category.id}><span>{category.name}</span></Link>)
									}
								</div>
								<div className="product_name">{this.state.productInfo.name}<div className={'loader_small'+(this.state.loadingProduct ? ' active' : '')}></div>&nbsp;</div>
								<div className="product_location">{ this.state.productInfo.location }</div>
								<div className="product_post_time">Objavljeno {Util.dateFormat(this.state.productInfo.time)}</div>
								<div className="product_condition">{(this.state.productInfo.used == 'new-product') ? 'Novo' : 'Polovno'}</div>
								<div className="product_price_and_quantity_container">
									<div className="product_price">{ Util.formatPrice(this.state.productInfo.price) }</div>
									{ this.state.productInfo.quantity != '' && this.state.productInfo.quantity != null && this.state.productInfo.quantity != 'null' && <div className="product_quantity">Na stanju: { this.state.productInfo.quantity }</div>}
								</div>
								
								{ this.state.productInfo.user_id !== this.state.usersData.id && this.state.usersData.id != undefined && this.state.productInfo.is_sold !== 1 &&
									<div>
										{ (this.state.productInfo.delivery == 'shipping' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1" onClick={() => { this.setState({showBuyNow: true});  window.addBlur('.product_section, .header'); }}>Naruči dostavu</div>
										}
										{ (this.state.productInfo.delivery == 'in-person' || this.state.productInfo.delivery == 'both') &&
											<div className="contact_button1 contact_button3" onClick={() => { this.setState({showLocation: true});  window.addBlur('.product_section, .header'); }}>Vidi lokaciju</div>
										}
										<div className="contact_button1 contact_button2" onClick={() => { this.setState({showSendMessage: true}); window.addBlur('.product_section, .header'); }}>Poruka</div>
									</div> }
								
								{ this.state.usersData.id == undefined && 
									<div>
										{ (this.state.productInfo.delivery == 'shipping' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1" onClick={() => { this.setState({showBuyNow: true});  window.addBlur('.product_section, .header'); }}>Naruči dostavu</div>
										}
										{ (this.state.productInfo.delivery == 'in-person' || this.state.productInfo.delivery == 'both') &&
											<div className="contact_button1 contact_button3" onClick={() => { this.setState({showLocation: true});  window.addBlur('.product_section, .header'); }}>Vidi lokaciju</div>
										}
										<div className="contact_button1 contact_button2" onClick={this.doSimulateLogin}>Poruka</div>
									</div>
								}
								
								{ this.state.productInfo.user_id == this.state.usersData.id && this.state.usersData.id != undefined && this.state.productInfo.is_sold !== 1 &&
									<div>
										<Link to={'/zamjeniproizvod/'+Util.getOrderId(window)}><div className="contact_button1">Izmijeni informacije</div></Link>
									</div>
								}
							</div>
							
							
							{ this.state.productInfo.detailed_info != "" &&
								<div>
									<div className="product_text">{this.state.productInfo.detailed_info}</div>
								</div>
							}
							{ this.state.productInfo.meta_array != "" &&
								<div className="product_description_tab product_description_padding">
									<div className="product_title product_title_adjustment">Detalji</div>
									<div className="product_table">
										{ this.state.productInfo.meta_array.map((product, i) =>
												<div className="table_column table_column_border" key={i}>
													<div className="product_column_info">{ product.name }</div>
													{ product.value == 1 && product.is_checkbox == 1 ? <div className="product_column_spec2"><span className="check_green">&nbsp;</span></div> : <div className="product_column_spec">{product.value}</div> }
												</div>
											)}										
									</div>
								</div>
							}
						</div>
						<div className="product_info_container">
							<div className="product_info_tab">
								<div className="product_info_upper_container product_info_upper_containerdesktop">
									<div className="article_body">{ this.state.productInfo.categories_array.map(category => 
											<Link to={category.link} key={category.id}><span>{category.name}</span></Link>)
										}
									</div>
									<div className="product_name">{this.state.productInfo.name}<div className={'loader_small'+(this.state.loadingProduct ? ' active' : '')}></div>&nbsp;</div>
									<div className="product_location">{ this.state.productInfo.location }</div>
									<div className="product_post_time">Objavljeno {Util.dateFormat(this.state.productInfo.time)}</div>
									<div className="product_condition">{(this.state.productInfo.used == 'new-product') ? 'Novo' : 'Polovno'}</div>
									<div className="product_price_and_quantity_container">
										<div className="product_price">{ Util.formatPrice(this.state.productInfo.price) }</div>
										{ this.state.productInfo.quantity != '' && this.state.productInfo.quantity != null && this.state.productInfo.quantity != 'null' && <div className="product_quantity">Na stanju: { this.state.productInfo.quantity }</div>}
									</div>
									
									{ this.state.productInfo.user_id !== this.state.usersData.id && this.state.usersData.id != undefined && this.state.productInfo.is_sold !== 1 &&
										<div>
											{ (this.state.productInfo.delivery == 'shipping' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1" onClick={() => { this.setState({showBuyNow: true});  window.addBlur('.product_section, .header'); }}>Naruči dostavu</div>
											}
											{ (this.state.productInfo.delivery == 'in-person' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1 contact_button3" onClick={() => { this.setState({showLocation: true});  window.addBlur('.product_section, .header'); }}>Vidi lokaciju</div>
											}
											<div className="contact_button1 contact_button2" onClick={() => { this.setState({showSendMessage: true}); window.addBlur('.product_section, .header'); }}>Poruka</div>
										</div> }
									
									{ this.state.usersData.id == undefined && 
										<div>
											{ (this.state.productInfo.delivery == 'shipping' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1" onClick={() => { this.setState({showBuyNow: true});  window.addBlur('.product_section, .header'); }}>Naruči dostavu</div>
											}
											{ (this.state.productInfo.delivery == 'in-person' || this.state.productInfo.delivery == 'both') &&
												<div className="contact_button1 contact_button3" onClick={() => { this.setState({showLocation: true});  window.addBlur('.product_section, .header'); }}>Vidi lokaciju</div>
											}
											<div className="contact_button1 contact_button2" onClick={this.doSimulateLogin}>Poruka</div>
										</div>
									}
									
									{ this.state.productInfo.user_id == this.state.usersData.id && this.state.usersData.id != undefined && this.state.productInfo.is_sold !== 1 &&
										<div>
											<Link to={'/zamjeniproizvod/'+Util.getOrderId(window)}><input type="button" className="contact_button1" value="Izmijeni informacije" /></Link>
										</div>
									}
								</div>
								
								<div className="info_card_container">
									<div className="profile_info_card">
										<div>
											<div className="profile_image" style={{'backgroundImage': (this.state.productInfo.user_image ? 'url('+window.imagesLink+this.state.productInfo.user_image+')' : '')}}></div>
											<div className="profile_name">{this.state.productInfo.username}</div>
										</div>
										<div>
											<Link to={("/radnja/"+this.state.productInfo.username)}><div className="profile_link">Profil</div></Link>
										</div>
									</div>
									<div className="product_rating_statistic">

										<SellerRating  currentTab="seller" userId={this.state.productInfo.user_id} />

									</div>
								</div>
								
								
								<div className="sharebutton">
									<div class="fb-share-button" data-layout="button" data-size="large"></div>
								</div>
							</div>
						</div>

						{ this.state.productInfo.more_info != "" && <div className="product_description_longform_tab">
								<div className="product_title">Detaljan opis</div>
								<div className="product_text" dangerouslySetInnerHTML={{__html: this.state.productInfo.more_info }}></div>
							</div> }

					</div>
				}
				
				{ this.state.showSendMessage == true &&
				<div className="middle_popup">
					<div className="middle_popup_inside">
						<div className="middle_popup_close" onClick={() => { this.setState({showSendMessage: false, showSentMessage: false});  window.removeBlur(''); }}>&times;</div>
						<div className="middle_popup_title">Pošalji Novu Poruku</div>
						<div className="middle_popup_body">
						
							<div className="smallProductInfo">
								<div className="smallProductInfo_image"><img src={this.state.productInfo.image} alt="" /></div>
								<div className="smallProductInfo_info">
									<div className="smallProductInfo_name">{this.state.productInfo.name}</div>
									<div className="smallProductInfo_condition">{(this.state.productInfo.used == 'new-product') ? 'Novo' : 'Polovno'}</div>
									<div className="smallProductInfo_price">{Util.formatPrice(this.state.productInfo.price)}</div>
								</div>
							</div>
						
							<Chat sendOnly={true} onSentCallback={this.messageIsSent} productChatId={this.state.productInfo.id} otherUserId={this.state.productInfo.user_id} fromProduct={true} />
						</div>
					</div>
				</div>
				}
				
				
				{ this.state.showBuyNow == true &&  this.state.buySuccess == false && (this.state.productInfo.delivery == 'shipping' || 'both') &&
					<div className="middle_popup">
						<div className="middle_popup_inside">
							<div className="middle_popup_close" onClick={() => { this.setState({showSendMessage: false, showSentMessage: false, showBuyNow: false});  window.removeBlur(''); }}>&times;</div>
						
									<div className="middle_popup_body">
										<div>
										<div className="middle_popup_title">Pošaljite podatke za kupovinu</div>
										<div className="buy_popup_field_title">Količina:</div>
										<select className="buy_popup_field" onChange={this.handleChange.bind(this)} >
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>7</option>
											<option>8</option>
											<option>9</option>
											<option>10</option>
										</select>
										<div className={'' + (this.state.order_quantity_error !== '' ? 'form_field_error' : '')}>{this.state.order_quantity_error}</div>
										<div className="buy_popup_field_container">
											<div className="buy_popup_field_title">Dostava na:</div>
											<div className="middle_popup_body_50">
												<InputAnimated type="text" id="address" title="Ime" fieldValue={this.state.order_first_name} fieldError={this.state.order_first_name_error} fieldName="order_first_name" updateChild={this.updateChild} />
												<InputAnimated type="text" id="address" title="Ulica i broj" placeholder="npr: Sarajevska 7, Sarajevo, 71000" fieldValue={this.state.order_address} fieldError={this.state.order_address_error} fieldName="order_address" updateChild={this.updateChild} />
												<InputAnimated type="text" id="address" title="Grad" placeholder="npr: Sarajevo" fieldValue={this.state.order_city} fieldError={this.state.order_city_error} fieldName="order_city" updateChild={this.updateChild} />
											</div>
											<div className="middle_popup_body_50">
												<InputAnimated type="text" id="address" title="Prezime" fieldValue={this.state.order_last_name} fieldError={this.state.order_last_name_error} fieldName="order_last_name" updateChild={this.updateChild} />
												<InputAnimated type="text" id="address" title="Poštanski broj" placeholder="npr: 71000" fieldValue={this.state.order_postal} fieldError={this.state.order_postal_error} fieldName="order_postal" updateChild={this.updateChild} />
												<InputAnimated type="text" id="address" title="Kontakt telefon" placeholder="npr: 0663314..." fieldValue={this.state.order_phone} fieldError={this.state.order_phone_error} fieldName="order_phone" updateChild={this.updateChild} />
											</div>
											<div style={{ margin: '0 10px' }}>
												<TextareatAnimated id="order_more_info" title="Dodatne informacije" fieldValue={this.state.order_more_info} fieldError={this.state.more_infoError} fieldName="order_more_info" updateChild={this.updateChild} />
											</div>
											<input type="button" className="contact_button1 buy_popup_submit" value="Pošalji Podatke" onClick={this.doOrder} />
										</div>

								</div>
				
								
							</div>
						</div>
					</div>
				}
				
				{ this.state.buySuccess == true && (this.state.productInfo.delivery == 'shipping' || 'both') &&
					<div className="middle_popup">
						<div className="middle_popup_inside">
							<div className="middle_popup_close" onClick={ this.doCloseConfirmation }>&times;</div>
							<div className="middle_popup_title">Kupovina proizvoda</div>
							<div className="middle_popup_body">
								<div className="middle_popup_body_padding">Vaš proizvod je uspješno naručen!</div>
							</div>
							<input type="button" className="contact_button1 buy_popup_submit" value="U redu" onClick={ this.doCloseConfirmation } />
						</div>
					</div>
				}
				
				{ this.state.showLocation == true && (this.state.productInfo.delivery == 'in-person' || 'both') &&
					<div className="middle_popup">
						<div className="middle_popup_inside">
							<div className="middle_popup_close" onClick={()=> {this.setState({showLocation : false}); window.removeBlur(''); }}>&times;</div>
							<div className="middle_popup_title">Informacije o radnji</div>
							<div className="middle_popup_body">
								{ this.state.seller_info.address !== '' && <div className="middle_popup_body_padding">Adresa prodavca: <span className="strong_info"> { this.state.seller_info.address} , {this.state.seller_info.postal_code}  {this.state.seller_info.city}</span></div> }
								{ this.state.seller_info.phone !== '' && <div className="middle_popup_body_padding bottom_margin_popup">Za sve informacije možete se obratiti putem telefona : <span className="strong_info"> {this.state.seller_info.phone} </span></div> }
								{ this.state.seller_info.address == '' && this.state.seller_info.address == '' && <div className="middle_popup_body_padding">Kontakt je moguć samo preko poruka na OPK.ba</div> }
							</div>
							<input type="button" className="contact_button1 buy_popup_submit" value="U redu" onClick={()=> {this.setState({showLocation : false}); window.removeBlur(''); }} />
						</div>
					</div>
				}
				
			</div>
		)
	}
}

export default Product;
