import React, { Component } from 'react';
import './Payments.css';

import { Footerhome } from '../../components/footerhome/Footerhome';

class Payments extends Component {
	
	constructor()
		{
			super();
			this.state = {
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
						};
		}
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
		}
	
	render()
	{
		return (
				<div className="payments_page">
					<div className="payments_top">Pogodnosti i Cijene za <div></div> Radnje</div>
					
					
					<div className="payments_section_top">Svako ko želi da prodaje dobija besplatan probni period od 30 dana!</div>
					
					<div className="payments_section_top">Pogodnosti</div>
					<div className="payments_benefit_sections">
						<div className="payments_benefit_section">
							<div className="paybenefit_top">Besplatan probni period od 30 dana</div>
							<div>Bez ugovora i obaveza!<br />Svako može veoma lako da doda novi oglas <b>besplatno</b> i da provjeri kako će mu ići prodaja na OPK.ba.</div>
						</div>
						
						<div className="payments_benefit_section">
							<div className="paybenefit_top">Neograničen broj oglasa</div>
							<div>Imate veliku radnju i hoćete da prodajte više proizvoda odjednom?<br />Nema problema, dodajite koliko god želite <b>bez dodatnih troškova</b>.</div>
						</div>
						
						<div className="payments_benefit_section">
							<div className="paybenefit_top">Uključena promocija jednog oglasa</div>
							<div>Uvjek možete da promovišite jedan oglas na početnoj stranici na koliko god to želite. Možete da birate koji oglas da promovišete i da promjenite u bilo kom trenutku na neki drugi.</div>
						</div>
						
						<div className="payments_benefit_section">
							<div className="paybenefit_top">Neograničen broj obnavljanja oglasa</div>
							<div>Niste prodali oglas u kratkom roku?<br />Uvjek možete da obnovite oglas da obavjestite kupce da je aktivan i sigurno dostupan za prodaju.</div>
						</div>
					</div>
					
					<div className="pay_top">
						<div>OPK brine o svojim radnjama i stavlja ih na prvo mjesto.</div>
						Takom korištenja našeg portala održavamo kontakt sa radnjama, pomažemo im u prodaji i sve novine koje uvodimo su tu da olakšamo prodaju i da na što bolji način istaknemo njihove najbolje proizvode.
					</div>
					
					<div className="payments_section_top">Cijena za radnje<div>(Prvih 30 dana se ne naplaćuje)</div></div>
					<div className="payments_price_sections">
						<div className="pay_price_one">
							<div className="pay_topprice">57 <span>KM</span></div>
							<div className="pay_toppricesub">mjesečno</div>
							
							<ul>
								<li className="pay_price_desc">Plaćanje svakog mjeseca za naredni mjesec</li>
								<li className="pay_price_desc">Plaćate samo ako želite da prodajete</li>
								<li className="pay_price_desc">Bez obaveze</li>
								<li className="pay_price_desc">Bez potpisivanja ugovora</li>
								<li className="pay_price_desc">Bez skrivenih troškova</li>
							</ul>
						</div>
						
						<div className="pay_price_one">
							<div className="pay_topprice"><div>684 <span>KM</span></div>600 <span>KM</span></div>
							<div className="pay_toppricesub">godišnje</div>
							
							<ul>
								<li className="pay_price_desc"><b>Popust od 84KM</b></li>
								<li className="pay_price_desc">Plaćanje jednom godišnje za narednu godinu</li>
								<li className="pay_price_desc">Plaćate samo ako želite da prodajete</li>
								<li className="pay_price_desc">Bez obaveze</li>
								<li className="pay_price_desc">Bez potpisivanja ugovora</li>
								<li className="pay_price_desc">Bez skrivenih troškova</li>
							</ul>
						</div>
					</div>
					
					<div className="pay_top">
						Ukoliko nakon mjesec dana budete zadovoljni prodajom, zadovoljni iskustvom sa našim portalom, mi ćemo u dogovoru sa Vama poslati na adresu koja se nalazi na profilu fakturu/predračun na kom imate sve potrebne podatke za uplatu.
						<br /><br />
						OPK.ba svoje radnje shvata ozbiljno, plaćanje je jednostavno, nema skrivenih tokena/zlatnika/bonova/novčića, nego jednostavno plaćanje kao za svaku ozbiljnu uslugu.
					</div>
					
					<div className="payments_onlysell">Ova stranica je samo za one koji žele da prodaju na OPK.ba i ne odnosi se na korisnike koji kupuju.</div>
					
					<Footerhome></Footerhome>
				</div>
		);
	}
}

export default Payments;