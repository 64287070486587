import { Component } from 'react';
import './Style.css';


export class TextareatAnimated extends Component {
	
	constructor()
		{
			super();
			this.state = {hasFocus: false};
		}
	
	doFormFocus = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: true});
			}
	}
	
	doFormBlur = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: false});
			}
	}
	
	render()
	{
		return (
				<div className={('input_element_animated form_field'+(this.state.hasFocus ? ' active' : '')+(this.props.fieldValue && this.props.fieldValue.length > 0 ? ' hasActiveText' : '')+(this.props.resize ? ' textarea_resize_'+this.props.resize : ''))}>
					<div className="form_field_title">{this.props.title}</div>
					<textarea name={this.props.id} id={this.props.id} value={this.props.fieldValue} onChange={(event) => this.props.updateChild({[this.props.fieldName]: event.target.value}, this.props.callback)} onFocus={this.doFormFocus} onBlur={this.doFormBlur} placeholder="" />
					{ this.props.fieldError && <div className="form_field_error">{this.props.fieldError}</div> }
				</div>
		);
	}
}