import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';


// All Users
import Home from './pages/home/Home';
import Search from './pages/search/Search';
import Product from './pages/product/Product';
import Contact from './pages/static/Contact';
import Terms from './pages/static/Terms';
import SellingList from './pages/sellinglist/SellingList';
import Categories from './pages/categories/Categories';
import Payments from './pages/payments/Payments';

// Logged in Only
import Settings from './pages/settings/Settings';
import Notifications from './pages/notifications/Notifications';
import Profile from './pages/profile/Profile';
import Statistic from './pages/statistic/Statistic';
import Messages from './pages/messages/Messages';
import NewProduct from './pages/addproducts/NewProduct';
import AboutUs from './pages/aboutus/About';
import BoughtList from './pages/boughtlist/Boughtlist';
import OrgProducts from './pages/orgproducts/OrgProducts';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import AdminPanel from './pages/adminpanel/AdminPanel';


// Not logged in Only
import Register from './pages/register/Register';

var oldLinkPath = window.location.href;

function App()
	{
		var navigate = useNavigate();
		var loggedIn = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).id ? true : false : false : false;
		var is_admin = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).is_admin ? true : false : false : false;
		
		var titleAfter = ' - OPK.ba';
		
		if ( oldLinkPath !== window.location.href ) { window.scrollTo(0,0); oldLinkPath = window.location.href; }
		
		return (<div className="App">
						<Header navigate={navigate} />
						<div className="Page">
							<Routes>
								{ /* All Users */ }
								<Route path="/" element={<Home pageTitle={'Početna stranica'+titleAfter} />} />
								<Route path="/pretraga" element={<Search pageTitle={'Pretraga'+titleAfter} />} />
								<Route path="/k/:param1" element={<Search pageTitle={'Pretraga'+titleAfter} />} />
								<Route path="/k/:param1/:param2" element={<Search pageTitle={'Pretraga'+titleAfter} />} />
								<Route path="/p/:param1" element ={<Product pageTitle={titleAfter} />} />
								<Route path="/radnja/:param1" element={<Profile pageTitle={titleAfter} />} />
								<Route path="/svekategorije" element={<Categories pageTitle={'Kupujem'+titleAfter} navigate={navigate} />} />
								<Route path="/kontakt" element={<Contact pageTitle={'Kontakt'+titleAfter} />} />
								<Route path="/onama" element={<AboutUs pageTitle={'O nama'+titleAfter} navigate={navigate} />} />
								<Route path="/placanje" element={<Payments pageTitle={'Plan Plaćanja'+titleAfter} navigate={navigate} />} />
								<Route path="/uslovikoristenja" element={<Terms pageTitle={'Uslovi Koristenja'+titleAfter} navigate={navigate} />} />
								<Route path="/kupovina" element={<SellingList pageTitle={'Kupujem'+titleAfter} navigate={navigate} />} />
								
								{ /* Logged in Only */ }
								<Route path="/mojprofil" element={loggedIn ? <Profile myprofile="1" pageTitle={titleAfter} /> : <Navigate to='/' />} />
								<Route path="/statistika" element={loggedIn ? <Statistic myprofile="1" pageTitle={'Statistika'+titleAfter} /> : <Navigate to='/' />} />
								<Route path='/podesavanja' element={loggedIn ? <Settings pageTitle={'Podešavanja'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								<Route path='/obavjestenja' element={loggedIn ? <Notifications pageTitle={'Obavještenja'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								<Route path="/poruke" element={loggedIn ? <Messages pageTitle={'Poruke'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								<Route path="/poruke/:param1/:param2" element={loggedIn ? <Messages pageTitle={'Poruke'+titleAfter} singleMessage="1" /> : <Navigate to='/' />} />
								<Route path='/dodajproizvod' element={loggedIn ? <NewProduct pageTitle={'Dodajte proizvod'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								<Route path='/novaprodaja' element={loggedIn ? <NewProduct pageTitle={'Novi oglas'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								<Route path='/kupljeniproizvodi' element={loggedIn ? <BoughtList pageTitle={'Kupljeni proizvodi' + titleAfter} navigate={navigate} /> : <Navigate to='/' /> } />
								<Route path='/organizovanjeproizvoda' element={loggedIn ? <OrgProducts pageTitle={'Organizovanje proizvoda' + titleAfter} navigate={navigate} /> : <Navigate to='/' /> } />
								<Route path='/zamjeniproizvod/:param1' element={loggedIn ? <NewProduct isEditing={true} pageTitle={'Zamjena oglasa'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
								
								<Route path='/adminpanel' element={loggedIn && is_admin ? <AdminPanel  pageTitle={'Dodajte proizvod'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />

								{ /* Not logged in Only */ }
								<Route path="/registracija" element={!loggedIn ? <Register pageTitle={'Registracija'+titleAfter} navigate={navigate} /> : <Navigate to='/mojprofil' />} />
								<Route path="/lozinka" element={!loggedIn ? <ForgotPassword pageTitle={'Zaboravili ste lozinku'+titleAfter} navigate={navigate} /> : <Navigate to='/' />} />
							</Routes>
						</div>
						<Footer navigate={navigate} />
					</div>);
	}


export default App;
