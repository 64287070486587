import { Component } from 'react';
import { Link } from "react-router-dom";
import './Footerhome.css';

export class Footerhome extends Component {
	
	constructor()
	{
		super();
		this.state = {usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {}};
	}
	
	render()
	{
		return (
				<div className="homepage_footer_container">
					<div className="homepage_footer_list">
						<div className="homepage_footer_title">Kupujete?</div>
						{ this.state.usersData.id ?	"" : <Link to="/registracija"><div className="homepage_footer_text">Registracija</div></Link> }
						<Link to="/svekategorije"><div className="homepage_footer_text">Kupovina</div></Link>
						<Link to="/uslovikoristenja"><div className="homepage_footer_text">Pravila i uslovi</div></Link>
					</div>
					<div className="homepage_footer_list">
						<div className="homepage_footer_title">Prodajte</div>
						{ this.state.usersData.id ? <Link to="/novaprodaja"><div className="homepage_footer_text">Nova prodaja</div></Link>: <Link to="/registracija"><div className="homepage_footer_text">Registracija</div></Link> }
						<Link to="/uslovikoristenja"><div className="homepage_footer_text">Pravila i uslovi</div></Link>
						<Link to="/placanje"><div className="homepage_footer_text">Pogodnosti i cijena</div></Link>
					</div>
					<div className="homepage_footer_list">
						<div className="homepage_footer_title">Društvene mreže</div>
						<a href="https://www.facebook.com/online.prodaja.kupovina/" target="_blank" rel="noreferrer"><div className="homepage_footer_text homepage_footer_img">Facebook</div></a>
					</div>
					<div className="homepage_footer_list">
						<div className="homepage_footer_title">O nama</div>
						<Link to="/kontakt"><div className="homepage_footer_text">Kontakt</div></Link>
					</div>
					<div className="homepage_footer_copyright">Copyright &copy; 2022 OPK.ba. Sva prava zadržana.</div>
				</div>
		);
	}
}