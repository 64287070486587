import React, { Component } from 'react';
import axios from 'axios';

import './AdminPanel.css';
import Util from '../../util/util';

class AdminPanel extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			allUsers: [],
			reportedComments: [],
			reportedUsers: [],
			isAdmin: true,
			currentTab: 'users',
			gettingInformations: false,
		}

	}
	
	componentDidMount = () =>
		{
			
			var mainThis = this;
			if ( this.state.isAdmin )
			{
				axios.get(window.ajaxLink+'user/getAllUsersSimple')
							.then(res => {
								
								if ( res['data'] && res['data']['responseData'] )
									{
										mainThis.setState({ allUsers : res['data']['responseData']});
									}
							}).catch(err => {  }
						);
						
				axios.get(window.ajaxLink+'user/getReportedComments')
					.then(res => {
						if ( res['data'] && res['data']['responseData'] )
									{
										mainThis.setState({ reportedComments : res['data']['responseData']});
									}	
					});
					
				axios.get(window.ajaxLink+'user/getReportedUsers')
					.then(res => {
						if ( res['data'] && res['data']['responseData'] )
									{
										mainThis.setState({ reportedUsers : res['data']['responseData']});
									}	
					});
			}
			
			
		}
		
	unblockUser = (id) =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'user/unblockUser', {user_id: id})
				.then(res => {
					if ( res['data'] && res['data']['responseData'] )
									{
										mainThis.setState({ allUsers: res['data']['responseData']});
									}
				}).catch(err => {console.log(err)});
			
		}
		
	blockUser = (id) =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'user/blockUser', {user_id: id})
				.then(res => {
					if ( res['data'] && res['data']['responseData'] )
						{
							mainThis.setState({ allUsers: res['data']['responseData']});
						}
				}).catch(err => {console.log(err)});
			
		}
		
	blockComment = (comment_id) =>
		{
			//var mainThis = this;
			axios.post(window.ajaxLink+'user/blockComment',{ comment_id: comment_id })
				.then(res => {
					
				}).catch( err => {console.log(err)});
		}
		
	ignoreReport = (id) =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'user/ignoreReport', { report_id: id })
				.then( res => {
					if ( res['data'] && res['data']['responseData'] )
						{
							mainThis.setState({ reportedUsers: res['data']['responseData']});
						}
				}).catch( err => { console.log(err)});
		}
		
		
	render()
	{
		console.log(this.state.allUsers);
		return(
				<div className="admin_panel_page">
					<div className="admin_panel_page_header">
						<div className="admin_panel_page_title">Administrator panel</div>
					</div>
					<div className="admin_sections">
						<a href="#korisnici"><div onClick={() => { this.setState({currentTab: 'users'}); }} className={(this.state.currentTab == 'users' ? 'active' : '')}>Lista korisnika ({this.state.allUsers.length})</div></a>
						<a href="#prijavljenikorisnici"><div onClick={() => { this.setState({currentTab: 'reported_users'}); }} className={(this.state.currentTab == 'reported_users' ? 'active' : '')}>Prijavljeni korisnici ({this.state.reportedUsers.length})</div></a>
						<a href="#prijavljenikomentari"><div onClick={() => { this.setState({currentTab: 'reported_comments'}); }} className={(this.state.currentTab == 'reported_comments' ? 'active' : '')}>Prijavljeni komentari ({this.state.reportedComments.length})</div></a>
						<a href="#prijavljenezloupotrebe"><div onClick={() => { this.setState({currentTab: 'reported_abuse'}); }} className={(this.state.currentTab == 'reported_abuse' ? 'active' : '')}>Prijavljene zloupotrebe</div></a>
						
					</div>
					{ this.state.currentTab == 'users' &&<div className="admin_panel_section">
						<div className="admin_section_title">Lista registrovanih korisnika ({this.state.allUsers.length})</div>
								<div className="one_user_header">
									<div className="one_user_field" style={{'width': '50px'}}>ID</div>
									<div className="one_user_field">Username</div>
									<div className="one_user_field">Online</div>
									<div className="one_user_field" style={{'width': '80px'}}>Aktivnih</div>
									<div className="one_user_field" style={{'width': '80px'}}>Prodatih</div>
									<div className="one_user_field" style={{'width': '80px'}}>Poruka</div>
									<div className="one_user_field">Ime i prezime</div>
									<div className="one_user_field">Email</div>
									<div className="one_user_field">Telefon</div>
									<div className="one_user_field">Adresa</div>
									<div className="one_user_field">Postanski broj</div>

									<div className="one_user_field last_item">Status</div>
								</div>						
						{
							 this.state.allUsers.map((oneUser, i) =>
								<div className="one_user_item" key={i}>
									<div className="one_user_field" style={{'width': '50px'}}>{oneUser.id}</div>
									<a href={'/radnja/'+oneUser.username} target="_blank" rel="noreferrer"><div style={{"cursor":"pointer", 'text-align': 'left'}} className="one_user_field">{oneUser.username}</div></a>
									<div className="one_user_field">{Util.dateFormat(oneUser.registered)}<sup>R</sup><br /><b>{Util.dateTimeFormat(oneUser.last_time_logged)}</b></div>
									<div className="one_user_field" style={{'width': '80px'}}>{oneUser.active}</div>
									<div className="one_user_field" style={{'width': '80px'}}>{oneUser.sold}</div>
									<div className="one_user_field" style={{'width': '80px'}}>{oneUser.receved}/{oneUser.sent}</div>
									<div className="one_user_field">{oneUser.first_name} {oneUser.last_name}</div>
									<div className="one_user_field">{oneUser.email}</div>
									<div className="one_user_field">{oneUser.phone}</div>
									<div className="one_user_field">{oneUser.address}</div>
									<div className="one_user_field">{oneUser.postal_code}</div>

									{(oneUser.status == 0) ? <div className="one_user_field blocked_user" onClick={() => { this.unblockUser(oneUser.id) }}>Odblokiraj</div> : <div className="one_user_field valid_user" onClick={() => { this.blockUser(oneUser.id) }}>Blokiraj</div>}
								</div>
							) 
						}
					</div> }
					{ this.state.currentTab == 'reported_comments' && <div className="admin_panel_section">
						<div className="admin_section_title">Lista prijavljenih komentara ({this.state.reportedComments.length})</div>
								<div className="one_user_header">
									<div className="one_user_field">ID</div>
									<div className="one_user_field">Id komentara</div>
									<div className="one_user_field">ID korisnika</div>
									<div className="one_user_field">Username</div>
									<div className="one_user_field">Komentar</div>
									
								</div>						
						{
							 this.state.reportedComments.map((oneUser, i)=>
								<div className="one_user_item" key={i}>
									<div className="one_user_field">{oneUser.id}</div>
									<div className="one_user_field">{oneUser.comment_id}</div>
									<div className="one_user_field">{oneUser.reported_user}</div>
									<div className="one_user_field">{oneUser.reported_username}</div>
									<div className="one_user_field">{oneUser.text}</div>
									<div className="one_user_field blocked_user" onClick={() => { this.blockComment(oneUser.comment_id)}}>Blokiraj</div> 	
									<div className="one_user_field blocked_user" >Ignoriši</div> 	
								</div>
							) 
						}
					</div>}
					{ this.state.currentTab == 'reported_users' && <div className="admin_panel_section">
						<div className="admin_section_title">Lista prijavljenih korisnika ({this.state.reportedUsers.length})</div>
								<div className="one_user_header">
									<div className="one_user_field">ID</div>
									<div className="one_user_field">Prijavio ID</div>
									<div className="one_user_field">Prijavio</div>
									<div className="one_user_field">Prijavljeni ID</div>
									<div className="one_user_field">Prijavljeni</div>
									<div className="one_user_field">Komentar</div>
									<div className="one_user_field">Blokiraj korisnika</div>
									<div className="one_user_field">Neosnovana prijava</div>
									
								</div>						
						{
							 this.state.reportedUsers.map((oneUser, i)=>
								<div className="one_user_item" key={i}>
									<div className="one_user_field">{oneUser.id}</div>
									<div className="one_user_field">{oneUser.from_user}</div>
									<div className="one_user_field">{oneUser.reporter}</div>
									<div className="one_user_field">{oneUser.to_user}</div>
									<div className="one_user_field">{oneUser.reported_user}</div>
									<div className="one_user_field">{oneUser.message}</div>
									<div className="one_user_field blocked_user" onClick={() => { this.blockUser(oneUser.to_user)}}>Blokiraj</div> 	
									<div className="one_user_field blocked_user" onClick={() => {this.ignoreReport(oneUser.id)}}>Poništi</div> 	
								</div>
							) 
						}
					</div>}
					<div className={'loader_holder'+(this.state.gettingInformations ? ' active' : '')}>
						<div className='loader_big'></div>
					</div>
				</div>
		)
	}
}

export default AdminPanel;