import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Util from '../../util/util';

import NewProductCategory from '../../components/newproductcategory/NewProductCategory';
import ProductPricetag from '../../components/productpricetag/ProductPricetag';

import { InputAnimated } from '../../components/elements/InputAnimated';
import { TextareatAnimated } from '../../components/elements/TextareatAnimated';
import { SelectAnimated } from '../../components/elements/SelectAnimated';

import './NewProduct.css';

var lastProduct = '';
class NewProduct extends Component {
	constructor(props)
	{
		super(props);
		this.defaultState = {
			
			alreadyFocused: false,
			
			delivery_type: '',
			productName: '',			
			productQuantity: '',
			product_used: '',						
			productDetailedInfo: '',			
			productMoreInfo: '',
			
			
			imageUploaded: false,
			imageError:'',
			productNameError: '',
			productQuantityError:'',
			productExchangeError:'',
			productDetailedInfoError:'',
			productMoreInfoError:'',
			
			uploadImages: [],
			categoryList: [],
			
			mainCategory: '',
			subCategory: '',
			subSubCategory: '',
			subSubSubCategory: '',
			
			metaId: '',
			checkbox_list:[],
			inputs_list:[],
			oneInputVal: {},
			
			changedAnyCustomField: false,
			
			mainCategoryError: '',	
			subCategoryError: '',			
			subSubCategoryError: '',
			subSubSubCategoryError: '',
			product_usedError: '',
			used_error_message: false,
			product_delivery_error: '',
			delivery_error_message: false,

		
			
			
			usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
			user_id: 0,
			city_id: 0,
			product_id: 0,
			productCity: '',
			productPrice: '',
			productPriceError: '',
			errorMessage: false,
			registrationCode: '',
			hasRegistrationCode: true,
			registrationCodeError: '',
			
			edit_product_id: 0,
			uploadedImages: 0,
			addingProduct: false,
			
			selling_popup: false,
			selling_first_name: '',
			selling_last_name:'',
			selling_address:'',
			selling_postal_code:'',
			selling_region_id:0,
			selling_city_id:0,
			selling_cities_list: [],
			selling_city_name:'',
			selling_phone_number:'',
			
			selling_first_name_error: '',
			selling_last_name_error:'',
			selling_address_error:'',
			selling_postal_code_error:'',
			selling_region_id_error:'',
			selling_city_id_error:'',
			selling_city_name_error:'',
			selling_phone_number_error:''
		}
		
		this.state = this.defaultState;
	}
	
		checkPriceValue = (val) =>
			{
				var newVal = val.productPrice;
				var newValObj = {};
				newVal = newVal.replace(/[^0-9,]/g, '');
				newVal = newVal.replace(',,', ',').replace(',,', ',');
				//newVal = parseFloat(newVal);
				newValObj = {'productPrice': newVal};
				this.updateChild(newValObj);
			}
		
		updateChild = (val, callback) =>
			{
				if ( callback )
					{
						this.setState(val, callback);
					}
				else
					{
						this.setState(val);
					}
			}
		
		getInputValues = (val,i) =>
			{
				if ( val && Object.keys(val) && Object.keys(val)[0] )
					{
						var custom_inputs = this.state.inputs_list;
						custom_inputs[i]['value'] = val[Object.keys(val)[0]];
						this.setState({inputs_list: custom_inputs, changedAnyCustomField: true});
						
						var inputArr = [];
						var arrName = custom_inputs[i].id;
						inputArr = custom_inputs[i][arrName];
						inputArr = inputArr.filter((object) => object.name !== 'Drugo:');
						var checkIfHasName = inputArr.filter((object) => object.name == custom_inputs[i]['value'] );
						
						if ( custom_inputs[i]['value'] == 'Drugo:' )
							{
								custom_inputs[i]['user_id'] = this.state.usersData.id;
							}
						
						if ( custom_inputs[i]['user_id'] !== 0 && checkIfHasName.length > 0 )
							{
								var ditch_user_id = this.state.inputs_list;
								ditch_user_id[i]['user_id'] = 0;
								this.setState({inputs_list: ditch_user_id, changedAnyCustomField: true});
							}
					}
			}
		
		getCheckboxValues = (i) =>
			{
				var checkBox = this.state.checkbox_list;
				checkBox[i]['checked']= !checkBox[i]['checked'];
				this.setState({checkbox_list: checkBox, changedAnyCustomField: true});
			}
		
		checkDetailedExtract = () =>
			{
				if ( this.state.changedAnyCustomField == false )
					{
						var foundAny = false;
						var checkBox = this.state.checkbox_list;
						for ( var chk in checkBox )
							{
								var chkName = checkBox[chk]['id'];
								if ( this.state.productMoreInfo.indexOf(chkName) > 0 )
									{
										checkBox[chk]['checked']= true;
										foundAny = true;
									}
							}
						
						var inputList = this.state.inputs_list;
						for ( var inp in inputList )
							{
								if ( inputList[inp]['select'] && inputList[inp]['id'] && inputList[inp][inputList[inp]['id']] )
									{
										var inpName = inputList[inp]['id'];
										var splitInfo = this.state.productMoreInfo.split("\n");
										for ( var spi of splitInfo )
											{
												var spi2 = spi.trim().replace(':', '').replace('  ', ' ').replace('  ', ' ');
												var spName = spi2.split(' ')[0];
												
												if ( inpName.toLowerCase() == spName.toLowerCase() )
													{
														var fvalue = spi2.replace(inpName+' ', '');
														for ( var inpval of inputList[inp][inputList[inp]['id']] )
															{
																if ( fvalue.toLowerCase().indexOf(inpval['id'].toLowerCase()) > -1 )
																	{
																		inputList[inp]['value'] = inpval['id'];
																	}
															}
													}
											}
									}
							}
						
						if ( foundAny ) { this.setState({checkbox_list: checkBox}); }
					}
			}
		
		componentDidMount()
			{
				this.loadNewProduct(this.props);
				window.onbeforeunload = function (e) { e.preventDefault(); return 'tt'; }
			}
		
		loadNewProduct = (nextProps) =>
			{
				this.setState(this.defaultState);
				
				document.title = nextProps.pageTitle;
				var product_id = Util.getPath(2);
				
				var mainThis = this;
				axios.post(window.ajaxLink+'products/getCategory', {cat_id: 0}).then(res => {
						
						var categoriesList = res['data']['responseData']['categories'];
						categoriesList.unshift({id: '', name: '', disabled: true, default: true});
						mainThis.setState({categoryList: categoriesList});
						
					}).catch(err => {});
				
				if ( product_id )
					{
						this.setState({edit_product_id: product_id});
						
						lastProduct = product_id;
						axios.post(window.ajaxLink+'products/getProductInfo',{product_id})
							.then(res => {
								
								var image_list = res['data']['responseData']['product_image_info'];
								var product_info = res['data']['responseData']['product_info'];
								mainThis.setState({
									productName: product_info[0]['name'],
									productQuantity: product_info[0]['quantity'],
									delivery_type: product_info[0]['delivery'],
									productDetailedInfo: product_info[0]['detailed_info'],
									product_used: product_info[0]['used'],
									productMoreInfo: product_info[0]['more_info'],
									productPrice: (product_info[0]['price']+'').replace('.',','),
									mainCategory: product_info[0]['main_category_id'],
									subCategory: product_info[0]['category_id'],
									subSubCategory: product_info[0]['subcategory_id'],
									subSubSubCategory: product_info[0]['subsubcategory_id'],
									uploadImages: image_list,
									edit_product_id: product_info[0]['id']
								})
								
								if ( mainThis.state.subSubSubCategory != '' || mainThis.state.subSubCategory != '' || mainThis.state.subCategory != '' || mainThis.state.mainCategory != '' )
									{
										this.checkFields();
									}
						
								
							}).catch(err => {});
					}
					
				this.setState({
					userId: this.state.usersData.id,
					city_id: this.state.usersData.city_id,
					productCity: this.state.usersData.city,
					selling_first_name: this.state.usersData.first_name,
					selling_last_name: this.state.usersData.last_name,
					selling_address: this.state.usersData.address,
					selling_postal_code: this.state.usersData.postal_code,
					selling_region_id: this.state.usersData.region_id,
					selling_city_id: this.state.usersData.city_id,
					selling_city_name: this.state.usersData.city,
					selling_phone_number: this.state.usersData.phone
				}, () => { this.updateLocationPick(); this.hasUserData(); });
				
				/* axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
				axios.post(window.ajaxLink+'products/checkMyCode',{})
					.then(res => {
									if ( res['data'] && res['data']['responseData']['my_code'] )
											{
												mainThis.setState({registrationCode: res['data']['responseData']['my_code'], hasRegistrationCode: true });
											}
									else 
										{
											mainThis.setState({hasRegistrationCode: false});
										}
							
								}).catch(err => {}); */
			}
		
		UNSAFE_componentWillReceiveProps(nextProps)
			{
				document.title = nextProps.pageTitle;
				var product_id = Util.getPath(2);
				
				if ( lastProduct !== product_id)
					{
						lastProduct = product_id;
						this.loadNewProduct(nextProps);
					}
			}
		
		componentWillUnmount()
			{
				window.onbeforeunload = null;
				//this.askIfLeave();
			}
		
		/*askIfLeave = () =>
			{
				Util.showPopupDialog(
									'Potvrdite Izlazak',
									'Da li ste sigurni da želite da napustite stranicu?',
									{title: 'Napusti', css: 'middle_popup_blue_button', callback: () => { window.location.reload(); }},
									{title: 'Prekini', css: 'middle_popup_yellow_button', callback: () => { }},
									() => { },
									'warning_popup');
			}*/
		
		hasUserData = () =>
			{
				if ( this.state.selling_first_name == '' || this.state.selling_last_name == '' || this.state.selling_address == '' || this.state.selling_postal_code == '' || this.state.selling_region_id == 0 || this.state.selling_city_id == 0 || this.state.selling_phone_number == '' )
					{
						this.setState({selling_popup: true });
					}
			}

		selectImages = async (e) =>
			{
				if ( e.target.files ) 
					{
						var imageURL = this.state.uploadImages;
						let images = e.target.files;
						
						for( let img of images )
							{
								imageURL.push({'imageShow': URL.createObjectURL(img), 'imageFile': img, is_default: this.state.uploadImages.length == 0 ? 1 : 0});
							}

						this.setState({
								uploadImages: imageURL,
								imageUploaded: true,
								uploadedImages: this.state.uploadedImages+1
							});
					}			
			}
			
		updateLocationPick()
			{
				var mainThis = this;
				if ( this.state.selling_region_id )
					{
						axios.post(window.ajaxLink+'user/allCities', {region_id: this.state.selling_region_id})
								.then(res => {
									
									if ( res['data'] && res['data']['responseData'] && res['data']['responseData'] )
										{
											res['data']['responseData'].unshift({id: '0', name: '', disabled: true, default: true});
											mainThis.setState({selling_cities_list: res['data']['responseData']});
										}
								}).catch(err => {  }
							);
					}
			}
		
		doUploadImages = () =>
			{
				var product_id = Util.getPath(2);
				
				if ( this.createProduct([], true) == false ) { return false; }
				
				this.setState({addingProduct: true});
				
				var imgList = [];
				var mainThis = this;
				if ( this.state.imageUploaded == true && !product_id )
					{
						const formData = new FormData();
						for( let img of this.state.uploadImages )
							{
								formData.append('files', img['imageFile']);
							}
						
						axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
						axios.post(window.uploadProductImage, formData, {headers: {'Content-Type': 'multipart/form-data'}})
							.then(res => {
								
								mainThis.createProduct(res['data']['responseData']['imagesList'], false);
								
							}).catch(err => {}); 
					}
				else if ( this.state.imageUploaded == true && product_id )
					{
						var hasAny = false;
						const formData = new FormData();
						for( let img of this.state.uploadImages )
							{
								if ( img['imageFile'] )
									{
										formData.append('files', img['imageFile']);
										hasAny = true;
									}
							}
						
						if ( hasAny )
							{
								axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
								axios.post(window.uploadProductImage, formData, {headers: {'Content-Type': 'multipart/form-data'}})
									.then(res => {
										
										var imgListUpload = res['data']['responseData']['imagesList'];
										
										imgList = [];
										var nowImg = 0;
										for ( let img of mainThis.state.uploadImages )
											{
												if ( img['imageFile'] )
													{
														imgList.push(imgListUpload[nowImg]);
														nowImg++;
													}
												else
													{
														imgList.push(img['imageShow'].replace(window.imagesLink, ''));
													}
											}
										
										mainThis.createProduct(imgList, false);
										
									}).catch(err => {});
							}
						else
							{
								imgList = [];
								for( let img of this.state.uploadImages ) { imgList.push(img['imageShow'].replace(window.imagesLink, '')); }
								mainThis.createProduct(imgList, false);
							}
					}
				else if ( product_id )
					{
						imgList = [];
						for( let img of this.state.uploadImages ) { imgList.push(img['imageShow'].replace(window.imagesLink, '')); }
						mainThis.createProduct(imgList, false);
					}
				else
					{
						this.setState({imageError:'Morate uploadovati bar jednu sliku', errorMessage: true, addingProduct: false});
						window.scrollTo(0, 0);
					}
				
				
			}
		
		deleteImage = (img) =>
			{
				var imageURL = this.state.uploadImages;
				if( imageURL.length == 0 )
					{
						this.setState({imageUploaded: false, uploadImages: []});
					}
				else
					{
						if( imageURL[img]['is_default'] == 1 )
							{
								if ( img < imageURL.length-1){ imageURL[img+1]['is_default'] = 1; }
								else { imageURL[0]['is_default'] = 1; } 
							}
						imageURL.splice(img, 1);
					}
	
				
				this.setState({
					uploadImages: imageURL,
				});
			}
			
		addSellerInformations = () =>
			{
				var allValid = true;
				this.setState({
						selling_first_name_error: '',
						selling_last_name_error:'',
						selling_address_error:'',
						selling_postal_code_error:'',
						selling_region_id_error:'',
						selling_city_id_error:'',
						selling_city_name_error:'',
						selling_phone_number_error:''
				});

				//first name validation 
				 if( !Util.checkParams(this.state.selling_first_name, 'name'))
					{
						this.setState({selling_first_name_error:'Ime mora sadržati više od 2 karaktera'});
						allValid = false;
					
					}
					
				// last name validation
				if( !Util.checkParams(this.state.selling_last_name, 'name'))
					{
						this.setState({selling_last_name_error:'Prezime mora sadržati više od 2 karaktera'});
						allValid = false;
						
					}
					

					
				// address validation
				if	( !Util.checkParams(this.state.selling_address, 'address'))
					{
						this.setState({selling_address_error:'Adresa mora sadržati više od 2 karaktera'});
						allValid = false;
						
					}
				
				// postal code validation
				if ( !Util.checkParams(this.state.selling_postal_code, 'postal'))
					{
						this.setState({selling_postal_code_error: 'Poštanski kod mora sadržati 5 cifara'});
						allValid = false;
					}
					
				
				
				//phone number validation
				if( !Util.checkParams(this.state.selling_phone_number, 'phone'))
					{
						this.setState({selling_phone_number_error:'Broj telefona nije pravilno upisan'});
						allValid = false;
						
					} 
					
				//location selected validation	
				if ( this.state.selling_region_id < 1)
					{
						this.setState({selling_region_id_error: 'Izaberite lokaciju'});
						allValid = false;
						
					}
				
				//city selection validation
				if ( this.state.selling_city_id < 1)
					{
						this.setState({selling_city_name_error:'Izaberite grad'});
						allValid = false;
						
					}
				if ( allValid == true )
					{
							var mainThis = this;
							axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
							axios.post(window.ajaxLink+'user/addSellerInformations',{
								selling_id: this.state.usersData.id,
								selling_first_name: this.state.selling_first_name,
								selling_last_name: this.state.selling_last_name,
								selling_address: this.state.selling_address,
								selling_postal_code: this.state.selling_postal_code,
								selling_region_id: this.state.selling_region_id,
								selling_city_id: this.state.selling_city_id,
								selling_phone_number: this.state.selling_phone_number,
							})
								.then(res => {
												if ( res['data'] && res['data']['responseData'] )
														{
															mainThis.setState({ selling_popup: false, usersData: res['data']['responseData']});
															localStorage.setItem('user_data', JSON.stringify(res['data']['responseData']));
														}
										
											}).catch(err => {
															Util.handleAjaxError(err, mainThis);
														});
					}
				
			}
		
		doCloseConfirmation = () =>
			{
				window.location = '/';			
				this.setState({selling_popup: false}); 
			}
		
		makeDefaultImage = (i) =>
			{
				var imgList = this.state.uploadImages;
				for ( let img in imgList )
					{
						imgList[img]['is_default'] = 0;
					}
				imgList[i]['is_default'] = 1;
				this.setState({uploadImages: imgList});
			}
		
		
		createProduct = (imageName, checkOnly) =>
			{
				var product_id = Util.getPath(2);
				
				var imagesList = [];
				if ( checkOnly == false )
					{
						for ( let img in this.state.uploadImages )
							{
								var item = this.state.uploadImages[img];
								item['url'] = imageName[img];
								item['imageShow'] = window.imagesLink+imageName[img].replace(window.imagesLink, '');
								item['imageFile'] = false;
								imagesList.push(item);
							}
						
						this.setState({uploadImages: imagesList}); 
					}
				
				this.setState({
					imageError: '',
					productNameError: '',
					productQuantityError: '',
					productExchangeError: '',
					productDetailedInfoError: '',
					productMoreInfoError: '',
					mainCategoryError: '',
					subCategoryError: '',
					subSubCategoryError: '',
					subSubSubCategoryError: '',
					productPriceError:'',
					registrationCodeError: '',
					errorMessage: false,
					product_usedError: '',
					used_error_message: false,
					product_delivery_error: '',
					delivery_error_message: false
				}); 
				
				var allValid = true;
				
				if( imagesList.length == 0 && ( !checkOnly || this.state.uploadImages.length == 0 ) )
					{
						this.setState({imageError: 'Morate uploadovati bar jednu sliku', errorMessage: true});
						window.scrollTo(0, 0);
						allValid = false;
					}
				
				if( this.state.productName.length < 5 )
					{
						this.setState({ productNameError:'Morate unijeti ime'});
						if ( this.state.imageError == '' && this.state.mainCategoryError == '' && this.state.subCategoryError == '' )
							{
								document.getElementById('productName').focus();
								this.setState({alreadyFocused: true});
							}
						allValid = false;
					}
				else if ( this.state.productName.length > 200 )
					{
						this.setState({ productNameError:'Ime ne može sadržati više od 200 karaktera'});
						if ( this.state.imageError == '' && this.state.mainCategoryError == '' && this.state.subCategoryError == '' )
							{
								document.getElementById('productName').focus();
								this.setState({alreadyFocused: true});
							}
						allValid = false;
					}
					
				if( !Util.checkParams(this.state.productQuantity, 'quantity'))
					{
						this.setState({ productQuantityError:'Morate unijeti količinu'});
						if ( this.state.imageError == '' && this.state.productNameError == '' )
							{
								document.getElementById('productQuantity').focus();
								this.setState({alreadyFocused: true});
							}
						allValid = false;
					}

				if ( parseInt(this.state.productQuantity) == 0 )
					{
						this.setState({ productQuantityError:'Količina mora biti veća od 0'});
						if ( this.state.imageError == '' && this.state.productNameError == '' )
							{
								document.getElementById('productQuantity').focus();
								this.setState({alreadyFocused: true});
							}
						allValid = false;
					}
						

				if ( this.state.mainCategory < 1 )
					{
						allValid = false;
						if ( this.state.imageError == '' )
							{
								document.getElementById('productCategory').focus();
								this.setState({alreadyFocused: true});
							}
						this.setState({ mainCategoryError:'Označite glavnu kategoriju'});
					}
				
				if ( this.state.subCategory < 1 )
					{
						this.setState({ subCategoryError:'Označite potkategoriju'});
						if ( this.state.imageError == '' )
							{
								document.getElementById('productCategory').focus();
								this.setState({alreadyFocused: true});
							}
						allValid = false;
					}
			
				if ( this.state.product_used == '' )
					{
						this.setState({ product_usedError:'Označite da li je vaš proizvod nov ili je već korišten', used_error_message: true});
						allValid = false;
						if ( this.state.imageError == '' && this.state.mainCategoryError == '' && this.state.subCategoryError == '' )
							{
								document.getElementById('animatedHolders').focus();
							}
					}
				if ( this.state.delivery_type == '' )
					{
						this.setState({ product_delivery_error:'Označite način na koji želite da se vaš proizvod isporuči', delivery_error_message: true});
						allValid = false;
						if ( this.state.imageError == '' && this.state.mainCategoryError == '' && this.state.subCategoryError == '' )
							{
								document.getElementById('animatedHolders').focus();
							}
					}
			
				 /* if ( this.state.registrationCode != 5 )
					{
						this.setState({ registrationCodeError: 'Registracioni kod mora sadržati 5 cifara' });
						allValid = false;
					} 
				 */
				//price validation
				if( !Util.checkParams(this.state.productPrice, 'quantity') )
					{
						this.setState({productPriceError:'Dodajte cijenu'});
						if ( this.state.productNameError == '' && this.state.productQuantityError == '' )
							{
								this.setState({alreadyFocused: false});
							}
						allValid = false;
					}
				if ( parseInt(this.state.productPrice) == 0 )
					{
						this.setState({ productPriceError:'Cijena mora biti veća od 0'});
						if ( this.state.productNameError == '' && this.state.productQuantityError == '' )
							{
								this.setState({alreadyFocused: false});
							}
						allValid = false;
					}
				
				var metaID = '';
				if ( this.state.subSubSubCategory ){ metaID = this.state.subSubSubCategory; }			
				else if ( this.state.subSubCategory ){ metaID = this.state.subSubCategory; }
				else if ( this.state.subCategory ){ metaID = this.state.subCategory;}
				else if ( this.state.mainCategory ){ metaID = this.state.mainCategory; }
				
				if ( !metaID )
					{
						allValid = false;
						this.setState({ mainCategoryError:'Došlo je do greške prilikom odabira kategorije'});
						if ( this.state.imageError == '' )
							{
								document.getElementById('productCategory').focus();
							}
					}
				
				if ( checkOnly ) { return allValid; }
				
				if ( allValid == true )	
					{
						var mainThis = this;
						axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
						axios.post(window.ajaxLink+'products/addProduct', {
									edit_product_id: product_id ? product_id : 0,
									user_id: this.state.usersData.id,
									images: imagesList, 
									productName: this.state.productName,
									productQuantity: this.state.productQuantity,	
									productDetailedInfo: this.state.productDetailedInfo,			
									productMoreInfo: this.state.productMoreInfo,
									productDelivery: this.state.delivery_type,
									productUsed: this.state.product_used,		
									price: this.state.productPrice,
									city_id: this.state.usersData.city_id,
									productCity: this.state.usersData.city,
									mainCategory:this.state.mainCategory,
									subCategory:this.state.subCategory,
									subSubCategory:this.state.subSubCategory,
									subSubSubCategory: this.state.subSubSubCategory,
									specificValues: this.state.inputs_list,
									specificCheckboxes: this.state.checkbox_list,
									meta_id: metaID,
									registration_code: this.state.registrationCode,
							}).then(response => {
										if ( response['data'] && response['data']['responseData']['success'] )
											{
												window.onbeforeunload = null;
												window.location.href = response['data']['responseData']['link'];
											}
										else
											{
												mainThis.setState({productNameError: 'Došlo je do greške prilikom kreiranja oglasa', addingProduct: false});
												if ( mainThis.state.imageError == '' )
													{
														document.getElementById('productName').focus();
													}
											}  
									
									}).catch(err => {
													Util.handleAjaxError(err, mainThis);
													mainThis.setState({addingProduct: false});
												}); 
					}
				else
					{
						this.setState({addingProduct: false});
					}
			} 

		checkFields = () => 
			{
				var product_id = Util.getPath(2);		
				this.setState({mainCategoryError: ''});
				var metaID = '';		
				if ( this.state.subSubSubCategory ) { metaID = this.state.subSubSubCategory; }
				else if ( this.state.subSubCategory ) { metaID = this.state.subSubCategory; }
				else if ( this.state.subCategory ) { metaID = this.state.subCategory; }
				else if ( this.state.mainCategory ) { metaID = this.state.mainCategory; }
				
				/*
				if ( this.state.subCategory == '' && this.state.subSubCategory !== '' ){ metaID = this.state.subCategory; this.setState({subSubSubCategory: '', subSubCategory: ''}); }
				if ( this.state.subSubCategory == '' && this.state.subSubSubCategory !== '' ){ metaID = this.state.subSubCategory; this.setState({subSubSubCategory: ''}); }
				*/
				
				if( metaID )
					{
						var mainThis = this;
							axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
							axios.post(window.ajaxLink+'products/getMainMeta', {user_id: this.state.usersData.id, mainCatMeta: metaID, product_id: product_id ? product_id : 0}).then(response => {
											if ( response['data'] && response['data']['responseData'])
												{
													mainThis.setState({ 
														checkbox_list: response['data']['responseData']['checkbox_arr'],
														inputs_list: response['data']['responseData']['inputs_arr'],

													});													
												}
											else
												{
													 mainThis.setState({productNameError: 'Došlo je do greške prilikom kreiranja oglasa'});
													 if ( mainThis.state.imageError == '' )
														{
															document.getElementById('productName').focus();
														}
												}  
										
										}).catch(err => {
														Util.handleAjaxError(err, mainThis); 
													}); 																
					}
			}
		
		refreshList = () =>
			{
				var clearList = [];
				this.setState({
									subCategory: '',
									subSubCategory: '',
									subSubSubCategory: '',
									checkbox_list: clearList, 
									inputs_list: clearList, 
									//oneInputVal: {}
				});
			}

		
		deliveryType = (dtype) =>
			{
				var newDtype = '';
				if ( dtype == 'shipping' && this.state.delivery_type == '' )
					{
						newDtype = 'shipping';
					}
				else if ( dtype == 'shipping' && this.state.delivery_type == 'shipping' )
					{
						newDtype = '';
					}
				else if ( dtype == 'shipping' && this.state.delivery_type == 'both' )
					{
						newDtype = 'in-person';
					}
				else if ( dtype == 'shipping' && this.state.delivery_type == 'in-person' )
					{
						newDtype = 'both';
					}
				else if ( dtype == 'in-person' && this.state.delivery_type == '' )
					{
						newDtype = 'in-person';
					}
				else if ( dtype == 'in-person' && this.state.delivery_type == 'in-person' )
					{
						newDtype = '';
					}
				else if ( dtype == 'in-person' && this.state.delivery_type == 'both' )
					{
						newDtype = 'shipping';
					}
				else if ( dtype == 'in-person' && this.state.delivery_type == 'shipping' )
					{
						newDtype = 'both';
					}
				
				this.setState({delivery_type: newDtype});
			}

		
		
		importAdOtherLinks = () =>
			{
				if ( !Util.checkParams(this.state.productLink, 'urlother') )
					{
						this.setState({productLinkError: 'Pogrešan link'});
						return false;
					}
				
				this.setState({addingProduct: true});
				axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
				axios.post(window.ajaxLink+'products/importurl',{url: this.state.productLink}).then(res => {
						if ( res['data'] && res['data']['responseData']['success'] && res['data']['responseData']['url'] )
							{
								window.onbeforeunload = null;
								window.location = res['data']['responseData']['url'];
							}
						else if ( res['data'] && res['data']['responseData']['error'] )
							{
								this.setState({productLinkError: res['data']['responseData']['error'], addingProduct: false});
							}
						else
							{
								this.setState({productLinkError: 'Pogrešan link', addingProduct: false});
							}
					}).catch(err => { this.setState({productLinkError: 'Pogrešan link', addingProduct: false}); });
			}
		
	render()
	{
		console.log(this.state.checkbox_list);
		return(
				<div className="new_product_page">
						
						<div className="new_product_section">
							<div className="product_section_header">
								<div className="new_product_title">{this.state.edit_product_id ? 'Zamijeni oglas' : 'Nova Prodaja'}</div>
								
								<Link to="/placanje"><div className="payment_warrning_buttonright">Uslovi za radnje</div></Link>
							</div>
							{/* !this.state.hasRegistrationCode &&
								<div className="section_container">
									<div className="info_card">Dodajte kod</div>
									<div className="animated_holder registration_code_holder">
											<InputAnimated type="phone" id="registrationCode"  title="Dodajte kod" fieldValue={this.state.registrationCode} fieldError={this.state.registrationCodeError} fieldName="registrationCode" updateChild={this.updateChild} />										
										</div>
								</div>
							*/}
							<div className="section_container">
								<div className="uploaded_image_container">
									<div className="uploaded_image_holder">
										{	
											this.state.uploadImages.map((oneImage, i) =>
												<div className="one_image_item" key={i}>
													<img key={i} src={oneImage.imageShow} className="uploaded_image_item" alt="" />
													{ oneImage.is_default == 1 && <div className="is_default_image">&#10004;</div> }
													<div className="addimage_options">
														<div className="make_default_image" onClick={()=> this.makeDefaultImage(i)}>&#10004;</div>
														<div className="delete_image_btn" onClick={()=> this.deleteImage(i)}>&times;</div>
													</div>
												</div>
											)
										}
									</div>
								</div>									
								<div className="product_info_upload">
									<input type="file" key={this.state.uploadedImages} className="file_upload" accept="image/*" onChange={this.selectImages} multiple />
									<div className="add_icon">+</div>
									<div className="file_text">Dodajte Sliku</div>
								</div>
									{
										this.state.errorMessage && <div className="form_field_error">{this.state.imageError}</div>
										
									}
							</div>
							
							<div id="productCategory" tabIndex="0">
								<NewProductCategory mainCategory={this.state.mainCategory} subCategory={this.state.subCategory} subSubCategory={this.state.subSubCategory} subSubSubCategory={this.state.subSubSubCategory} refreshList={this.refreshList} checkFields={this.checkFields} categoryList={this.state.categoryList} updateChild={this.updateChild} mainCategoryError={this.state.mainCategoryError} subCategoryError={this.state.subCategoryError} subSubCategoryError={this.state.subSubCategoryError} subSubSubCategoryError={this.state.subSubSubCategoryError} />
							</div>
							
							<div className="product_container product_left">
								<div className="section_container">
									<div className="info_card">Osnovne informacije</div>	
									<div className="animated_holder  animated_holder_left">
										<InputAnimated type="text" id="productName" title="Naziv" placeholder="npr. Audi A6" fieldValue={this.state.productName} fieldError={this.state.productNameError} fieldName="productName" updateChild={this.updateChild} />
									</div>	
									<div className="animated_holder animated_holder_left">
										<InputAnimated type="text" id="productQuantity" placeholder="Unesite brojevima količinu" title="Količina" fieldValue={this.state.productQuantity} fieldError={this.state.productQuantityError} fieldName="productQuantity" updateChild={this.updateChild} />										
									</div>
									
									<div id="animatedHolders" tabIndex="1">
										<div className="animated_holder animated_holder_left">
											<div className='checkbox_holder_block'>
												<div className="checkbox_label">Označite na koji način želite da dostavite proizvod kupcima</div>
												<div className={("checkbox_item checkbox_delivery" + (this.state.delivery_type == 'shipping' || this.state.delivery_type == 'both' ? ' checkbox_selected' : ''))} onClick={()=>{ this.deliveryType('shipping') }}>Dostava</div>
												<div className={("checkbox_item checkbox_inperson" + (this.state.delivery_type == 'in-person' || this.state.delivery_type == 'both' ? ' checkbox_selected' : ''))} onClick={()=>{ this.deliveryType('in-person') }}>Lično</div>
												{ this.state.delivery_error_message && <div className="product_used_error">{this.state.product_delivery_error}</div>}
											</div>
										</div>
										<div className="animated_holder animated_holder_left">
											<div className='checkbox_holder_block'>
												<div className="checkbox_label">Označite da li je proizvod nov ili je već korišten/polovan</div>
												<div className={("checkbox_item checkbox_itemnew" + (this.state.product_used == 'new-product' ? ' checkbox_selected' : ''))} onClick={()=>{ this.setState({product_used: 'new-product'}); }}>Nov</div>
												<div className={("checkbox_item checkbox_itemused" + (this.state.product_used == 'used-product' ? ' checkbox_selected' : ''))} onClick={()=>{ this.setState({product_used: 'used-product'}); }}>Korišten/Polovan</div>
												{ this.state.used_error_message && <div className="product_used_error">{this.state.product_usedError}</div>}
											</div>
										</div>
									</div>
									
									<div className="animated_holder animated_holder_left">
										<TextareatAnimated id="productDetailedInfo" title="Kratak opis" resize="vertical" fieldValue={this.state.productDetailedInfo} fieldError={this.state.productDetailedInfoError} fieldName="productDetailedInfo" updateChild={this.updateChild} />
									</div>
									
									<div className="animated_holder animated_holder_left">									
										<TextareatAnimated id="productMoreInfo" title="Dodatne informacije" resize="vertical" fieldValue={this.state.productMoreInfo} fieldError={this.state.productMoreInfoError} fieldName="productMoreInfo" updateChild={this.updateChild} callback={(val)=> {this.checkDetailedExtract(); }} />

									</div>
								</div>
							</div>
							
							<div id="priceTag" className="product_container product_right">
								<ProductPricetag updateChild={this.checkPriceValue}  productPrice={this.state.productPrice} productPriceError={this.state.productPriceError} alreadyFocused={this.state.alreadyFocused} />
							</div>
							
							
							
							{ ( this.state.inputs_list.length > 0 || this.state.checkbox_list.length > 0 ) && 
								<div className="section_container moreinfo_section">	
									<div className="info_card">Dodatne informacije</div>
										{
											this.state.inputs_list.map((oneItem, i)=>
												<div className="animated_holder" key={i}>
													{oneItem.select == true ?
														<SelectAnimated id={oneItem.id} title={oneItem.id}  fieldValue={oneItem.value} fieldName={oneItem.id} updateChild={(val) => {this.getInputValues(val,i)}}
														options_list= {oneItem[oneItem.id]} userId={oneItem.user_id} />
													:
														<InputAnimated type="text" id={oneItem.id}  title={oneItem.id} fieldName={oneItem.id} fieldValue={oneItem.value} updateChild={(val) => {this.getInputValues(val,i)}} />
													}
												</div>
											)
										}
									
										<div className="animated_holder for_checkboxes">
											{
												this.state.checkbox_list.map((oneItem, i) => 

													<div className={("checkbox_container" + (oneItem.checked ? ' checkbox_container_selected' : ''))} key={oneItem.id} onClick={ () => { this.getCheckboxValues(i) }}>
														<span>{oneItem.id}</span>
														<div key={i} className={'new_product_checkbox'+ (oneItem.checked ? ' blueType' : '')}></div>
													</div>
													
												)
											}
									</div>										
								</div>
							}
							
							<div className="btn btn_save" onClick={this.doUploadImages}>{ this.state.edit_product_id > 0 ? 'Sačuvaj Izmjene' : 'Objavi Oglas' }</div>
							{ this.state.selling_popup &&
								<div className="middle_popup">
									<div className="middle_popup_inside">
										<div className="middle_popup_close" onClick={ this.doCloseConfirmation }>&times;</div>
										<div className="middle_popup_body">
											<div className="middle_popup_title">Unesite podatke</div>
											<div className="buy_popup_field_container">
												<div className="buy_popup_field_title"></div>
												<div className="animated_input_container mr5">
													<InputAnimated type="text" id="selling_first_name" title="Ime" fieldValue={this.state.selling_first_name} fieldName="selling_first_name" fieldError={this.state.selling_first_name_error} updateChild={this.updateChild} />
												</div>
												<div className="animated_input_container ">
													<InputAnimated type="text" id="selling_last_name" title="Prezime" fieldValue={this.state.selling_last_name} fieldName="selling_last_name" fieldError={this.state.selling_last_name_error} updateChild={this.updateChild} />
												</div>
											
												<div className="animated_input_container mr5">
													<SelectAnimated id="selling_region_id" title="Lokacija" emptyText="Učitava lokacije..." fieldValue={this.state.selling_region_id} fieldName="selling_region_id" fieldError={this.state.selling_region_id_error} updateChild={this.updateChild} callback={this.updateLocationPick}
														options_list={[
																		{id: '0', name: '', disabled: true, default: true},
																		{id: '', style: {'color': 'inherit', 'background': '#ccc'}, name: 'Republika Srpska', disabled: false},
																		{id: '1', name: 'Banjalučka', disabled: false},
																		{id: '2', name: 'Dobojsko-Bijeljinska', disabled: false},
																		{id: '3', name: 'Sarajevsko-Zvornička', disabled: false},
																		{id: '4', name: 'Trebinjsko-Fočanska', disabled: false},
																		{id: '', style: {'color': 'inherit', 'background': '#ccc'}, name: 'Federacija BiH', disabled: true},
																		{id: '5', name: 'Sarajevo', disabled: false},
																		{id: '6', name: 'Unsko-sanski', disabled: false},
																		{id: '7', name: 'Posavski', disabled: false},
																		{id: '8', name: 'Tuzlanski', disabled: false},
																		{id: '9', name: 'Zeničko-dobojski', disabled: false},
																		{id: '10', name: 'Bosansko-podrinjski', disabled: false},
																		{id: '11', name: 'Srednjobosanski', disabled: false},
																		{id: '12', name: 'Hercegovačko-neretvanski', disabled: false},
																		{id: '13', name: 'Zapadno-hercegovački', disabled: false},
																		{id: '14', name: 'Livanjski', disabled: false},
																		{id: '15', name: 'Distrikt Brčko', disabled: false}
																	]}/>
												</div>
												<div className="animated_input_container">	
													<SelectAnimated id="selling_city_id" title={this.state.selling_cities_list.length == 0 ? "Prvo izaberite lokaciju" : "Grad"} fieldValue={this.state.selling_city_id} fieldName="selling_city_id" fieldError={this.state.selling_city_id_error} updateChild={this.updateChild} options_list={this.state.selling_cities_list}/>
												</div>
												<div className="animated_input_container mr5">
													<InputAnimated type="text" id="selling_address" title="Ulica i broj" placeholder="npr: Sarajevska 7, Sarajevo, 71000"  fieldValue={this.state.selling_address} fieldName="selling_address" fieldError={this.state.selling_address_error} updateChild={this.updateChild} />
												</div>
												<div className="animated_input_container">
													<InputAnimated type="text" id="selling_phone_number" title="Kontakt telefon" placeholder="npr: 0663314..."  fieldValue={this.state.selling_phone_number} fieldName="selling_phone_number" fieldError={this.state.selling_phone_number_error} updateChild={this.updateChild} />
												</div>
												<div className="animated_input_container  mr5">
													<InputAnimated type="text" id="selling_postal_code" title="Poštanski broj" placeholder="npr: 71000"   fieldValue={this.state.selling_postal_code} fieldName="selling_postal_code" fieldError={this.state.selling_postal_code_error} updateChild={this.updateChild} />
												</div>
												
												</div>
												<div className="contact_button1 buy_popup_submit" onClick={ this.addSellerInformations }>Sačuvaj</div>

										</div>	
									</div>
								</div>
							}
						</div>
						
						
						{ this.state.edit_product_id == 0 &&
						<div className="new_product_section">
							<div className="product_section_header">
								<div className="new_product_title">Uvezi oglas sa drugih sajtova</div>
							</div>
							
							<div className="product_container product_left">	
								<div className="animated_holder  animated_holder_left">
									<InputAnimated type="text" id="productLink" title="Link" placeholder="npr: https://www.olx.ba/artikal/46724..." fieldValue={this.state.productLink} fieldError={this.state.productLinkError} fieldName="productLink" updateChild={this.updateChild} />
								</div>
							</div>
							<div className="product_container product_right">
								<div className="btn btn_save" onClick={this.importAdOtherLinks}>Uvezi Oglas</div>
							</div>
						</div>
						}
						
						
					
					<div className={'loader_holder'+(this.state.addingProduct ? ' active' : '')}>
						<div className='loader_big'></div>
					</div>
				</div>
		)
	}
}

export default NewProduct;