import React, { Component } from 'react';

import axios from "axios";
import './Search.css';

import { Results } from '../../components/results/Results';
import { CustomFilter } from '../../components/customfilter/CustomFilter';

import Util from '../../util/util';

class Search extends Component {
	
	constructor(ttt)
	{
		super();
		this.state = {
						itemsList: [],
						topStores: [],
						promoted_products: [],
						filter: [],
						filterCheck: [],
						filterSearch: [],
						oneOption: [],
						mainCategory: 0,
						lastSearch: window.location.search,
						lastQuery: '',
						sortValue: 'Najsličniji',
						firstRun: true,
						enteredMobile: false,
						dropFilters: true,
						sortMenuOpen: false,
						loadingSearch: false,
					};
	}
  
	componentDidUpdate()
	{
		var nowPath = Util.getPath(2);
		if ( this.state.lastSearch !== window.location.search && !nowPath )
			{
				this.setState({filter: [], filterCheck: [], lastSearch: window.location.search}, () => { this.refreshPageSearch(); });
			}
	}
	
	componentDidMount()
	{
		document.title = this.props.pageTitle;
		this.refreshPageSearch();
		
		if(window.matchMedia('only screen and (max-width: 768px)').matches)
			{
				this.setState({enteredMobile: true, dropFilters: true});
			}
	}
	
	refreshPageSearch = () =>
	{
		var nowPath = Util.getPath(2);
		var nowPathSave = nowPath;
		var nowPath2 = Util.getPath(3);
		if ( !nowPath2 ) { nowPath2 = ''; }
		
		var nowPath0 = Util.getPath(1);
		if ( nowPath0 == 'k' )
			{
				nowPath = nowPath.replace(/-/g, ' ');
				nowPath2 = nowPath2.replace(/-/g, ' ');
			}
		
		var mainThis = this;
		if ( nowPath )
			{
				if ( this.state.firstRun )
					{
						var ret = Util.storagePreload(['category_'+nowPath+'_'+nowPath2, 'categoryf_'+nowPath+'_'+nowPath2, 'categoryf2_'+nowPath+'_'+nowPath2], 'json', [], false);
						this.setState({
										itemsList: ret['category_'+nowPath+'_'+nowPath2],
										promoted_products: ret['category_'+nowPath+'_'+nowPath2]
									});
						
						var savedList = localStorage.getItem('category_'+nowPath+'_'+nowPath2);
						if ( savedList ) { savedList = JSON.parse(savedList); }
						if ( savedList ) { this.setState({lastQuery: nowPath+' '+nowPath2, itemsList: savedList}); document.title = Util.firstLettersUppercase(decodeURI(nowPath2+' '+nowPath))+' na OPK.ba'; }
					}
				this.setState({loadingSearch: true});
				var oldFilters = [this.state.filter, this.state.filterCheck];
				
				axios.post(window.ajaxLink+'products/category', {category: decodeURI(nowPathSave), 'filter': nowPath2, customFilter: this.state.filterSearch, mainCategory: this.state.mainCategory, oldFilters: oldFilters, sort_value: this.state.sortValue})
						.then(res => {
							mainThis.setState({
												firstRun: false,
												loadingSearch: false,
												lastQuery: nowPath+' '+nowPath2,
												itemsList: res['data']['responseData']['products'],
												filter: res['data']['responseData']['filter'],
												filterCheck: res['data']['responseData']['filtercheck'],
												mainCategory: res['data']['responseData']['mainCategory'],
												promoted_products: res['data']['responseData']['promoted_products']});
							
							document.title = Util.firstLettersUppercase(decodeURI(nowPath2+' '+nowPath))+' na OPK.ba';
							
							localStorage.setItem('category_'+nowPath+'_'+nowPath2, JSON.stringify(res['data']['responseData']['products']));
						}).catch(err => { console.log(err); }
					); 
			}
		else
			{
				var params = new URLSearchParams(window ? window.location.search : {});
				this.setState({loadingSearch: true});
				axios.post(window.ajaxLink+'products/filter', {loc: params.get('l'), 'filter': params.get('q'), customFilter: this.state.filterSearch, mainCategory: this.state.mainCategory, oldFilters: [this.state.filter, this.state.filterCheck], sort_value: this.state.sortValue})
						.then(res => {
							document.title = Util.firstLettersUppercase(decodeURI(params.get('q')))+' - OPK.ba';
							mainThis.setState({
												firstRun: false,
												loadingSearch: false,
												lastQuery: params.get('q'),
												itemsList: res['data']['responseData']['products'],
												filter: res['data']['responseData']['filter'],
												filterCheck: res['data']['responseData']['filtercheck'],
												mainCategory: res['data']['responseData']['mainCategory'],
												topStores: res['data']['responseData']['topstores'],
												lastSearch: window.location.search});
						}).catch(err => { console.log(err); }
					);
			}
	}
	
	updateCustomFilter = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
		}
		
	 resetFilters = () =>
		{
			this.setState({ filterSearch: [], filter: [], filterCheck: [] , lastQuery: '',
			firstRun: true }, () => { this.refreshPageSearch() }); 
		} 
		
		
	updateDone = () =>
		{
			var filterQuery = [];
			for ( var itemf of this.state.filter )
				{
					if ( itemf['fromto'] )
						{
							if ( itemf['value'] && !itemf['value1'] )
								{
									filterQuery.push({'title': itemf['id'], 'option': [{'val': itemf['value'], 'dir': 'min'}]});
								}
							else if ( !itemf['value'] && itemf['value1'] )
								{
									filterQuery.push({'title': itemf['id'], 'option': [{'val': itemf['value1'], 'dir': 'max'}]});
								}
							else if ( itemf['value'] && itemf['value1'] )
								{
									filterQuery.push({'title': itemf['id'], 'option': [{'val': itemf['value'],'val1': itemf['value1'], 'dir': 'both'}]});
								}
						}
					else if ( itemf['value'] )
						{
							filterQuery.push({'title': itemf['id'], 'option': [itemf['value']]});
						}
				}
			
			var otherList = [];
			for ( itemf of this.state.filterCheck )
				{
					if ( itemf['checked'] )
						{
							otherList.push(itemf['id']);
						}
				}
			
			if ( otherList.length > 0 )
				{
					filterQuery.push({'title': 'Ostalo', 'option': otherList});
				}
			
			this.setState({filterSearch: filterQuery}, () => { this.refreshPageSearch() });
		}
	
	showFilters = () =>
		{
			this.setState({dropFilters: !this.state.dropFilters});
		}
	
	sortBy = (sortValue) =>
		{
			this.setState({sortValue: sortValue}, () => { this.refreshPageSearch() });
		}
	
	render()
	{
		return (
				<div className="search_page">
					<div className="see_filters_button" onClick={this.showFilters}>{this.state.dropFilters? "Prikaži sve filtere" : "Sakrij sve filtere"}</div>
					<div className={"page_1of4"+(this.state.dropFilters ? " search_page_hide_1of4" : '')} >
					{ this.state.filterSearch.length !== 0 &&
						<div className="filter_list_item">
							<div className="reset_btn" onClick={ this.resetFilters }>Resetuj</div>
						</div>
					}
						<CustomFilter currentList={this.state.filter} checkbox={false} updateCustomFilter={this.updateCustomFilter} callback={this.updateDone} />
						<CustomFilter currentList={this.state.filterCheck} checkbox={true} updateCustomFilter={this.updateCustomFilter} callback={this.updateDone} />
					</div>
					<div className="page_3of4">
					
					{ !this.state.loadingSearch && <Results promotedList={this.state.promoted_products} lastSearch={this.state.lastQuery} storesList={this.state.topStores} productsList={this.state.itemsList} sortMenu={this.sortMenu} sortMenuOpen={this.state.sortMenuOpen} sortValue={this.state.sortValue} sortBy={this.sortBy} /> }
					</div>
					<div className={'loader_holder'+(this.state.loadingSearch ? ' active' : '')}>
							<div className='loader_big'></div>
					</div>
				</div>
		);
	}
}

export default Search;