import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Util from '../../util/util';

import './SmallProduct.css';


export class SmallProduct extends Component {
	
	
	
	
	
	render(){
		return(
					<Link to={('/p/'+this.props.oneItem.link)}>
						<div className={"section_oneitem" + (this.props.homeItem == true ? " section_oneitem_home" : "")}>
							<div className="section_promoted" style= {{"display": (this.props.oneItem.is_promoted == 1 ? 'block' : 'none')}}>Promovisano</div>
							<div className="section_oneitem_image" style={{'backgroundImage': (this.props.oneItem.image ? 'url('+this.props.oneItem.image+')' : '')}}></div>
							<div className="section_oneitem_text">
								<div className="section_oneitem_textname">{this.props.oneItem.name}</div>
								<div className="section_oneitem_textprice">{Util.formatPrice(this.props.oneItem.price)}</div>
							</div>
							<div className="section_oneitem_ago">{Util.timeAgoGet(this.props.oneItem.time)}</div>
							<div className="section_oneitem_location">
								{(this.props.oneItem.location ? this.props.oneItem.location : <span className="unknown">Nepoznato</span>)}
							</div>
						</div>
					</Link>
		)
	}
}