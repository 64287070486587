import React, {Component} from 'react';

import './ProductPricetag.css';
import { InputAnimated } from '../../components/elements/InputAnimated';

class ProductPricetag extends Component{
	constructor()
		{
			super();
			this.state = {
				
			}
		}
	
	componentDidUpdate()
		{
			if ( this.props.productPriceError !== '' && !this.props.productPrice && this.props.alreadyFocused == false )
				{
					document.getElementById('productPrice').focus();
				}
		}
	
	render(){
		return(
			<div className="new_product_price">
				<div className="price_title">
					<span>Cijena</span>
					<div className="animated_input_description">
						<div className="description_icon"></div>
						<div className="description_icon_hover">Sve cijene su izražene u Konvertibilnim markama</div>
					</div>
				</div>
				<div className="price_container">
					<div className="price_input">
						<InputAnimated type="tel" id="productPrice" title="KM" fieldValue={this.props.productPrice} fieldName="productPrice" fieldError={this.props.productPriceError} updateChild={this.props.updateChild} />
					</div>
					<div className="currency">KM</div>
				</div>
			</div>
		)
	}
}

export default ProductPricetag;