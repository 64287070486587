import { Component } from 'react';
import './SellerRating.css';

import axios from "axios";

var lastUserId = 0;
var currentTab = '';
export class SellerRating extends Component {
	constructor()
		{
			super();
			this.state = {
							avg_overall: 0,
							avg_overall_percent: 0,
							total_count: 0,


							count_1: 0,
							count_2: 0,
							count_3: 0,
							count_4: 0,
							count_5: 0,
							count_1p: 0,
							count_2p: 0,
							count_3p: 0,
							count_4p: 0,
							count_5p: 0,
							profileData: {},
							
							loaded: false
				};
		}
	
	componentDidMount()
		{
			if ( this.props.userId )
				{
					this.getUserInfo(this.props.userId);
				}
		}
	
	getUserInfo = (userId) =>
	{
		lastUserId = userId;
		if ( !userId ) { return false; }
		
		var profileData = localStorage.getItem('profile_datar_'+userId);
		if ( profileData ) { profileData = JSON.parse(profileData); }
		this.setState({profileData: profileData ? profileData : {}});
		this.calculateStars(this.props);
		
		var mainThis = this;
		axios.post(window.ajaxLink+'user/info', {'userId': userId, 'rating': 1})
			.then(res => {
				
				if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['name'] )
					{
						mainThis.setState({profileData: res['data']['responseData'], loaded: true});
						localStorage.setItem('profile_datar_'+userId, JSON.stringify(res['data']['responseData']));
						mainThis.calculateStars(mainThis.props);
					}
				
				
				
			}).catch(err => {});
	}
	
	calculateStars = (nowProps) =>
		{
			currentTab = nowProps.currentTab;
		
			var userRating = nowProps.currentTab == 'seller' ? this.state.profileData.user_rating_seller : this.state.profileData.user_rating_buyer;
			if ( !userRating ) { userRating = []; }
			
			var avg_overall = 0;
			var avg_overall_percent = 0;
			var total_count = 0;
			
			var count_1 = 0;
			var count_2 = 0;
			var count_3 = 0;
			var count_4 = 0;
			var count_5 = 0;
			var count_1p = 0;
			var count_2p = 0;
			var count_3p = 0;
			var count_4p = 0;
			var count_5p = 0;
			
			if ( userRating && userRating.length > 0 )
				{
					var r = userRating[0];
					avg_overall = parseFloat(r.avg_overall).toFixed(1);
					avg_overall_percent = (parseFloat(r.avg_overall)/5)*100;
					
					total_count = r.total_count;
					
					var maxScore = Math.max(r.count_1, r.count_2, r.count_3, r.count_4, r.count_5);
					
					count_1 = r.count_1;
					count_1p = (parseFloat(r.count_1)/maxScore)*100;
					
					count_2 = r.count_2;
					count_2p = (parseFloat(r.count_2)/maxScore)*100;
					
					count_3 = r.count_3;
					count_3p = (parseFloat(r.count_3)/maxScore)*100;
					
					count_4 = r.count_4;
					count_4p = (parseFloat(r.count_4)/maxScore)*100;
					
					count_5 = r.count_5;
					count_5p = (parseFloat(r.count_5)/maxScore)*100;
				}
			
			this.setState({
							avg_overall: avg_overall,
							avg_overall_percent: avg_overall_percent,
							total_count: total_count,

							//after merge check 
							count_1: count_1,
							count_2: count_2,
							count_3: count_3,
							count_4: count_4,
							count_5: count_5,
							count_1p: count_1p,
							count_2p: count_2p,
							count_3p: count_3p,
							count_4p: count_4p,
							count_5p: count_5p
						});
		}
	
	
	UNSAFE_componentWillReceiveProps = (nextProps) =>
		{
			if ( nextProps.userId !== lastUserId )
				{
					this.getUserInfo(nextProps.userId);
				}
			else if ( nextProps.currentTab !== currentTab )
				{
					this.calculateStars(nextProps);
				}
		}
	
	render()
	{

		return (
				<div>{ this.state.total_count > 0 ?
					<div className="seller_rating_statistic_div">
						<div className="rating_statistic_title">OCJENE {(this.props.currentTab == 'seller' ? 'KUPACA' : 'PRODAVAČA')}</div>
						<div className="seller_rating_stat_left">
							<div className="seller_rsl_score">5</div><div className="seller_rsl_barshow"><div style={{'width': this.state.count_5p+'%'}}></div></div><div className="seller_rsl_count">{this.state.count_5}</div>
							<div className="seller_rsl_score">4</div><div className="seller_rsl_barshow"><div style={{'width': this.state.count_4p+'%'}}></div></div><div className="seller_rsl_count">{this.state.count_4}</div>
							<div className="seller_rsl_score">3</div><div className="seller_rsl_barshow"><div style={{'width': this.state.count_3p+'%'}}></div></div><div className="seller_rsl_count">{this.state.count_3}</div>
							<div className="seller_rsl_score">2</div><div className="seller_rsl_barshow"><div style={{'width': this.state.count_2p+'%'}}></div></div><div className="seller_rsl_count">{this.state.count_2}</div>
							<div className="seller_rsl_score">1</div><div className="seller_rsl_barshow"><div style={{'width': this.state.count_1p+'%'}}></div></div><div className="seller_rsl_count">{this.state.count_1}</div>
						</div>
						
						<div className="seller_rating_stat_right">
							<div className="seller_rsr_topscore">{this.state.avg_overall}</div>
							<div className="seller_rsr_stars"><div style={{'width': this.state.avg_overall_percent+'%'}}></div></div>
							<div className="seller_rsr_count">Broj ocjena: {this.state.total_count}</div>
						</div>
					</div> : <div className="noratingseller">{this.state.loaded ? 'Nema još uvjek ocjenu' : <div className="loader_small activeshow"></div>}</div> }
				</div>
		);
	}
}