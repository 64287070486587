import React, { Component } from 'react';
import './productImages.css';


class productImages extends Component {
	
	constructor()
		{
			super();
			this.state = {
							currentImage: 0,
							totalImages: 0,
							maxLeft: 0,
							sliderWidth: '100%',
							
							popupVisible: false,
							
							lastUserMouseX: 0
						}
		}
	
	
	componentDidMount()
	{
		this.recalculateSize(this.props);
		window.onresize = ()=>{ this.recalculateSize(this.props); };
	}
	
	componentWillUnmount()
	{
		window.onresize = null;
	}
	
	UNSAFE_componentWillReceiveProps = (nextProps) =>
	{
		this.recalculateSize(nextProps);
	}
	
	recalculateSize = (thisProps) =>
	{
		if ( thisProps.slides && thisProps.slides.length > 0 )
			{
				var maxLeft = (thisProps.slides.length*-120)+window.innerWidth-120;
				maxLeft = maxLeft < 0 ? maxLeft : 0;
				
				var sliderWidth = (thisProps.slides.length*120);
				sliderWidth = maxLeft < 0 ? sliderWidth+'px' : '100%';
				
				this.setState({sliderWidth: sliderWidth, maxLeft: maxLeft, totalImages: thisProps.slides.length});
			}
	}
	
	touchStarted = (e) =>
	{
		e.preventDefault();
		var t = e.changedTouches || e.touches || e.originalEvent.touches;
		this.setState({lastUserMouseX: t[0].clientX});
	}
	
	touchEnded = (e) =>
	{
		var t = e.changedTouches || e.touches || e.originalEvent.touches;
		var newX = t[0].clientX;
		var oldX = this.state.lastUserMouseX;
		if ( Math.abs(oldX-newX) > 20 )
			{
				if ( oldX > newX )
					{
						if ( this.state.currentImage < this.state.totalImages-1 ) { this.setState({currentImage: this.state.currentImage+1}); } else { this.setState({currentImage: 0}); }
					}
				else
					{
						if ( this.state.currentImage > 0 ) { this.setState({currentImage: this.state.currentImage-1}); } else { this.setState({currentImage: this.state.totalImages-1}); }
					}
			}
	}
	
	render(){
		
		return(
				<div>
					<div className="product_image">
						<div className="product_image_main" onClick={() => { this.setState({popupVisible: true}); }} style={{'backgroundImage': (this.props.image ? 'url('+this.props.image+')' : '')}}>
							<div>{this.state.totalImages}</div>
						</div>
					</div>
					
					{ this.state.popupVisible == true &&
					<div className="product_images_popup noselect">
						<div className="product_images_close" onClick={() => { this.setState({popupVisible: false}); }}>&times;</div>
						<div className="product_images_top_title">{(this.props.topTitle ? this.props.topTitle : '')}</div>
						<div className="product_large">
							<div className="product_large_image"
									onTouchStart={(e) => this.touchStarted(e)}
									onTouchEnd={(e) => this.touchEnded(e)}
									onClick={() => { if ( this.state.currentImage < this.state.totalImages-1 ) { this.setState({currentImage: this.state.currentImage+1}); } else { this.setState({currentImage: 0}); }}}
									style={{'backgroundImage': (this.props.slides && this.props.slides[this.state.currentImage] && this.props.slides[this.state.currentImage].image ? 'url('+this.props.slides[this.state.currentImage].image+')' : '')}}>
									
								<div className="product_large_left" onClick={(e) => { e.preventDefault(); e.stopPropagation(); if ( this.state.currentImage > 0 ) { this.setState({currentImage: this.state.currentImage-1}); } else { this.setState({currentImage: this.state.totalImages-1}); }}}>&#8249;</div>
								<div className="product_large_right" onClick={(e) => { e.preventDefault(); e.stopPropagation(); if ( this.state.currentImage < this.state.totalImages-1 ) { this.setState({currentImage: this.state.currentImage+1}); } else { this.setState({currentImage: 0}); }}}>&#8250;</div>
							</div>
						</div>
						<div className="product_images_list"  style={{'width': this.state.sliderWidth, 'left': (this.state.currentImage*-100 > this.state.maxLeft ? this.state.currentImage*-100 : this.state.maxLeft)+'px'}}>
							{
								this.props.slides.map((oneItem, j)=>(
									<div key={j} onClick={() => { this.setState({currentImage : j}); }}className={(this.state.currentImage == j ? 'active' : '')} style={{'backgroundImage': 'url('+oneItem.image+')'}}></div>
								))
							}
						</div>
					</div> }
			</div>
		)
	}																									
}

export default productImages