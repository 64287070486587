import { Component } from 'react';
import './Style.css';
import { SelectAnimated } from '../../components/elements/SelectAnimated';
import { FromToAnimated } from '../../components/elements/FromToAnimated';
import { InputAnimated } from '../../components/elements/InputAnimated';


export class MultiSelect extends Component {
	
	constructor()
		{
			super();
			this.state = {myOptions: [], openHide: false};
		}
	
	componentDidMount()
	{
		this.setState({myOptions: this.props.options_list, openHide: this.props.numb < 3 || this.props.itemTop > 0 });
	}
	
	doChangeClick = (j) =>
	{
		var oldList = this.state.myOptions;
		oldList[j]['checked'] = !oldList[j]['checked'];
		if ( this.props.callback )
			{
				this.props.callback(oldList);
			}
	}
	
	render()
	{
		return (
				<div className={"input_element_static"+(this.props.hasThisFilter || this.state.openHide ? ' showDownInput' : '')}>
					<div className="form_field_title form_field_title_top" onClick={() => { this.setState({openHide: !this.state.openHide}); }}>{this.props.title}</div>
					
					<div className="input_element_static_body">
						{ this.props.isCheckbox == true &&
							<div>
								{ this.state.myOptions.map((oneItem, j) =>
									<div key={j} className={'input_one_checkbox'+(oneItem.checked ? ' input_checked' : '')} onClick={() => { this.doChangeClick(j) }}>{oneItem.id}</div>
								)}
							</div>
						}
						
						{ this.props.isCheckbox == false && this.props.isSelect == true && this.props.options_list.length > 4 && this.props.forceCheck != true &&
							<div>
								<SelectAnimated id="" title="" fieldValue={this.props.fieldValue} fieldName={this.props.title} updateChild={this.props.callback} options_list={this.props.options_list}/>
							</div>
						}
						
						{ this.props.isCheckbox == false && this.props.isSelect == true && ( this.props.options_list.length <= 4 || this.props.forceCheck == true ) &&
							<div>
								{ this.props.options_list.map((oneItem, j) =>
									<div key={j} className={'input_one_checkbox'+(oneItem.id == this.props.fieldValue ? ' input_checked' : '')+(this.props.forceCheck == true ? ' forcenewlinef' : '')+(oneItem.isSubb == true ? ' forceSub' : '')} onClick={() => { this.props.callback({[this.props.fieldName]: oneItem.id == this.props.fieldValue ? '' : oneItem.id}); }}>{oneItem.id}</div>
								)}
							</div>
						}
						
						{ this.props.isCheckbox == false && this.props.isSelect != true && this.props.isFromTo != true &&
							<div className="from_to_container">
								<InputAnimated type="text" id="" title="" fieldValue={this.props.fieldValue}  fieldName={this.props.title} updateChild={this.props.callback} />	
							</div>
						}
						
						{ this.props.isCheckbox == false && this.props.isSelect != true && this.props.isFromTo == true &&
							<div className="from_to_container">
								<FromToAnimated type="text" id="" title="" fieldValue={this.props.fieldValue} fieldValue1={this.props.fieldValue1} fieldName={this.props.title} updateChild={this.props.callback} />	
							</div>
						}
						
						{ this.props.fieldError && <div className="form_field_error">{this.props.fieldError}</div> }
					</div>
				</div>
		);
	}
}