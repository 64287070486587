import { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

export class Footer extends Component {
	
	constructor()
	{
		super();
		this.state = { };
	}
	
	render()
	{
		return (
				<div className="footer">
					<Link to="/kontakt"><div className="contact_icon_footer"></div></Link>
				</div>
		);
	}
}