import React, { Component } from 'react';
import axios from "axios";
import Util from '../../util/util';
import { Link } from 'react-router-dom';

import './ForgotPassword.css';
import { InputAnimated } from '../../components/elements/InputAnimated';

class ForgotPassword extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: '',
			emailError: '',
			newPassword: '',
			newPasswordError:'',
			repeatedPassword:'',
			repeatedPasswordError: '',
			email_confirmed: false,
			pendingConfirmation: false,
			confirmCode: '',
			confirmMessage: '',
			resetSuccessful: false,
		}
	}
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
		}
	
	updateChild  = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
			
		}
	
	resendCode = () =>
		{
			var mainThis = this;
			axios.post(window.ajaxLink+'user/verifyEmail', { email: this.state.email })
			.then(res => {
				mainThis.setState({pendingConfirmation: true, confirmMessage: res['data']['responseData']['msg']});
			});
		}
	
	verifyConfirmCode = () =>
		{
			var codeVerify = this.state.confirmCode.trim().replace(/[^.\d]/g, '');
			codeVerify = codeVerify.substr(0, 5);
			var mainThis = this;
			axios.post(window.ajaxLink+'user/verifyCode', { email: this.state.email, confirmCode: codeVerify })
			.then(res => {
				 if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['success'] == 1)
					{
						mainThis.setState({ email_confirmed: true, confirmCode: codeVerify });	
					}
			});
			
		}
		
	doChangePassword = () =>
		{
			
			this.setState({
				emailError: '',
				newPasswordError: '',
				repeatedPasswordError: '',
				
			});
			
			var allValid = true;
			 //email validation
			if( !Util.checkParams(this.state.email, 'email'))
				{
					this.setState({ emailError:'Email nije pravilno upisan'});
					allValid = false;
				}

			if ( allValid == true )
				{
					if ( this.state.pendingConfirmation == false )
						{

							this.resendCode();
							allValid = false;
						}
				}
			
			if ( allValid == true && this.state.confirmCode.length < 5 )
				{
					this.setState({confirmMessage: 'Morate unijeti kod koji ste dobili na Vaš email.'});
					allValid = false;
				}
			if( allValid == true && !Util.checkParams(this.state.newPassword, 'password'))
				{
					this.setState({ newPasswordError:'Lozinka nije pravilno upisana'});
					allValid = false;
				}
				
			if( allValid == true && !Util.checkParams(this.state.repeatedPassword, 'password'))
				{
					this.setState({ repeatedPasswordError:'Ponovljena lozinka nije pravilno upisana'});
					allValid = false;
					
				}
			var pass1 = this.state.newPassword;
			var pass2 = this.state.repeatedPassword;
			
			if ( allValid == true && ( pass1 == '' || pass2 == '' || pass1 != pass2 ) ) 
				{
					this.setState({ repeatedPasswordError:'Ponovljena lozinka nije ista kao nova lozinka'});
					allValid = false;
				}
			
			if ( allValid == true && this.state.email_confirmed )
				{
					var mainThis = this;
					axios.post(window.ajaxLink+'user/changePassword', {
							email: this.state.email,
							newPassword: pass1,
							repeatedPassword: pass2,
							
						}).then(res => {
							 if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['success'] == 1)
								{
									mainThis.setState({ resetSuccessful: true, email_confirmed: false, pendingConfirmation: false,});	
								}
						}).catch(err => {
									Util.handleAjaxError(err, mainThis); 
								});
				}
		}
	
		
	render (){
		return(
				<div className="forgotpassword_page">
					<div className="forgotpassword_section">
						<div className="forgotpassword_header">
							<div className="forgotpassword_title">Zaboravili ste lozinku?</div>
						</div>
						
						<div className="fogotpassword_section_title">Unesite Vašu email adresu</div>
						<div className="forgotpassword_item">
							<div className="animated_input_holder">
								<InputAnimated type="text" id="password_email" title="Email" fieldName="email" fieldError={ this.state.emailError } fieldValue={this.state.email } updateChild={this.updateChild} />
								{this.state.pendingConfirmation && !this.state.email_confirmed &&
									<div className="register_confirm_email">
										Prije nego što nastavite dalje, morate unijeti KOD koji Vam je poslat na email:<br />
										<InputAnimated type="phone" id="register_confirmcode" title="Kod" placeholder="" fieldValue={this.state.confirmCode} fieldName="confirmCode" fieldError={this.state.confirmMessage} updateChild={(val, call) => { this.updateChild(val, this.verifyConfirmCode); }} />
										<div className="register_button" onClick={this.resendCode}>Niste dobili kod?</div>
									</div> }
							</div>
						</div>
							
						{ this.state.email_confirmed &&
							<div>
								<div className="fogotpassword_section_title">Unesite Vašu novu lozinku</div>
								<div className="forgotpassword_item">
									<div className="animated_input_holder">
										<InputAnimated type="password" id="change_pass" title="Vaša nova lozinka" fieldValue={this.state.newPassword} fieldError={this.state.newPasswordError} fieldName="newPassword" updateChild={this.updateChild} />
										<InputAnimated type="password" id="change_pass1" title="Ponovite lozinku" fieldValue={this.state.repeatedPassword} fieldName="repeatedPassword" fieldError={ this.state.repeatedPasswordError } updateChild={this.updateChild} />
									</div>
								</div>
							</div>
						}
						{ this.state.resetSuccessful && <div className="resetSuccessful">Vaša lozinka je uspješno resetovana. Vratite se na <Link to='/'><span>početnu</span></Link> ili se ulogujte sa novom lozinkom!</div> }
						{ this.state.resetSuccessful ? <Link to='/'><div className="forgotpassword_button">Vrati se na početnu</div></Link> : <div className="forgotpassword_button" onClick={this.doChangePassword}>Zamijeni lozinku</div> }
					</div> 
					
				</div>
		)
	}
}

export default ForgotPassword;