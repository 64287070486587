import React, { Component } from 'react';
import axios from "axios";

import './SellingList.css';

class SellingList extends Component {
	
	constructor()
		{ 
			super();
			this.state = {
							searchesList: [{search_term: 'iphone 12 telefon'}]
						};
		}
	
	
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
			
			axios.get(window.ajaxLink+'shared/globalSearch')
					.then(res => {
						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['list'] )
							{
								this.setState({searchesList: res['data']['responseData']['list']});
							}

					}).catch(err => {}
				);
		}
	
	
	render(){
		return (
			<div className="selllist_container">
				<div className="selllist_section_header">Popularne pretrage Prodaja - Kupovina</div>
				
				<div className="selllist_section_items">
					{ this.state.searchesList.map((sItem,i) => <div><a href={'/pretraga?q='+sItem.search_term}>Prodajem {sItem.search_term}</a><a href={'/pretraga?q='+sItem.search_term}>Kupujem {sItem.search_term}</a></div> )}
				</div>
			</div>
		)
	}
}

export default SellingList;