import React, { Component } from 'react';
import axios from "axios";
import Util from '../../util/util';

import './Static.css';

import { InputAnimated } from '../../components/elements/InputAnimated';
import { TextareatAnimated } from '../../components/elements/TextareatAnimated';

class Contact extends Component {
	
	constructor()
		{
			super();
			this.state = {
							staticName: '',
							staticEmail: '',
							staticMessage: '',
							nameError: '',
							emailError: '',
							responseMessageSuccess: ' ',
							responseMessageError: ' '
			};
		}
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
		}
	
	updateChild  = (val, callback) =>
	{
		if ( callback )
			{
				this.setState(val, callback);
			}
		else
			{
				this.setState(val);
			}
		
	}
	
	doSendMessage = () =>
		{
			this.setState({
								nameError: '',
								emailError: '',
								messageError: '',
								responseMessageSuccess: '',
								responseMessageError: ''
			});
			
			var allValid = true;
			
			if( !Util.checkParams(this.state.staticName, 'name'))
				{
					this.setState({nameError: 'Ime nije pravilno upisano'});
					allValid = false;
				}
			
			if( !Util.checkParams(this.state.staticEmail, 'emailphone'))
				{
					this.setState({emailError: 'Email ili broj telefona nisu pravilno upisani'});
					allValid = false;
				}
			
			if( !this.state.staticMessage )
				{
					this.setState({messageError: 'Poruka je obavezna.'});
					allValid = false;
				}
			
			if( this.state.staticMessage && this.state.staticMessage.length < 20 )
				{
					this.setState({messageError: 'Samo to? Napišite malo više o čemu se radi...'});
					allValid = false;
				}
			
			if( allValid == true )
				{
					var mainThis = this;
					axios.post(window.ajaxLink+'shared/contact', {name: this.state.staticName, email: this.state.staticEmail, message: this.state.staticMessage})
									.then(res => {
										
										if ( res['data']['responseData']['success'] )
											{
												mainThis.setState({responseMessageSuccess: res['data']['responseData']['successMessage'], staticMessage: '', staticEmail: '', staticName: ''});
											}
									}).catch(err => {
											Util.handleAjaxError(err, mainThis);
										});
				}
		}
	
	render()
	{
		return (
				<div className="static_page">
					<div className="static_page_title">Kontakt</div>
					<div className="static_page_subtitle">Ukoliko imate pitanje, sugestiju ili Vam treba pomoć oko naših usluga možete nas kontaktirati na broj telefona:<span> +387 65 315-654</span> <div>ili nam ostavite poruku:</div></div>
					
					<div className="static_contactform">
						<form onSubmit={this.doContact}>
							<InputAnimated type="text" id="static_form_name" title="Ime" fieldValue={this.state.staticName} fieldName="staticName" fieldError={this.state.nameError} updateChild={this.updateChild} />
							<InputAnimated type="text" id="static_form_email" title="Email ili Broj Telefona" fieldValue={this.state.staticEmail} fieldName="staticEmail" fieldError={this.state.emailError} updateChild={this.updateChild} />
							<TextareatAnimated type="text" id="static_form_message" title="Poruka" fieldValue={this.state.staticMessage} fieldName="staticMessage" fieldError={this.state.messageError} updateChild={this.updateChild} />
							
							<div className="send_button" onClick={this.doSendMessage}>POŠALJI PORUKU</div>
							<div className="static_text_small_green">{this.state.responseMessageSuccess}</div>
							<div className="static_text_small_red">{this.state.responseMessageError}</div>
						</form>
					</div>
				</div>
				
		);
	}
}

export default Contact;