import React, { Component } from 'react';
import axios from "axios";

import './Statistic.css';

class Statistic extends Component {
	
	constructor()
		{
			super();
			this.state = {
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
						};
		}
	
	componentDidMount()
		{
			document.title = this.props.pageTitle;
			
			if ( window['loadedHigh'] == undefined )
				{
					window['loadedHigh'] = document.createElement("script");
					window['loadedHigh'].async = false;
					window['loadedHigh'].src = "https://code.highcharts.com/highcharts.js";
					document.body.appendChild(window['loadedHigh']);
					
					window['loadedHigh2'] = document.createElement("script");
					window['loadedHigh2'].async = false;
					window['loadedHigh2'].src = "https://code.highcharts.com/themes/grid-light.js";
					document.body.appendChild(window['loadedHigh2']);
				}
			
			var mainThis = this;
			axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
			axios.get(window.ajaxLink+'user/statistic')
					.then(res => {
					
						if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['statistic'] )
							{
								var item;
								var seriesData = [];
								for ( item of res['data']['responseData']['statistic'] )
									{
										seriesData.push([new Date(item['visit_time']).getTime(), item['count']]);
									}
								
								var seriesData2 = [];
								for ( item of res['data']['responseData']['statistic_unique'] )
									{
										seriesData2.push([new Date(item['visit_time']).getTime(), item['count']]);
									}
								
								mainThis.createChart(seriesData, seriesData2);
							}
						
					}).catch(err => { }
				);
		}
			
			
			
		
	createChart(sdata, sdata2)
		{
			window.Highcharts.setOptions({lang: {
							months: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
							shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
							weekdays: ['Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota', 'Nedjelja'],
							resetZoom: 'Vrati na početak'
						}});
			
			window.Highcharts.chart('statistic_chart_container',
				{
					chart: {zoomType: 'x'},
					title: {text: 'Broj Posjeta Profila' },
					
					yAxis: [{
								title: {text: null},
								labels: {align: 'left', x: 3, y: 16, format: '{value:.,0f}'},
								showFirstLabel: false
							}],
					xAxis: {
								type: 'datetime',
								dateTimeLabelFormats: {month: '%e. %b', year: '%b'},
								gridLineWidth: 0,
								labels: {align: 'left', x: 3, y: -3},
								tickInterval: 3600 * 1000 * 24
							},
					legend: {align: 'left', verticalAlign: 'top', borderWidth: 0},
					tooltip: {shared: true, crosshairs: true, style: {"fontSize": "15px" }, headerFormat: '<b>{point.x:%e. %B %Y}</b><br>'},
					
					
					plotOptions2: {series: {label: { connectorAllowed: false }}},

					series: [{
								name: 'Sve Posjete Profila',
								data: sdata
							},
							{
								name: 'Jedinstvene Posjete Profila',
								data: sdata2
							}],
					
					
				});
		}
	
	render()
	{
		return (
				<div className="statistic_page">
					<div id="statistic_chart_container"></div>
				</div>
		);
	}
}

export default Statistic;