import { Component } from 'react';
import './CustomFilter.css';

import { MultiSelect } from '../../components/elements/MultiSelect';

export class CustomFilter extends Component {
	constructor()
		{
			super();
			this.state = {
							filter: {},
							filterError: ''
						};
		}
	
	updateCustomFilter = (val, i, sec, stopupdate) =>
	{
		if ( this.props.updateCustomFilter )
			{
				if ( i == -1 )
					{
						this.props.updateCustomFilter({filterCheck: val}, this.props.callback);
					}
				else
					{
						var oldList = this.props.currentList;
						if ( sec )
							{
								oldList[i]['value'] = val[oldList[i].id][0];
								oldList[i]['value1'] = val[oldList[i].id][1];
							}
						else
							{
								oldList[i]['value'] = val[oldList[i].id];
							}
						
						this.props.updateCustomFilter({filter: oldList}, stopupdate ? null : this.props.callback);
					}
			}			
	}
	
	
	render()
	{
		return (
				<div className="filter_list">
					{ this.props.checkbox == true && this.props.currentList && this.props.currentList.length > 0 ?
							<div className="filter_list_item filter_list_checkboxeslist">
								<MultiSelect
									id={'filter_left_side'}
									title='Ostalo ( Možete izabrati više )'
									isSelect={false}
									isFromTo={false}
									isCheckbox={true}
									fieldValue=""
									fieldValue1=""
									hasThisFilter={true}
									fieldName=""
									fieldError={this.state.filterError}
									options_list={this.props.currentList}
									callback={(val, sec, stopupdate) => { this.updateCustomFilter(val, -1, sec, stopupdate); }}
								/>
							</div>
						:
						this.props.currentList.map((item, i) =>
								<div className="filter_list_item" key={item.id}>
									<MultiSelect id={'filter_left_side_'+i} numb={i} title={item.id} itemTop={item.meta_top} forceCheck={item.forceCheck} hasThisFilter={false} isSelect={item.select} isFromTo={item.fromto} isCheckbox={this.props.checkbox} fieldValue={item.value} fieldValue1={item.value1} fieldName={item.id} fieldError={this.state.filterError} options_list={item[item.id]} callback={(val, sec) => { this.updateCustomFilter(val, i, sec); }}/>
								</div>
						)
					}
				</div>
		);
	}
}