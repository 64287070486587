import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';

window.ajaxLink = process.env.NODE_ENV == 'production' ? ( window.location.host == 'test.opk.ba' ? 'https://testapi.opk.ba/' : 'https://api.opk.ba/' ) : 'http://localhost:8001/';
window.imagesLink =  ( window.location.host == 'test.opk.ba' || window.location.host == 'localhost:3000' ? 'https://storage.googleapis.com/opk_media/images_temp/' : 'https://storage.googleapis.com/opk_media/images/' );
window.uploadProductImage =  window.ajaxLink+'products/uploadImagesProduct';

window.onscroll = function()
{
	var oldClassName = document.body.className.replace('scrollDown_900', '').replace('scrollDown_1200', '');
	if ( window.scrollY > 900 && window.innerWidth > 768 )  { oldClassName += ' scrollDown_900';  }
	if ( window.scrollY > 1200 && window.innerWidth > 768 ) { oldClassName += ' scrollDown_1200'; }
	
	document.body.className = oldClassName;
};


window.addBlur = function(queryElement)
{
	for ( var el of document.querySelectorAll(queryElement) )
		{
			el.className = el.className.replace('body_page_blur', '')+' body_page_blur';
		}
}

window.removeBlur = function(queryElement)
{
	if ( !queryElement ) { queryElement = '.body_page_blur'; }
	
	for ( var el of document.querySelectorAll(queryElement) )
		{
			el.className = el.className.replace(' body_page_blur', '');
		}
}


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

