import { Component } from 'react';
import { Link } from 'react-router-dom';
import './Chat.css';
import axios from "axios";

import { AddRating } from '../addrating/AddRating';
import Util from '../../util/util';

var lastChatId = 0;

export class Chat extends Component {

	constructor()
	{
		super();
		this.state = {
						textAreaHeight: 40,
						chatHeight: 'calc(100vh - 81px - 50px - 90px)',
						newMessage: '',
						listMessages: [],
						openAlready: false,
						currentProductInfo: {product_link: ''},
						currentUserInfo: {},
						usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {}
					};
	}

	reCalculateHeights(doScrollDown)
		{
			if ( document.getElementById('chat_test_div') )
				{
					var divHeight = document.getElementById('chat_test_div').offsetHeight;
					divHeight = divHeight < 160 ? divHeight < 40 ? 40 : divHeight : 160;
					
					if ( document.getElementById('chat_all') )
						{
							var chatTop = document.getElementById('chat_all').offsetTop;
							var chatHeight = (document.documentElement.clientHeight - chatTop) - divHeight - 10;
						}
					
					this.setState({textAreaHeight: divHeight, chatHeight: chatHeight+'px'}, () => { if ( doScrollDown && document.getElementById('chat_all') ) { document.getElementById('chat_all').scrollTo(0, 1000000000); } });
				}
		}

	componentDidMount()
		{
			this.reCalculateHeights(true);
			window.onresize = () => { this.reCalculateHeights(true); setTimeout(() => { window.scrollTo(0, 0); }, 5000);  };
			
			if ( this.state.usersData && this.state.usersData.id && this.state.usersData.token )
				{
					this.loadChatMessages(this.props, false);
					
					var mainThis = this;
					Util.addSSEventCallback('user_listening', 'chat_listen', function (data)
						{
							if ( data && data['update'] && data['update']['newMessage'] && mainThis.state )
								{
									mainThis.loadChatMessages(mainThis.props, true);
								}
						});
				}
		}
	
	UNSAFE_componentWillReceiveProps(nextProps)
		{
			if ( this.state.usersData && this.state.usersData.id && this.state.usersData.token )
				{
					if ( lastChatId !== nextProps.productChatId )
						{
							this.loadChatMessages(nextProps, false);
							lastChatId = nextProps.productChatId;
						}
				}
		}

	componentWillUnmount()
		{
			window.onresize = null;
		}
	
	loadChatMessages = (data, preloadForce) =>
		{
			if ( data.productChatId && data.otherUserId )
				{
					var onlyOrders = 0;
					if ( data.currentTab && data.currentTab == 'selling' ) { onlyOrders = 1; }
					
					
					var mainThis = this;
					var ret = Util.storagePreload(['chat_'+data.productChatId+'_'+data.otherUserId+'_'+onlyOrders, 'currentProductInfo_'+data.productChatId, 'currentUserInfo'+data.otherUserId], 'json', [], false);
					
					if ( !preloadForce )
						{
							mainThis.setState({
												listMessages: ret['chat_'+data.productChatId+'_'+data.otherUserId+'_'+onlyOrders],
												currentProductInfo: ret['currentProductInfo_'+data.productChatId],
												currentUserInfo: ret['currentUserInfo'+data.otherUserId]
											});
							axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
							axios.post(window.ajaxLink+'message/readMessages', {withProduct: data.productChatId, withUser: data.otherUserId, findOrders: onlyOrders}).then(res => { }).catch(err => { console.log(err); });
						}
					
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'message/getChat', {productId: data.productChatId, otherUserId: data.otherUserId, findOrders: onlyOrders})
							.then(res => {
								
								mainThis.setState({listMessages: res['data']['responseData']['messages'], currentProductInfo: res['data']['responseData']['productInfo'], currentUserInfo: res['data']['responseData']['userInfo']});
								
								localStorage.setItem('chat_'+data.productChatId+'_'+data.otherUserId+'_'+onlyOrders, JSON.stringify(res['data']['responseData']['messages']));
								localStorage.setItem('currentProductInfo_'+data.productChatId, JSON.stringify(res['data']['responseData']['productInfo']));
								localStorage.setItem('currentUserInfo'+data.otherUserId, JSON.stringify(res['data']['responseData']['userInfo']));
								
								mainThis.reCalculateHeights(true);
								
							}).catch(err => { console.log(err); });
				}
		}

	sendMessage = () =>
		{
			if ( this.state.newMessage !== '' )
				{
					document.getElementById('chat_input_text').focus();
					
					if ( this.props.productChatId && this.props.otherUserId )
						{
							var currentMessages = this.state.listMessages && this.state.listMessages[0] ? this.state.listMessages : [];
							currentMessages.push({
													'id':9999,
													'from_user': this.state.usersData.id,
													'to_user': this.state.currentUserInfo.id,
													'product_id': this.state.currentProductInfo.id,
													'message': this.state.newMessage,
													'message_read': 1,
													'message_time': '',
													'status': 1,
													'user_id': this.state.usersData.id,
													'username': this.state.usersData.username,
													'image': '',
													'product_name': this.state.currentProductInfo.name,
													'seller_id': this.state.currentProductInfo.user_id,
													'product_link': this.state.currentProductInfo.link,
													'sending': 1,
													'justnow': 1,
													'selling': 0
												});
							
							this.setState({listMessages: currentMessages}, () => { if ( document.getElementById('chat_all') ) { document.getElementById('chat_all').scrollTo(0, 1000000000); } });
							
							var mainThis = this;
							axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
							axios.post(window.ajaxLink+'message/sendMessage', {withUser: this.state.currentUserInfo.id, product_id: this.state.currentProductInfo.id, message: this.state.newMessage}).then(res =>
								{
									if ( mainThis.props.onSentCallback ) { mainThis.props.onSentCallback(); }
									
									mainThis.loadChatMessages(mainThis.props, true);
									
								}).catch(err => { console.log(err); });
						}
					
					
					this.setState({newMessage: ''}, () => { this.reCalculateHeights(); });
				}
		}
	
	acceptOrder = (mItem) =>
		{
			if ( mItem.order_id && !mItem.sending )
				{
					var mainThis = this;
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'message/confirmOrder', {orderId: mItem.order_id, withUser: this.state.currentUserInfo.id}).then(res => { mainThis.loadChatMessages(mainThis.props, true); }).catch(err => { console.log(err); });
				}
		}
	
	reportOrder = (mItem) =>
		{
			if ( mItem.order_id && !mItem.sending )
				{
					var mainThis = this;
					axios.defaults.headers.common['Authorization'] = this.state.usersData.token;
					axios.post(window.ajaxLink+'message/reportOrder', {orderId: mItem.order_id, withUser: this.state.currentUserInfo.id}).then(res => { mainThis.loadChatMessages(mainThis.props, true); }).catch(err => { console.log(err); });
				}
		}
	
	updateThisState = (st) =>
		{
			this.setState(st);
		}
	
	render()
	{
		return (
				<div>
					{ this.props.sendOnly != true && 
					<div>
						<div className="messages_topdiv">
							<div className="user_message_name">
							<div className="user_message_image" style={{'backgroundImage': (this.state.currentUserInfo.image ? 'url('+window.imagesLink+this.state.currentUserInfo.image+')' : '')}}></div><Link to={this.state.currentUserInfo.username ? ('/radnja/'+this.state.currentUserInfo.username): ''}>{this.state.currentUserInfo.username}&nbsp;</Link></div>
							{ this.props.fromProduct != true && this.state.currentProductInfo.product_link && <div className="user_message_product">
								{ this.state.currentProductInfo.user_id != this.state.usersData.id && <Link to={this.state.currentProductInfo.product_link}><div className="chat_smallbutton2">Kupi</div></Link> }
								<Link to={this.state.currentProductInfo.product_link}>{this.state.currentProductInfo.product_name}</Link>
							</div> }
						</div>
						<div className={'chat_main'+(this.state.newMessage.length > 0 ? ' has_text' : '')}>
							<div id="chat_all" className="chat_all" style={{'height': this.state.chatHeight}}>
								{ this.state.listMessages.map((oneItem, j) =>
										<div className={'chat_one'+(oneItem.sending ? ' chat_one_sent' : '')+(oneItem.justnow ? ' chat_one_justsent' : '')+(j > 0 && oneItem.order_id == 0 && this.state.listMessages[j-1].order_id == 0 && oneItem.from_user == this.state.listMessages[j-1].from_user && Util.minutesDiff(oneItem.message_time, this.state.listMessages[j-1].message_time) < 30 ? ' chat_same_user' : '')+(this.state.listMessages[j+1] && oneItem.from_user == this.state.listMessages[j+1].from_user && Util.minutesDiff(this.state.listMessages[j+1].message_time, oneItem.message_time) < 30 ? ' chat_nextsame_user' : '')} key={j}>
											{
												oneItem.order_id > 0 ?
													<div className="user_chat_text user_chat_order">
														<div className="chat_order">
															<div className="chat_order_title">{oneItem.sending ? 'Tvoja' : 'Nova'} porudžbina<div>{Util.dateTimeFormat(oneItem.message_time)}<br />{Util.timeAgoGet(oneItem.message_time)}</div></div>
															
															{ this.state.currentProductInfo.product_link ?
															<div className="chat_order_info"><div>Oglas:</div> <span><Link to={this.state.currentProductInfo.product_link}>{this.state.currentProductInfo.product_name}</Link></span></div>
															:
															<div className="chat_order_info"><div>Oglas:</div> <span>Nije dostupan</span></div>
															}
															<div className="chat_order_info"><div>Ime i prezime:</div> <span>{oneItem.order_first_name} {oneItem.order_last_name}</span></div>
															<div className="chat_order_info"><div>Adresa:</div> <span>{oneItem.order_address}</span></div>
															<div className="chat_order_info"><div>Grad:</div> <span>{oneItem.order_city}</span></div>
															<div className="chat_order_info"><div>Poštanski broj:</div> <span>{oneItem.order_zip_code}</span></div>
															<div className="chat_order_info"><div>Broj tel:</div> <span>{oneItem.order_phone}</span></div>
															<div className="chat_order_info"><div>Količina:</div> <span>{oneItem.order_quantity}</span></div>
															
															<div className="chat_order_info">Dodatne informacije:</div>
														</div>
														<b>{oneItem.message.split('\n').map((str, l) => <span key={l}><br />{str}</span>)}</b>
														
														{ this.state.currentUserInfo.id == null && <div className="order_notverified">Ovaj korisnik nema nalog na OPK.ba i nije verifikovao svoje podatke. Pažljivo sa ovom porudžbinom.</div> }
														
														
														{ this.state.currentProductInfo.product_link ?
															<div>
																{ oneItem.sending == false && oneItem.order_accepted != 1 && oneItem.order_reported != 1 ?
																	<div className="chat_order_buttons">
																		<div className="chat_order_accept" onClick={() => { this.acceptOrder(oneItem); }}>Prihvati Porudžbinu</div>
																		<div className="chat_order_report" onClick={() => { this.reportOrder(oneItem); }}>Prijavi Zloupotrebu</div>
																	</div>
																	:
																	<div className="chat_order_buttons">
																		{
																			oneItem.order_accepted ?
																				<div>
																					Prihvaćena porudžbina
																					<br />
																					<br />
																					{ this.state.currentUserInfo.id != null && oneItem.review_added_buy == 0 && oneItem.sending == true && <div className="chat_order_accept" onClick={() => { this.setState({rateSellBuy: 'seller', openAlready: true, openOrderId: oneItem.order_id}) }}>Ocijeni Kupovinu</div> }
																					{ this.state.currentUserInfo.id != null && oneItem.review_added_sell == 0 && oneItem.sending == false && <div className="chat_order_accept" onClick={() => { this.setState({rateSellBuy: 'buyer', openAlready: true, openOrderId: oneItem.order_id}) }}>Ocijeni Prodaju</div> }
																				</div>
																			:
																				'Čekamo odgovor...'
																		}
																	</div>
																}
															</div> : ''
														}
													</div>
												:
													<div>
														{ oneItem.sending != 1 && <div className="user_chat_image" style={{'backgroundImage': (oneItem.image ? 'url('+window.imagesLink+oneItem.image+')' : '')}}></div> }
														<div className="user_chat_text">{oneItem.message.split('\n').map((str, l) => <span key={l}><br />{str}</span>)}<div className="user_chat_time" title={Util.dateTimeFormat(oneItem.message_time)}>{(oneItem.justnow ? 'šalje se...' : Util.timeAgoGet(oneItem.message_time))}</div></div>
													</div>
											}
										</div>
									)}
							</div>
							
							{ this.state.currentUserInfo.id != null && this.state.currentProductInfo.product_link && <div>
								{
									this.props.currentTab !== 'selling' ?
											<div className="chat_bottom_div">
												<textarea id="chat_input_text" style={{'height': this.state.textAreaHeight+'px'}} placeholder="Počni pisati poruku..." value={this.state.newMessage} onChange={(event) => this.setState({newMessage: event.target.value}, () => { this.reCalculateHeights(); })}></textarea>
												<div className="chat_send_button" onClick={() => { this.sendMessage(); }}></div>
												<div className="hidepixel"><div id="chat_test_div">{this.state.newMessage}&nbsp;</div></div>
											</div>
									:
										<div className="chat_bottom_div">
											<div className="chat_smallbutton" onClick={() => { this.props.switchTabInbox(()=>{ this.loadChatMessages(this.props, true); });  }}>Otvori sve poruke</div>
										</div>
								}
							</div> }
						</div>
					</div> }
					
					{ this.props.sendOnly == true && 
					<div className={'chat_main'+(this.state.newMessage.length > 0 ? ' has_text' : '')}>
						<div className="chat_bottom_div">
							<textarea id="chat_input_text" style={{'height': this.state.textAreaHeight+'px'}} placeholder="Počni pisati poruku..." value={this.state.newMessage} onChange={(event) => this.setState({newMessage: event.target.value}, () => { this.reCalculateHeights(); })}></textarea>
							<div className="chat_send_button" onClick={() => { this.sendMessage(); }}></div>
							<div className="hidepixel"><div id="chat_test_div">{this.state.newMessage}&nbsp;</div></div>
						</div>
					</div> }
					
					{ this.state.openAlready == true && <AddRating currentTab={this.state.rateSellBuy} userId={this.state.currentUserInfo.id} openAlready={this.state.openAlready} orderId={this.state.openOrderId} updateParent={this.updateThisState} /> }
					
				</div>
		);
	}
}