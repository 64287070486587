import { Component } from 'react';
import './UserRating.css';

import { OneRating } from '../ratings/OneRating';
import { AddRating } from '../addrating/AddRating';
import { SellerRating } from '../sellerrating/SellerRating';

export class UserRating extends Component {
	
	constructor()
		{
			super();
			this.state = {
							currentTab: 'seller',
						};
		}
	
	render()
	{

		var userRating = this.state.currentTab == 'seller' ? this.props.ratingListSeller : this.props.ratingListBuyer;
		if ( !userRating ) { userRating = []; }
		
		var total_sell = this.props.ratingListSeller && this.props.ratingListSeller[0] && this.props.ratingListSeller[0].total_count ? this.props.ratingListSeller[0].total_count : 0;
		var total_buy = this.props.ratingListBuyer && this.props.ratingListBuyer[0] && this.props.ratingListBuyer[0].total_count ? this.props.ratingListBuyer[0].total_count : 0;
		
		var avg_desc = 0;
		var avg_shipfast = 0;
		var avg_shipprice = 0;
		var avg_msgfast = 0;
		var avg_agree = 0;
		if ( userRating && userRating.length > 0 )
			{
				var r = userRating[0];
				avg_desc = parseFloat(r.avg_desc).toFixed(1);
				avg_shipfast = parseFloat(r.avg_shipfast).toFixed(1);
				avg_shipprice = parseFloat(r.avg_shipprice).toFixed(1);
				avg_msgfast = parseFloat(r.avg_msgfast).toFixed(1);
				avg_agree = parseFloat(r.avg_agree).toFixed(1);
			}
		return (
				<div className="sections_list rating_list">
					<div className="rating_tabs">
						<div className={(this.state.currentTab == 'seller' ? 'active' : '')} onClick={()=> { this.setState({currentTab: 'seller'}); }}>KAO PRODAVAC ({total_sell})</div>
						<div className={(this.state.currentTab == 'buyer' ? 'active' : '')} onClick={()=> { this.setState({currentTab: 'buyer'}); }}>KAO KUPAC ({total_buy})</div>
					</div>
					<div className="rating_statistic">
						<div className="rating_statistic_div">

							<SellerRating currentTab={this.state.currentTab} userId={this.props.userId} />
						</div>
						
						{ this.state.currentTab == 'seller' &&
						<div className="rating_statistic_div">
							<div className="rating_statistic_title">DETALJNO OD KUPACA</div>
							
							<div className="rating_statistic_info"><div className="rsi_title">Proizvod kao u opisu</div><div className="rating_stars">{avg_desc}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Brzina dostave</div><div className="rating_stars">{avg_shipfast}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Cijena dostave</div><div className="rating_stars">{avg_shipprice}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Brzina odgovora na poruke</div><div className="rating_stars">{avg_msgfast}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Brzina dogovora</div><div className="rating_stars">{avg_agree}</div></div>
						</div> }
						
						{ this.state.currentTab == 'buyer' &&
						<div className="rating_statistic_div">
							<div className="rating_statistic_title">DETALJNO OD PRODAVAČA</div>
							
							<div className="rating_statistic_info"><div className="rsi_title">Uredno plaćanje</div><div className="rating_stars">{avg_desc}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Tačna adresa</div><div className="rating_stars">{avg_shipfast}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Tačan telefon</div><div className="rating_stars">{avg_shipprice}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Brzina dogovora</div><div className="rating_stars">{avg_agree}</div></div>
							<div className="rating_statistic_info"><div className="rsi_title">Brzina odgovora na poruke</div><div className="rating_stars">{avg_msgfast}</div></div>
						</div> }
					</div>
					
					<AddRating currentTab={this.state.currentTab} userId={this.props.userId} profileName={this.props.profileName} />
					
					<div className="rating_comments">
						{ userRating.map((oneItem, j) =>
								<OneRating key={j} comment={oneItem} isLoggedIn={this.props.isLoggedIn} commentId={oneItem.id} reportComment={this.props.reportComment} />
							)}
					</div>
				</div>
		);
	}
}