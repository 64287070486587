import React, { Component } from 'react';
import axios from "axios";

import Util from '../../util/util';
import './Profile.css';

import { Results } from '../../components/results/Results';
import { UserRating } from '../../components/userrating/UserRating';
import { TextareatAnimated } from '../../components/elements/TextareatAnimated';
import { Link } from 'react-router-dom';


var lastUserLink = '';
var lastLoadTimeout = 0;
class Profile extends Component {
	
	constructor()
		{
			super();
			
			this.state = {
							usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
							profileData: {},
							sellList: [],
							soldList: [],
							sellListLength: 0,
							soldListLength: 0,
							sellCategories: [],
							soldCategories: [],
							ratingList: [],
							currentTab: 'active',
							currentCategory: 'Sve Kategorije',
							currentSearch: '',
							profileExtend: 1,
							staticMessage: '',
							messageError: '',
							myProfile: false,
							loggedIn: false,
							reportOpen: false,
							reportedComments: [],
							todayWeekDay: (new Date()).getDay() == 0 ? 7 : (new Date()).getDay()
						};
		}
  
	componentDidMount()
		{
			this.loadAllUser(this.props);
		}
	
	extendSearch = () =>
		{
			var checkLength = 0;
			if ( this.state.currentTab == 'active' )
				{
					checkLength = this.state.sellListLength;
				}
			else if ( this.state.currentTab == 'sold' )
				{
					checkLength = this.state.soldListLength;
				}
			if ( (this.state.profileExtend-1)*40 < checkLength )
				{
					this.setState({profileExtend: this.state.profileExtend + 1});
					this.loadNewProducts(this.state.currentTab, this.state.currentCategory, this.state.currentSearch);
				}
		}
	
	
	UNSAFE_componentWillReceiveProps(nextProps)
		{
			var userName = '';
			if ( nextProps.myprofile ) { userName = this.state.usersData.name; }
			else
				{
					var nowPath = Util.getPath(2);
					if ( nowPath ) { userName = nowPath; }
				}
			
			if ( userName )
				{
					if ( lastUserLink !== userName )
						{
							this.loadAllUser(nextProps);
							lastUserLink = userName;
						}
				}
		}
	
	loadAllUser = (nextProps) =>
		{
			var userName = '';
			if ( nextProps.myprofile ) { userName = this.state.usersData.name; }
			else
				{
					window.scrollTo(0, 0);
					var nowPath = Util.getPath(2);
					if ( nowPath ) { userName = nowPath; }
				}
			
			if ( userName )
				{
					userName = userName.toLowerCase();
					
					lastUserLink = userName;
					var loggedIn = false;
					if ( this.state.usersData.name ) { loggedIn = true; }
					
					var isMyProfile = false;
					if ( this.state.usersData && this.state.usersData.name && userName.toLowerCase() == this.state.usersData.name.toLowerCase() ) { isMyProfile = true; }
					
					var ret = Util.storagePreload(['products_sell_store_'+userName, 'products_sold_store_'+userName, 'products_sell_cat_store_'+userName, 'products_sold_cat_store_'+userName, 'products_sell_len_'+userName, 'products_sold_len_'+userName], 'json', [], false);
					
					this.setState({
								sellList: ret['products_sell_store_'+userName],
								soldList: ret['products_sold_store_'+userName],
								sellCategories: ret['products_sell_cat_store_'+userName],
								soldCategories: ret['products_sold_cat_store_'+userName],
								sellListLength: ret['products_sell_len_'+userName] ? ret['products_sell_len_'+userName] : 0,
								soldListLength: ret['products_sold_len_'+userName] ? ret['products_sold_len_'+userName] : 0
							});
					
					var profileData = localStorage.getItem('profile_data_'+userName);
					if ( profileData ) { profileData = JSON.parse(profileData); }
					if ( !profileData && isMyProfile ) { profileData = this.state.usersData; }
					
					var currentTabHref = 'active';
					if ( window.location.hash == '#opis' ) { currentTabHref = 'about'; }
					if ( window.location.hash == '#prosli' ) { currentTabHref = 'sold'; }
					if ( window.location.hash == '#ocjene' ) { currentTabHref = 'rating'; }
					if ( window.location.hash == '#radnovrijeme' ) { currentTabHref = 'hours'; }
					
					document.title = userName+nextProps.pageTitle;

					this.setState({myProfile: isMyProfile, loggedIn: loggedIn, currentTab: currentTabHref, profileData: profileData ? profileData : {}});
					
					
					var mainThis = this;
					axios.post(window.ajaxLink+'user/info', {'user': userName})
							.then(res => {
								
								if ( res['data'] && res['data']['responseData'] && res['data']['responseData']['name'] )
									{
										mainThis.setState({profileData: res['data']['responseData']});
										localStorage.setItem('profile_data_'+userName, JSON.stringify(res['data']['responseData']));
										mainThis.loadNewProducts(currentTabHref, 'Sve Kategorije');
									}
							}).catch(err => {}
						);
				}
		}
	
	loadNewProducts = (currentTab, currentCat, currentSrc) =>
		{
			var mainThis = this;
			var userName = lastUserLink;
			userName = userName.toLowerCase();
			
			var userId = this.state.profileData.id;
			
			axios.post(window.ajaxLink+'products/user', {'user': userId, extend_limit: this.state.profileExtend, currentTab: currentTab, currentCategory: currentCat, currentSearch: currentSrc, sellCategories: this.state.sellCategories, soldCategories: this.state.soldCategories})
				.then(res => {
					
					mainThis.setState({sellList: res['data']['responseData']['sell_list'], soldList: res['data']['responseData']['sold_list'], sellCategories: res['data']['responseData']['sell_categories'], soldCategories: res['data']['responseData']['sold_categories'], sellListLength: res['data']['responseData']['sellLength'], soldListLength: res['data']['responseData']['soldLength']});
					
					localStorage.setItem('products_sell_store_'+userName, JSON.stringify(res['data']['responseData']['sell_list']));
					localStorage.setItem('products_sold_store_'+userName, JSON.stringify(res['data']['responseData']['sold_list']));
					localStorage.setItem('products_sell_cat_store_'+userName, JSON.stringify(res['data']['responseData']['sell_categories']));
					localStorage.setItem('products_sold_cat_store_'+userName, JSON.stringify(res['data']['responseData']['sold_categories']));
					localStorage.setItem('products_sell_len_'+userName, JSON.stringify(res['data']['responseData']['sellLength']));
					localStorage.setItem('products_sold_len_'+userName, JSON.stringify(res['data']['responseData']['soldLength']));
					
					if ( mainThis.state.sellListLength == 0 && mainThis.state.soldListLength == 0 && mainThis.state.myProfile == false )
						{
							mainThis.setState({currentTab: 'rating'});
						}
					
				}).catch(err => {}
			);
		}
	
	updateChild  = (val, callback) =>
		{
			if ( callback )
				{
					this.setState(val, callback);
				}
			else
				{
					this.setState(val);
				}
			
		}

	setCategory = (currentCat) =>
		{
			this.setState({currentCategory: currentCat, profileExtend: 1});
			this.loadNewProducts(this.state.currentTab, currentCat);
		}

	doSearch = (currentSrc) =>
		{
			this.setState({currentSearch: currentSrc, profileExtend: 1});
			
			if ( lastLoadTimeout ) { clearTimeout(lastLoadTimeout); }
			lastLoadTimeout = setTimeout(() => {
													this.loadNewProducts(this.state.currentTab, this.state.currentCategory, currentSrc);
										}, 2000);
		}
	
	reportUser = () =>
		{
			this.setState({reportOpen: true});
			window.addBlur('.profile_topImage, .profile_info, .header');
		}
	
	closeReport = () =>
		{
			this.setState({reportOpen: false});
			window.removeBlur('');
		}
	
	doReportUser = () =>
		{
			var mainThis = this;
			var messageTrue = true;
			var staticMessage = this.state.staticMessage.trim();
			
			if ( !Util.checkParams(staticMessage, 'txtAreaRegEx'))
				{
					messageTrue = false;
					this.setState({messageError: 'Prijava nije ispravno upisana'});
				}
			
			if ( messageTrue == true )
				{
					axios.post(window.ajaxLink+'user/report', { 
					
						from_user: this.state.usersData.id, 
						to_user: this.state.profileData.id, 
						message: staticMessage
					}).then(res => {
							if( res['data']['responseMessage'] == 'success' )
								{
									mainThis.setState({reportOpen: false, staticMessage: ''});
									Util.showPopupDialog(
															'Potvrda prijave',
															'Prijava korisnika je uspješno izvršena',
															{title: 'U redu', css: 'profile_report_confirm', callback: () => { }},
									);
								}
					}).catch(err => { console.log(err);} );
				}
		}
	
	reportComment = (commentId) =>
		{
			Util.showPopupDialog(
									'Potvrdite Prijavu',
									'Da li ste sigurni da želite da prijavite ovaj komentar?',
									{title: 'Prijavi', css: 'middle_popup_blue_button', callback: () => { this.doReportComment(commentId); }},
									{title: 'Prekini', css: 'middle_popup_yellow_button', callback: () => { }},
									() => { },
									'warning_popup');
		}
	
	doReportComment = (commentId) =>
		{
			var mainThis = this;
			if ( commentId !== 0 )
				{
					axios.post(window.ajaxLink+'user/reportComment', { 
					
						user_id: this.state.usersData.id,
						comment_id: commentId 
					}).then(res =>{
						
						if ( res['data']['responseMessage'] == 'success' )
							{
								mainThis.loadAllUser(mainThis.props);
							}
						
					}).catch(err => { console.log(err); } );
				}
		}
	
	copyMyLink = () =>
		{
			var copyText = document.getElementById("copymylinkid");
			copyText.select();
			copyText.setSelectionRange(0, 99999);
			navigator.clipboard.writeText(copyText.value);
		}
	
	render()
	{
		return (
				<div className="profile_page">
				{ this.state.loggedIn && this.state.myProfile && this.state.profileData.isTrial && this.state.profileData.expiredTrial == false && <div className="payment_warrning">
						<div className="payment_warrning_left">
							<div className="payment_message_line">Trenutno koristite besplatan probni period. Vaš probni period se završava <b>{Util.dateFormat(this.state.profileData.expireTrial)}</b></div>
							<div className="payment_message_line">Nakon isteka ovog perioda poslaćemo Vam račun za sledeći mjesec na adresu sa Vašeg profila.</div>
							<div className="gray_warrning">Ovu poruku vidite samo Vi</div>
						</div>
						<div className="payment_warrning_right">
							<Link to="/placanje"><div className="payment_warrning_button">Saznajte Više</div></Link>
						</div>
					</div> }
				
				{ this.state.loggedIn && this.state.myProfile && this.state.profileData.isTrial && this.state.profileData.expiredTrial == true && <div className="payment_warrning payment_warrning_red">
						<div className="payment_warrning_left">
							<div className="payment_message_line">Vaš besplatan probni period je istekao <b>{Util.dateFormat(this.state.profileData.expireTrial)}</b>.</div>
							<div className="payment_message_line">Da bi ste sačuvali Vaš profil potrebno je da u što kraćem roku platite račun koji je poslat na Vašu adresu sa profila.</div>
							<div className="gray_warrning">Ovu poruku vidite samo Vi</div>
						</div>
						<div className="payment_warrning_right">
							<Link to="/kontakt"><div className="payment_warrning_button">Niste dobili račun?</div></Link>
						</div>
					</div> }
				
				
				
				
					<div className="profile_topImage" style={{'backgroundImage': (this.state.profileData.image_top ? 'url('+this.state.profileData.image_top+')' : '')}}>
					
					</div>
					<div className="profile_info">
						<div className="page_1of4">
							<div className="profile_infoleft">
								<div className="profile_lefttop">
									<div className="profile_userimage" id="img11" style={{'backgroundImage': (this.state.profileData.image ? 'url('+this.state.profileData.image+')' : '')}}>{(this.state.profileData.working_hours && this.state.profileData.working_hours.length >= this.state.todayWeekDay && Util.openClosedCheck(this.state.profileData.working_hours) && <div className="profile_useronline" title="Trenutno je OTVORENO"></div> )}</div>
									<div className="profile_username">{this.state.profileData.name}</div>
									{ this.state.profileData.city && <div className="profile_usercity">{this.state.profileData.city}</div> }
								</div>
								
								<div className="profile_leftbottom">
									{ this.state.profileData.region && <div className="profile_userinfo"><div className="profile_userinfo_title">Kanton/<wbr />Regija</div><div>{this.state.profileData.region}</div></div> }
									{ this.state.profileData.address && <div className="profile_userinfo"><div className="profile_userinfo_title">Adresa</div><div><a href={'https://maps.google.com/maps?q='+this.state.profileData.address+','+this.state.profileData.city+',Bosnia&t=&z=13'} target="_blank" rel="noreferrer">{this.state.profileData.address}</a></div></div> }
									{ this.state.profileData.phone && <div className="profile_userinfo"><div className="profile_userinfo_title">Broj telefona</div><div><a href={('tel:'+this.state.profileData.phone)}>{this.state.profileData.phone}</a></div></div> }
									{ this.state.profileData.website && <div className="profile_userinfo"><div className="profile_userinfo_title">Website</div><div><a href={'http://'+this.state.profileData.website} target="_blank" rel="noreferrer">{this.state.profileData.website}</a></div></div> }
									{ this.state.profileData.registered && <div className="profile_userinfo"><div className="profile_userinfo_title">Registrovan</div><div>{Util.dateFormat(this.state.profileData.registered)}</div></div> }
								
									{ this.state.myProfile &&
										<div className="profile_copy_link">
											<input type="text" readOnly={true} id="copymylinkid" value={'https://www.opk.ba/radnja/'+(this.state.profileData.name).toLowerCase()} />
											<div onClick={this.copyMyLink}>Kopiraj Link</div>
										</div>
									}
									{ !this.state.myProfile &&
										<div className="profile_report_button" onClick={this.reportUser}>Prijavi korisnika</div>
									}
								</div>
							</div>
						</div>
						<div className="page_3of4">
							<div className="profile_menu">
								{ this.state.profileData.about !== undefined && this.state.profileData.about.length > 0 && <a href="#opis"><div onClick={() => { this.setState({currentTab: 'about'}); }} className={(this.state.currentTab == 'about' ? 'active' : '')}>O {this.state.profileData.name}</div></a> }
								{ ( this.state.sellListLength > 0 || this.state.myProfile ) && <a href="#na-prodaju"><div onClick={() => { this.setState({currentTab: 'active', profileExtend: 1}); this.loadNewProducts('active', this.state.currentCategory, this.state.currentSearch); }} className={(this.state.currentTab == 'active' ? 'active' : '')}>NA PRODAJU ({this.state.sellListLength})</div></a> }
								{ this.state.soldListLength > 0 && <a href="#prosli"><div onClick={() => { this.setState({currentTab: 'sold', profileExtend: 1}); this.loadNewProducts('sold', this.state.currentCategory, this.state.currentSearch); }} className={(this.state.currentTab == 'sold' ? 'active' : '')}>PRODATI ({this.state.soldListLength})</div></a> }
								<a href="#ocjene"><div onClick={() => { this.setState({currentTab: 'rating'}); }} className={(this.state.currentTab == 'rating' ? 'active' : '')}>OCJENE</div></a>
								{ this.state.profileData.working_hours !== undefined && this.state.profileData.working_hours.length > 0 && <a href="#radnovrijeme"><div onClick={() => { this.setState({currentTab: 'hours'}); }} className={(this.state.currentTab == 'hours' ? 'active' : '')}>RADNO VRIJEME</div></a> }
							</div>
							{ this.state.currentTab == 'about' && <div className="profile_section profile_section_about">{this.state.profileData.about}</div> }
							{ this.state.currentTab == 'active' && <div className="profile_section"><Results currentCategory={this.state.currentCategory} setCategory={this.setCategory} currentSearch={this.state.currentSearch} doSearch={this.doSearch} maxShow="11" showCategories="1" showSearch="1" filterCategories={this.state.sellCategories} productsList={this.state.sellList} productsListLength={this.state.sellListLength} /> </div> }
							{ this.state.currentTab == 'sold' && <div className="profile_section"><Results currentCategory={this.state.currentCategory} setCategory={this.setCategory} currentSearch={this.state.currentSearch} doSearch={this.doSearch} maxShow="11" showCategories="1" showSearch="1" filterCategories={this.state.soldCategories} productsList={this.state.soldList} productsListLength={this.state.soldListLength} /> </div> }
							{ this.state.currentTab == 'rating' && <div className="profile_section"><UserRating userId={this.state.profileData.id} ratingListSeller={this.state.profileData.user_rating_seller} isLoggedIn={this.state.loggedIn} ratingListBuyer={this.state.profileData.user_rating_buyer} reportComment={this.reportComment} profileName={this.state.profileData.name} /> </div> }
							{ this.state.currentTab == 'hours' && this.state.profileData.working_hours !== undefined && <div className="profile_section profile_section_hours">
									
									<div className="profile_workinghoursnow">
										Trenutno je <b>{Util.openClosedCheck(this.state.profileData.working_hours) ? <span>OTVORENO</span> : <div>ZATVORENO</div>}</b>
									</div>
									
									<div className="profile_workinghours profile_tablehead">
										<div>Dan</div>
										<div>Od</div>
										<div>Do</div>
									</div>
									{
										this.state.profileData.working_hours.map((oneItem, j) =>
										<div className={('profile_workinghours'+(this.state.todayWeekDay == oneItem.week_day ? ' profile_istodayday' : ''))} key={j}>
											<div>{Util.weekDayFormat(oneItem.week_day)}</div>
											{oneItem.start_time && oneItem.start_time !== '25:00:00' && <div>{Util.timeFormat(oneItem.start_time)}</div>}
											{oneItem.end_time && oneItem.start_time !== '25:00:00' && <div>{Util.timeFormat(oneItem.end_time)}</div>}
											{ !oneItem.end_time && !oneItem.end_time && <div className="profile_workinghours_no">Ne radi</div> }
											{ oneItem.start_time == '25:00:00' && <div className="profile_workinghours_all">Uvijek otvoreno</div> }
										</div>
									)}
							</div> }
						</div>
						
						{ this.state.currentTab == 'active' && <div className="profile_section profile_background"><Results startShow="11" showCategories={false} showSearch={false} homeItem={true} filterCategories={this.state.sellCategories} productsList={this.state.sellList} currentCategory={this.state.currentCategory} currentSearch={this.state.currentSearch} doSearch={this.doSearch} extendSearch={this.extendSearch} /> </div> }
						{ this.state.currentTab == 'sold' && <div className="profile_section profile_background"><Results startShow="11" showCategories={false} showSearch={false} homeItem={true} filterCategories={this.state.soldCategories} productsList={this.state.soldList} currentCategory={this.state.currentCategory} currentSearch={this.state.currentSearch} doSearch={this.doSearch} extendSearch={this.extendSearch} /> </div> }
						
						
					</div>
					{ this.state.reportOpen == true &&
						<div className="middle_popup">
							<div className="middle_popup_inside">
								<div className="middle_popup_close" onClick={this.closeReport}>&times;</div>
								<div className="middle_popup_title">Prijava korisnika {this.state.profileData.name}</div>
								
									{ this.state.loggedIn == true &&
										<div className="middle_popup_body">
											<div className="middle_popup_body_padding">
												<div className="profile_popup_warning">Ako niste sigurni u vezi prijave, pogledajte <a href="/uslovikoristenja" target="_blank">uslove korištenja</a></div>
												<TextareatAnimated type="text" id="static_form_message" title="Prijava" fieldValue={this.state.staticMessage} fieldName="staticMessage" fieldError={this.state.messageError} updateChild={this.updateChild} />
												<div className="profile_popup_confirm" onClick={this.doReportUser}>Potvrdi</div>
											</div>
										</div>
									}
									{ this.state.loggedIn == false &&
										<div className="middle_popup_body">
											<div className="middle_popup_body">
												<div className="middle_popup_body_padding">
													<div className="profile_popup_warning">Prijavu mogu da vrše samo registrovani korisnici</div>
													<Link to="/registracija"><div className="profile_popup_confirm" style={{width: 115 +'px', marginTop: 20 + 'px'}} onClick={this.closeReport}>Registruj se</div></Link>
												</div>
											</div>
										</div>
									}
								
							</div>
						</div>
					}
				</div>
		);
	}
}

export default Profile;