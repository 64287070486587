import { Component } from 'react';
import './Style.css';


export class SelectAnimated extends Component {
	
	constructor()
		{
			super();
			this.state = {hasFocus: false};
		}
	
	doFormFocus = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: true});
			}
	}
	
	doFormBlur = (e) =>
	{
		if ( e && e.target )
			{
				this.setState({hasFocus: false});
			}
	}
	
	render()
	{
		return (
				<div className={('input_element_animated form_field'+(this.state.hasFocus ? ' active' : '')+(this.props.fieldValue ? ' hasActiveText' : ''))}>
					<div className="form_field_title">{this.props.title}</div>
					<select name={this.props.id} id={this.props.id} value={this.props.fieldValue} onChange={(event) => this.props.updateChild({[this.props.fieldName]: event.target.value}, this.props.callback)} onFocus={this.doFormFocus} onBlur={this.doFormBlur}>
						
						{ !this.props.fieldValue && <option value="" defaultValue={true}></option> }
						
						{ this.props.options_list.map((oneItem, j) =>
							<option key={j} style={oneItem.style} defaultValue={oneItem.default} disabled={oneItem.disabled} value={oneItem.id}>{oneItem.name}</option>
						)}
					</select>
					{ this.props.fieldError && <div className="form_field_error">{this.props.fieldError}</div> }
					
					{ this.props.userId !== 0 && this.props.userId !== undefined && <input type='text' name={this.props.id} id={this.props.id} fieldvalue={this.props.fieldValue} onChange={(event) => this.props.updateChild({[this.props.fieldName]: event.target.value})} />}
				</div>
		);
	}
}