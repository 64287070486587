import React, { Component } from 'react';
import { Footerhome } from '../../components/footerhome/Footerhome';
import "./Static.css";

class Terms extends Component {
	render(){
		return (
			<div className="terms_container">
				<div className="payments_top">Uslovi korištenja <div></div></div>
				<div className="terms_option_three">
					<div className="terms_bold_top_text">Korištenjem web stranice OPK.ba podrazumijeva se da prihvatate ove Uslove.</div>
					<div className="terms_padding_text">
						<div className="terms_bold_top_text">OPK.ba zadržava pravo da promijeni ove Uslove bez prethodne najave ili obavještenja.</div>
					</div>
				</div>
				<div className="terms_option_three">
					<div className="terms_layman_container">
						<h2 className="terms_layman_title">Uslovi korištenja</h2>
						<h4 className="terms_article">Član 1.</h4>
						<div className="terms_padding_text">Svako ko koristi web stranice OPK.ba, bez obzira da li ima registrovan nalog ili ne, smatra se Korisnikom usluga OPK.ba, i na svakog Korisnika se primjenjuju svi uslovi.</div>
						
						<h4 className="terms_article">Član 2.</h4>
						<div className="terms_padding_text">Podrazumijeva se da svaki Korisnik koji koristi web stranicu OPK.ba <b>prihvata</b> bez pogovora Uslove korištenja, u suprotnom dužan je da prekine posjećivanje OPK.ba stranice.</div>
						
						<h4 className="terms_article">Član 3.</h4>
						<div className="terms_padding_text">OPK.ba nije odgovoran niti dužan provjeravati istinitost/tačnost objava, u vidu teksta ili slika, koje objavljuju Korisnici prilikom korištenja web stranice. Niti je dužan provjeravati zakonitost postavljenog oglasa ili teksta.</div>
						
						<h4 className="terms_article">Član 4.</h4>
						<div className="terms_padding_text">OPK.ba služi samo kao medij povezivanje prodavaca i kupaca, ne posreduje diretkno u kupo-prodaji, niti je dužan nadoknaditi bilo kakvu štetu nastalu svjesnim ili nesvjesnim postupcima Korisnika.</div>
						
						<h4 className="terms_article">Član 5.</h4>
						<div className="terms_padding_text">Svaki Korisnik se obavezuje da će postavljati sadržaj koji je istinit, tačan, vjerodostojan i da ne krši ni jedan zakon u okviru zakona Bosne i Hrecegovine i pripadajućih entiteta.</div>
						
						<h4 className="terms_article">Član 6.</h4>
						<div className="terms_padding_text">Svaki Korisnik koji prodaje robu ili usluge preko OPK.ba je samostalno odgovoran da ispunjava sve zakonske obaveze za prodavanje, podnosi prijave poreza i dobiti nadležnim organima.</div>
						
						<h4 className="terms_article">Član 7.</h4>
						<div className="terms_padding_text">OPK.ba neće samostalno vršiti zakonske provjere oglasa niti poslovanja samog Korisnika. To je dužnost Korisnika navedena u Članu 6.</div>
						
						<h4 className="terms_article">Član 8.</h4>
						<div className="terms_padding_text">OPK.ba zadržava pravo da blokira/ukloni svakog Korisnika ili pojedinačni sadržaj sa web stranice bez prethodne obavijesti.</div>
					</div>
				</div>
				
				<div className="terms_option_three">
					<div className="terms_layman_container">
						<h2 className="terms_layman_title">Korištenje i zaštita podataka</h2>
						
						<h4 className="terms_article">Član 9.</h4>
						<div className="terms_padding_text">OPK.ba čini sve kako bi zaštitio privatnost i sigurnost registrovanih korisnika u okviru "Zakona o zaštiti ličnih podataka" Bosne i Hercegovine.</div>
						
						<h4 className="terms_article">Član 10.</h4>
						<div className="terms_padding_text">OPK.ba će koristiti podatke samo u svrhu kupovine i prodaje između posjetilaca, u svrhu oglašavanja u vidu objavljenih oglasa, te unutrašnje obrade podataka za funkcionisanje web stranice.</div>
						
						<h4 className="terms_article">Član 11.</h4>
						<div className="terms_padding_text">Svaki Korisnik ima pravo da zatraži sakrivanje svih javno objavljenih podataka na stranici.</div>
						
						<h4 className="terms_article">Član 12.</h4>
						<div className="terms_padding_text">Svaki Korisnik prilikom registracije dozvoljava OPK.ba da koristi i obrađuje podatke koje Korisnik unese.</div>
						
						<h4 className="terms_article">Član 13.</h4>
						<div className="terms_padding_text">Shodno "Zakonu o zaštiti ličnih podataka" Bosne i Hercegovine, lični podaci korisnika čuvaju se u bazi podataka u okviru stranice OPK.ba, koje se obavezuje da čuva privatnost svojih korisnika i sve njihove podatke.</div>
						
						<h4 className="terms_article">Član 14.</h4>
						<div className="terms_padding_text">Lične podatke korisnika OPK.ba će proslijediti trećim licima samo ukoliko postoji zahtjev ili nalog nadležnog organa ili je takvo prosljeđivanje propisano zakonom.</div>
						
						<h4 className="terms_article">Član 15.</h4>
						<div className="terms_padding_text">OPK.ba ne preuzima odgovornost po bilo kojem osnovu, ukoliko korisnik sam otkrije svoje podatke trećim licima ili te podatke učini dostupnim da drugi način.</div>
						
						<h4 className="terms_article">Član 16.</h4>
						<div className="terms_padding_text">Svaki Korisnik se obavezuje da neće kršiti autorska prava trećih lica i da za sav objavljeni sadržaj ima pravo korištenja.</div>
					</div>
				</div>
				
				<div className="terms_option_three">
					<div className="terms_layman_container">
						<h2 className="terms_layman_title">Ograničenje odgovornosti</h2>
						
						<h4 className="terms_article">Član 17.</h4>
						<div className="terms_padding_text">OPK.ba ne garantuje za:
							<div>- tačnost podataka na oglasima ili drugim objavama Korisnika, uključujući njihove lične podatke</div>
							<div>- kvalitet, sigurnost ni legalnost prodaje-kupovine proizvoda ili usluga</div>
							<div>- da na stranici neće biti grešaka ili prekida sa radom</div>
							<div>- da na stranici neće biti povreda autorskih prava od strane trećih lica</div>
							<div>- da će Korisnici u svakom trenutku koristiti stranice legalno</div>
						</div>
						
						<h4 className="terms_article">Član 18.</h4>
						<div className="terms_padding_text">OPK.ba neće:
							<div>- samostalno provjeravati tačnost podataka</div>
							<div>- samostalno provjeravati zakonitost radnje ili prodaje-kupovine proizvoda ili usluga</div>
						</div>
					</div>
				</div>
				
				<div className="terms_option_three">
					<div className="terms_layman_container">
						<h2 className="terms_layman_title">Sudska nadležnost</h2>
						
						<h4 className="terms_article">Član 19.</h4>
						<div className="terms_padding_text">Za sve zahtjeve i sporove koji bi mogli proizaći iz korištenja predmetnih materijala, stvarno je nadležan sud u Zvorniku ili po zahtjevu vlasnika OPK.ba u Banja Luci.</div>
						
						<h4 className="terms_article">Član 20.</h4>
						<div className="terms_padding_text">OPK.ba zadržava pravo da promijeni ove Uslove bez prethodne najave ili obavještenja.</div>
					</div>
				</div>
				
				<Footerhome></Footerhome>
			</div>
		)
	}
}

export default Terms;