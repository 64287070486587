import React, { Component } from 'react';
import axios from "axios";
import './Boughtlist.css';
import { Link } from 'react-router-dom';
import Util from '../../util/util';

class BoughtList extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			user_id: '',
			boughtList: [],
			usersData: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
		}
	}
	
	componentDidMount = () =>
		{
			var mainThis = this;
		
			axios.post(window.ajaxLink+'products/boughtList', {user_id: this.state.usersData.id})
				.then(res => {
					mainThis.setState({ 
						boughtList: res['data']['responseData']['boughtItems'],					
						});
				}).catch(err => {console.log(err)});
		}
		
		
	render(){
		return (
				<div className="bought_list_container">	
					<div className="bought_items_page_title">Lista kupljenih proizvoda</div>
					{
							this.state.boughtList.map((boughtItem, i)=>
								<Link key={i} to={'/p/'+boughtItem.name+'-'+boughtItem.id}><div key={i} className="bought_list_item" >
											<div className="item_header_title">{boughtItem.name}</div>
											<div className="item_header_picture" style={{backgroundImage: 'url('+window.imagesLink+boughtItem.image+')'}}></div>
											<div className="item_headers_container">
											<div className="item_headers">
												<div className="item_header">Prodavac</div>
												<div className="item_header">Količina</div>
												<div className="item_header">Cijena</div>
												<div className="item_header">Ukupno</div>
											</div>
											<div className="item_headers item_headers_recolor">
												<div className="item_header">{boughtItem.username}</div>
												<div className="item_header">{boughtItem.ordered_quantity}</div>
												<div className="item_header">{Util.formatPrice(boughtItem.price)}</div>
												<div className="item_header">{boughtItem.price == -1 ? "Po dogovoru" : Util.formatPrice(Util.getTotal(boughtItem.price, boughtItem.ordered_quantity))}</div>
											</div>
											</div>
										</div></Link>
						)
					}
				</div>	
		)
	}
}

export default BoughtList;