import React, { Component } from 'react';
import './OneRating.css';

export class OneRating extends Component {
	
	render(){
		
		var rating_overall_percent = 0;
		var rating_overall = 0;
		if ( this.props.comment.rating_overall )
			{
				rating_overall = parseFloat(this.props.comment.rating_overall).toFixed(1);
				rating_overall_percent = (parseFloat(this.props.comment.rating_overall)/5)*100;
			}

		
		return(
			<div className="user_comment">
				<div className="user_comment_name"><div className="user_comment_image" style={{'backgroundImage': (this.props.comment.image ? 'url('+window.imagesLink+this.props.comment.image+')' : '')}}></div>{this.props.comment.username}</div>
				
				<div className="user_comment_rating">
					<div className="user_comment_rate">{rating_overall}</div>
					<div className="rsr_stars"><div style={{'width': rating_overall_percent+'%'}}></div></div>
				</div>
				
				<div className="user_comment_text">{this.props.comment.rating_text}</div>
					{this.props.isLoggedIn && <div className="user_comment_report_container">
						{ this.props.comment.myreport == null ?
							<div className="user_comment_report" onClick={ () => this.props.reportComment(this.props.commentId)}>Predloži brisanje</div>
							:
							<div className="user_comment_reported">Prijavljen</div>
						}
					</div>}
			</div>
		)
	}
}